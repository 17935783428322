import { getData } from "@/services/utils";
import qs from "qs";
import { axiosService } from "../axios";

const qsOptions = { addQueryPrefix: true };

const ISV_CONNECTIONS_URL = "/api/isv-connections";
export const isvConnectionService = {
    listConnections: accountId =>
        axiosService
            .get(`${ISV_CONNECTIONS_URL}?projectId=${accountId}`)
            .then(getData),
    createConnection: connection =>
        axiosService.post(`${ISV_CONNECTIONS_URL}`, connection).then(getData),

    getConnection: connectionId =>
        axiosService
            .get(`${ISV_CONNECTIONS_URL}/${connectionId}`)
            .then(getData),

    editConnection: (connectionId, connection) =>
        axiosService
            .put(`${ISV_CONNECTIONS_URL}/${connectionId}`, connection)
            .then(getData),

    refreshSettings: connectionId =>
        axiosService
            .post(`${ISV_CONNECTIONS_URL}/${connectionId}/refresh-settings`)
            .then(getData),
    deleteConnection: connectionId =>
        axiosService
            .delete(`${ISV_CONNECTIONS_URL}/${connectionId}`)
            .then(getData),
    connectionNameExists: (accountId, connectionName) => {
        const queryStr = qs.stringify(
            {
                projectId: accountId,
                name: connectionName,
            },
            qsOptions,
        );

        return axiosService
            .get(`${ISV_CONNECTIONS_URL}${queryStr}`)
            .then(getData);
    },
};
