import {
    useInitialValuesQuery,
    useSaveAlertRuleMutation,
    useServicesOptionsQuery,
    useTestAlertRuleMutation,
} from "@/components/AlertRules/loadables";
import {
    alertRuleEntityType,
    alertRuleScope,
    alertRuleSeverities,
} from "@/components/AlertRules/services/alertRules.service";
import {
    useAlertRuleNameValidator as useNameValidator,
    useWebHookValidator,
} from "@/components/AlertRules/validators";
import { useRecipientsValidator } from "@/components/CreateJobTaskAlert/validators";
import { Button, Forms, P } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { gt, pick } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

const serviceOptions = [
    {
        label: t("alert-rule-form.button.partitions"),
        value: alertRuleEntityType.PARTITION,
    },
    {
        label: t("alert-rule-form.button.integrations"),
        value: alertRuleEntityType.INTEGRATION,
    },
    {
        label: t("alert-rule-form.button.accelerators"),
        value: alertRuleEntityType.ACCELERATOR,
    },
];

const ACTION = {
    SAVE: "SAVE",
    TEST: "TEST",
};

const TEST_PROPERTIES = [
    "definition",
    "entityType",
    "entityId",
    "scope",
    "threshold",
    "accountId",
];

export const AlertRulesForm = ({ accountId, alertRuleId, scope }) => {
    const {
        locationRouterService,
        accountAppLocations: { accountAlertRulesLocation },
        administrationAppLocations: { globalAlertsRulesLocation },
    } = useDic();

    useSetPageTitle(
        t(
            alertRuleId === undefined
                ? "alert-rules-form.label.create"
                : "alert-rules-form.label.edit",
        ),
    );

    const initialValuesQuery = useInitialValuesQuery({ alertRuleId });
    const servicesOptionsQuery = useServicesOptionsQuery({ accountId });
    const testAlertRuleMutation = useTestAlertRuleMutation();
    const saveAlertRuleMutation = useSaveAlertRuleMutation({
        afterSuccess: ({ scope }) => {
            if (scope === alertRuleScope.SINGLE)
                locationRouterService.navigate(accountAlertRulesLocation, {
                    accountId,
                });
            if (scope === alertRuleScope.GLOBAL)
                locationRouterService.navigate(globalAlertsRulesLocation);
        },
    });
    const { formId, submit, handleSubmit, setValues, setTouched } =
        Forms.useForm({
            onSubmit: ({ values, args: [action] }) => {
                const [entityType, entityId] = !values.service
                    ? [null, null]
                    : {
                          [alertRuleScope.SINGLE]: values.service.split("|"),
                          [alertRuleScope.GLOBAL]: [values.service, null],
                      }[scope];
                const data = {
                    ...initialValuesQuery.loadable.valueMaybe(),
                    ...values,
                    entityType,
                    entityId,
                    scope,
                    recipients: values.recipients?.join(","),
                    accountId,
                };

                if (action === ACTION.SAVE)
                    return saveAlertRuleMutation.mutate(data);

                if (action === ACTION.TEST)
                    return testAlertRuleMutation.mutate(
                        pick(TEST_PROPERTIES, data),
                    );
            },
        });
    const initialValues = initialValuesQuery.loadable.valueMaybe();
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    const webHookValue = Forms.useFieldValue({ formId, name: "webHook" });
    const recipientsValue = Forms.useFieldValue({ formId, name: "recipients" });

    const nameValidator = useNameValidator({ accountId, scope, alertRuleId });
    const recipientsValidator = useRecipientsValidator({ webHookValue });
    const webHookValidator = useWebHookValidator({ emails: recipientsValue });

    return (
        <>
            <P>{t("alert-rules-form.description")}</P>
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.Fields.Input
                    name="name"
                    label={t("alert-rules-form.label.name")}
                    placeholder={t("alert-rules-form.placeholder.name")}
                    validator={nameValidator}
                    required
                />
                <Forms.Fields.Switch
                    name="enabled"
                    label={t("reports-form.label.enabled")}
                />
                {scope === alertRuleScope.SINGLE && (
                    <Forms.Fields.Select
                        name="service"
                        label={t("alert-rules-form.label.service")}
                        placeholder={t("alert-rules-form.placeholder.service")}
                        allowClear={false}
                        validator={Forms.pmValidators.isRequired}
                        required
                        useRawOptions
                        {...getLoadableSelectProps(
                            servicesOptionsQuery.loadable,
                        )}
                    />
                )}
                {scope === alertRuleScope.GLOBAL && (
                    <Forms.Fields.RadioButton
                        name="service"
                        label={t("alert-rules-form.label.service")}
                        allowClear={false}
                        options={serviceOptions}
                        validator={Forms.pmValidators.isRequired}
                        required
                    />
                )}
                <Forms.Fields.Input
                    name="definition"
                    label={t("alert-rules-form.label.definition")}
                    placeholder={t("alert-rules-form.placeholder.definition")}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createMinLengthValidation(4),
                    ])}
                    required
                />
                <Forms.Fields.Select_tempFixDeselect
                    name="recipients"
                    label={t("alert-rules-form.label.recipients")}
                    placeholder={t("alert-rules-form.placeholder.recipients")}
                    mode="tags"
                    allowClear={false}
                    required={!webHookValue}
                    validator={recipientsValidator}
                />
                <Forms.Fields.Input
                    name="webHook"
                    label={t("alert-rules-form.label.webhook")}
                    placeholder={t("alert-rules-form.placeholder.webhook")}
                    required={!recipientsValue?.length}
                    validator={webHookValidator}
                />
                <Forms.Fields.Input
                    name="cron"
                    label={t("alert-rules-form.label.cron")}
                    required
                    disabled
                />
                <Forms.Fields.InputNumber
                    name="threshold"
                    label={t("alert-rules-form.label.threshold")}
                    placeholder={t("alert-rules-form.placeholder.threshold")}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.min(0, gt),
                    ])}
                    required
                />
                <Forms.Fields.Select
                    name="severity"
                    label={t("alert-rules-form.label.severity")}
                    placeholder={t("alert-rules-form.placeholder.severity")}
                    options={alertRuleSeverities}
                    validator={Forms.pmValidators.isRequired}
                    required
                />

                <Button
                    formId={formId}
                    label={t("general.save")}
                    type="primary"
                    htmlType="submit"
                    data-test="save-button"
                    onClick={() => submit(ACTION.SAVE)}
                    disabled={
                        isLoading(saveAlertRuleMutation) ||
                        isLoading(servicesOptionsQuery) ||
                        isLoading(initialValuesQuery)
                    }
                />
                <Button
                    formId={formId}
                    label={t("instance-form.button.test")}
                    htmlType="button"
                    data-test="test-rule-button"
                    onClick={() => submit(ACTION.TEST)}
                    disabled={
                        isLoading(testAlertRuleMutation) ||
                        isLoading(servicesOptionsQuery) ||
                        isLoading(initialValuesQuery)
                    }
                />

                {accountId && (
                    <Button
                        label={t("instance-form.button.cancel")}
                        type="text"
                        htmlType="button"
                        data-test="cancel-button"
                        onClick={() =>
                            locationRouterService.navigate(
                                accountAlertRulesLocation,
                                { accountId },
                            )
                        }
                    />
                )}
                {scope === alertRuleScope.GLOBAL && (
                    <Button
                        label={t("instance-form.button.cancel")}
                        type="text"
                        htmlType="button"
                        data-test="cancel-button"
                        onClick={() =>
                            locationRouterService.navigate(
                                globalAlertsRulesLocation,
                            )
                        }
                    />
                )}
            </Forms.Form>
        </>
    );
};

AlertRulesForm.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    alertRuleId: PropTypes.number,
    scope: PropTypes.oneOf(Object.keys(alertRuleScope)).isRequired,
};
