import {
    ButtonMenu,
    Forms,
    Label,
    Modal,
    Skeleton,
    UnityLayout,
} from "@/components/DesignSystem";
import { lessThanDay } from "@/components/DesignSystem/Forms/fields";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { isUrlValid } from "@/utils/validation";
import entries from "lodash/fp/entries";
import includes from "lodash/fp/includes";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import trim from "lodash/fp/trim";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { getCreateJobTaskAlertTrackName } from "../../mixpanel/buttonNames";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    DEFAULT_EXECUTION_PERIOD,
    SchedulingDetails,
} from "./SchedulingDetails";
import {
    ALERT_TRIGGER_STATE_BY_TYPE,
    ALL_TARGETS_VALUE,
    ALL_TRIGGERS_VALUE,
    ENTITY_TYPE,
    NOTIFICATION_TYPE_OPTIONS,
    SCHEDULE_TYPE,
    TYPE,
    TYPE_OPTIONS,
} from "./constants";
import {
    ALL_ENTITY_IDS,
    ALL_TARGET_NAMES,
    getLoadableTargetNameSelectProps,
    useEntitiesOptionsResource,
    useGetNotificationRuleQuery,
    usePostNotificationRule,
    useTargetNameOptionsResource,
} from "./loadables";
import {
    useEntityIdValidator,
    useMaxRunningTimeMinutesValidator,
    useNameValidator,
    useRecipientsValidator,
    useTargetNameValidator,
    useTriggerValueValidator,
} from "./validators";

const to = alert => {
    const {
        entityIds,
        useAll,
        scheduledAt,
        timezone,
        scheduleType,
        targetName,
        target,
        recipients: maybeRecipients,
        executionPeriod: maybeExecutionPeriod,
        ...rest
    } = alert;
    const recipients = maybeRecipients || undefined; // sometimes null :-/
    const executionPeriod = maybeExecutionPeriod || DEFAULT_EXECUTION_PERIOD; // sometimes null :-/
    const targetJson =
        targetName && target ? JSON.stringify([targetName, target]) : undefined;

    const common = {
        entityIds: useAll ? [ALL_ENTITY_IDS] : entityIds,
        recipients,
        executionPeriod,
        targetName: targetJson,
        target,
    };

    if (!scheduledAt || scheduleType === SCHEDULE_TYPE.REALTIME)
        return {
            scheduleType: SCHEDULE_TYPE.REALTIME,
            ...common,
            ...rest,
        };

    const scheduledAtMoment = moment(scheduledAt);

    return {
        scheduleType: SCHEDULE_TYPE.SCHEDULED,
        date: scheduledAtMoment,
        time: scheduledAtMoment,
        timezone,
        ...common,
        ...rest,
    };
};

const from = formValues => {
    const {
        date,
        time,
        timezone,
        executionPeriod,
        scheduleType,
        name,
        type,
        targetName: targetJson,
        target: targetInput,
        entityIds,
        accountName, // kills API when present
        ...rest
    } = formValues;
    const entityType = getEntityType(type);
    const [targetName, target] = targetJson
        ? JSON.parse(targetJson)
        : [undefined, targetInput];

    const useAll = entityIds?.includes(ALL_ENTITY_IDS);
    const common = {
        useAll,
        entityIds: entityIds.filter(id => id !== ALL_ENTITY_IDS),
        name: trim(name),
        scheduleType,
        type,
        entityType,
        targetName,
        target,
    };

    if (scheduleType === SCHEDULE_TYPE.SCHEDULED) {
        return {
            scheduledAt: moment(date)
                .set({
                    hour: time?.hour?.(),
                    minute: time?.minute?.(),
                    second: time?.second?.(),
                })
                ?.format("YYYY-MM-DDTHH:mm:ss"),
            timezone,
            executionPeriod,
            ...common,
            ...rest,
        };
    }

    if (type === TYPE.WORKFLOW) {
        return {
            ...common,
            ...rest,
            target: ALL_TARGETS_VALUE,
            targetType: ALL_TARGETS_VALUE,
        };
    }

    return {
        ...common,
        ...rest,
    };
};

const possibleTriggerOptions = type => {
    if (!type) return [];

    return pipe(
        entries,
        map(([value, label]) => ({ value, label })),
        // reject( // https://pricefx.atlassian.net/browse/PFIM-3588
        //     scheduleType === SCHEDULE_TYPE.REALTIME
        //         ? ["value", DID_NOT_RUN_VALUE]
        //         : F
        // )
    )(ALERT_TRIGGER_STATE_BY_TYPE[type]);
};

const notBlankValidator = Forms.pmValidators.notBlank();

const getEntityType = type =>
    ({
        [TYPE.PARTITION_JOB]: ENTITY_TYPE.PARTITION,
        [TYPE.ROUTE_CHECK]: ENTITY_TYPE.INTEGRATION_MANAGER_INSTANCE,
        [TYPE.WORKFLOW]: ENTITY_TYPE.WORKFLOW,
    }[type]);

const emptyObject = {};
const emptyArray = [];

export const CreateJobTaskAlertModal = ({
    title,
    visible,
    onCancel,
    afterSave,
    alertRuleId,
    initialValues: initialValuesProp,
    projectId,
    okText = alertRuleId ? t("general.save") : t("general.create"),
}) => {
    const initialValuesLoadable = useGetNotificationRuleQuery({
        projectId,
        id: alertRuleId,
        canFetch: visible,
    })?.loadable;
    const initialValues =
        initialValuesProp ||
        initialValuesLoadable?.valueMaybe?.() ||
        emptyObject;

    const postAlertMutation = usePostNotificationRule({
        afterSave,
        projectId,
    });
    const { accountLoadable } = useRouteAccount();
    const { formId, handleSubmit, setValues, setTouched, reset } =
        Forms.useForm({
            onSubmit: ({ values }) =>
                postAlertMutation.mutate({
                    id: alertRuleId,
                    ...from(values),
                }),
        });

    const type = Forms.useFieldValue({ formId, name: "type" });
    const entityIds = Forms.useFieldValue({ formId, name: "entityIds" });
    const webHookValue = Forms.useFieldValue({
        formId,
        name: "webHook",
    });
    const recipientsValue = Forms.useFieldValue({
        formId,
        name: "recipients",
    });
    const scheduleType = Forms.useFieldValue({
        formId,
        name: "scheduleType",
    });
    const targetName = Forms.useFieldValue({
        formId,
        name: "targetName",
    });

    useEffect(() => {
        if (!visible) reset();
    }, [visible]);
    useSetValidatedInitialValues(
        {
            initialValues: to(initialValues),
            setValues,
            setTouched,
        },
        [initialValues],
    );

    const entitiesOptionsResource = useEntitiesOptionsResource({
        type,
        projectId,
    });

    const nameValidator = useNameValidator({
        projectId,
        entityIds,
        entityType: getEntityType(type),
        initialName: initialValues.name,
        alertRuleId,
    });
    const triggerValueOptions = useMemo(
        () => possibleTriggerOptions(type),
        [type],
    );
    const triggerValueFormatValidators = useTriggerValueValidator({
        formId,
        type,
        scheduleType,
        triggerValueOptions,
    });
    const recipientsValidator = useRecipientsValidator({ webHookValue });
    const webHookValidator = useCallback(
        value =>
            !value || isUrlValid(value)
                ? Forms.success()
                : Forms.error("Invalid url"),
        [],
    );
    const onChangeTrigger = useCallback(
        ({ value }) => {
            if (includes(ALL_TRIGGERS_VALUE, value))
                setValues({
                    triggerValue: [ALL_TRIGGERS_VALUE],
                });
        },
        [setValues],
    );
    const fromScratch = !initialValues?.type;

    const entityIdValidator = useEntityIdValidator({
        maybeOptions: entitiesOptionsResource.loadable.valueMaybe(),
    });

    const isMultipleEntityIds =
        entityIds?.length > 1 || entityIds?.includes(ALL_ENTITY_IDS);
    const partitionIds = type === TYPE.PARTITION_JOB ? entityIds : undefined;
    const targetNameOptionsResource = useTargetNameOptionsResource({
        projectId,
        partitionId: partitionIds?.[0],
        canFetch: partitionIds?.length === 1 && !isMultipleEntityIds,
    });
    const maybeTargetNameOptions =
        targetNameOptionsResource.loadable.valueMaybe();
    const targetNameValidator = useTargetNameValidator({
        maybeOptions: maybeTargetNameOptions,
    });
    const onChangeType = useCallback(
        v => {
            if (v.value === TYPE.WORKFLOW) {
                setValues({ scheduleType: SCHEDULE_TYPE.REALTIME });
            }
        },
        [setValues],
    );
    const onChangeTargetName = useCallback(
        ({ value }) => {
            if (value) {
                const [targetName, targetObject] = JSON.parse(value);
                setValues({ target: targetObject });
                if (value.includes(ALL_TARGETS_VALUE)) {
                    setValues({ targetType: targetObject });
                }
            }
        },
        [setValues],
    );
    const onChangeEntityIds = useCallback(
        ({ name, value }) => {
            if (type === TYPE.PARTITION_JOB) {
                setValues({ targetName: undefined, target: undefined });
            }
            if (value?.includes(ALL_ENTITY_IDS) || value?.length > 1) {
                if (value?.includes(ALL_ENTITY_IDS)) {
                    setValues({ entityIds: [ALL_ENTITY_IDS] });
                }
                setValues({ targetName: ALL_TARGET_NAMES });
                onChangeTargetName({ value: ALL_TARGET_NAMES });
            }
        },
        [onChangeTargetName, setValues, type],
    );
    const onChangeTarget = useCallback(
        () => setValues({ targetName: undefined }),
        [setValues],
    );

    const maxRunningTimeMinutesValidator = useMaxRunningTimeMinutesValidator({
        formId,
    });

    return (
        <Modal
            visible={visible}
            onClose={onCancel}
            // width={600}
        >
            <UnityLayout>
                <UnityLayout.Header size={3} title={title} />
                <UnityLayout.Content padding={[false, true]}>
                    <Skeleton active loading={isLoading(initialValuesLoadable)}>
                        <Forms.Form onSubmit={handleSubmit} formId={formId}>
                            <Forms.FieldGroup width="max" inputWidth="max">
                                {fromScratch ? (
                                    <Forms.Fields.Select
                                        name="type"
                                        required
                                        label={t(
                                            "alerts.modal-create.type.label",
                                        )}
                                        options={TYPE_OPTIONS}
                                        validator={
                                            Forms.pmValidators.isRequired
                                        }
                                        initialValue={TYPE.PARTITION_JOB}
                                        onChange={onChangeType}
                                        allowClear={false}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            position: "absolute",
                                            height: 0,
                                        }}
                                    >
                                        <Forms.Fields.Input
                                            name="type"
                                            type="hidden"
                                        />
                                    </div>
                                )}
                                <Forms.Fields.Input
                                    required
                                    name="name"
                                    label={t("alerts.modal-create.name.label")}
                                    placeholder={""}
                                    validator={nameValidator}
                                    allowClear={false}
                                />
                                <Forms.Fields.Input
                                    required
                                    name="accountName"
                                    label={t(
                                        "alerts.modal-create.account.label",
                                    )}
                                    initialValue={
                                        accountLoadable.valueMaybe()?.name
                                    }
                                    allowClear={false}
                                    disabled
                                />
                                <Forms.Fields.Select
                                    required
                                    name="entityIds"
                                    mode="multiple"
                                    label={
                                        // TODO: check it can be either Partition, Integration of Workflow
                                        type === TYPE.PARTITION_JOB
                                            ? "Partition"
                                            : type === TYPE.WORKFLOW
                                            ? "Workflow"
                                            : "Integration Instance"
                                    }
                                    onChange={onChangeEntityIds}
                                    disabled={!fromScratch}
                                    validator={entityIdValidator}
                                    {...getLoadableSelectProps(
                                        entitiesOptionsResource.loadable,
                                    )}
                                />
                                {type !== TYPE.WORKFLOW && (
                                    <Forms.Fields.Input
                                        name="targetType"
                                        label={t(
                                            "alerts.modal-create.targetType.label",
                                        )}
                                        disabled={
                                            !fromScratch ||
                                            targetName?.includes(
                                                ALL_TARGETS_VALUE,
                                            )
                                        }
                                        required={fromScratch}
                                        validator={
                                            fromScratch
                                                ? notBlankValidator
                                                : undefined
                                        }
                                        allowClear={false}
                                    />
                                )}
                                {type === TYPE.PARTITION_JOB &&
                                    (fromScratch || targetName) && (
                                        <Forms.Fields.Select
                                            name="targetName"
                                            label={t(
                                                "alerts.modal-create.targetName.label",
                                            )}
                                            disabled={
                                                !fromScratch ||
                                                isMultipleEntityIds
                                            }
                                            validator={targetNameValidator}
                                            onChange={onChangeTargetName}
                                            {...getLoadableTargetNameSelectProps(
                                                {
                                                    loadable:
                                                        targetNameOptionsResource.loadable,
                                                    partitionIds,
                                                    isMultipleEntityIds,
                                                },
                                            )}
                                        />
                                    )}

                                {type !== TYPE.WORKFLOW && (
                                    <Forms.Fields.Input
                                        name="target"
                                        label={t(
                                            "alerts.modal-create.target.label",
                                        )}
                                        disabled={!fromScratch || targetName}
                                        required={fromScratch}
                                        onChange={onChangeTarget}
                                        validator={
                                            fromScratch
                                                ? notBlankValidator
                                                : undefined
                                        }
                                        allowClear={false}
                                    />
                                )}

                                <Label label="Trigger by" required />

                                <Forms.FieldGrid breakpoint={800}>
                                    <Forms.FieldGrid.Row>
                                        <Forms.Fields.Select
                                            required={false}
                                            name="triggerValue"
                                            label={t(
                                                "alerts.modal-create.triggerValue.label",
                                            )}
                                            allowClear={false}
                                            mode="multiple"
                                            options={triggerValueOptions}
                                            showSearch
                                            onChange={onChangeTrigger}
                                            validator={
                                                triggerValueFormatValidators
                                            }
                                            tooltip={
                                                "Alert is triggered when a defined state is detected"
                                            }
                                        />
                                        {type === TYPE.ROUTE_CHECK ? (
                                            <Forms.Fields.SelectTimeWithAdd
                                                required={false}
                                                formId={formId}
                                                name="maxRunningTimeMinutes"
                                                label={t(
                                                    "alerts.modal-create.maxRunningTime.label",
                                                )}
                                                addItemValidator={lessThanDay(
                                                    1,
                                                )}
                                                validator={
                                                    maxRunningTimeMinutesValidator
                                                }
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Forms.FieldGrid.Row>
                                </Forms.FieldGrid>
                                <Forms.Fields.Radio
                                    // required
                                    name="scheduleType"
                                    label={<></>}
                                    initialValue={SCHEDULE_TYPE.SCHEDULED}
                                    options={NOTIFICATION_TYPE_OPTIONS}
                                    validator={Forms.pmValidators.isRequired}
                                    disabled={type === TYPE.WORKFLOW}
                                    // style={{ marginTop: 0, marginBottom: 0 }} // TODO: vertical spacing
                                />

                                <Suspense fallback={null}>
                                    <SchedulingDetails
                                        formId={formId}
                                        visible={
                                            scheduleType ===
                                            SCHEDULE_TYPE.SCHEDULED
                                        }
                                    />
                                </Suspense>

                                <Forms.Fields.Select_tempFixDeselect
                                    required={!webHookValue}
                                    name="recipients"
                                    initialValue={emptyArray}
                                    label={t(
                                        "alerts.modal-create.recipients.label",
                                    )}
                                    placeholder={t(
                                        "alert-rules-form.placeholder.email",
                                    )}
                                    validator={recipientsValidator}
                                    allowClear={false}
                                    mode="tags"
                                />
                                <Forms.Fields.Checkbox
                                    name="showDetailMessage"
                                    label={t(
                                        "alerts.modal-create.showDetailMessage.label",
                                    )}
                                />
                                <Forms.Fields.Input
                                    required={!recipientsValue?.length}
                                    name="webHook"
                                    label={t(
                                        "alerts.modal-create.webhook.label",
                                    )}
                                    placeholder={t(
                                        "alert-rules-form.placeholder.webhook",
                                    )}
                                    allowClear={false}
                                    validator={webHookValidator}
                                />
                            </Forms.FieldGroup>
                        </Forms.Form>
                    </Skeleton>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: okText,
                                    type: "primary",
                                    formId,
                                    track: {
                                        name: getCreateJobTaskAlertTrackName(
                                            title,
                                            "Submit",
                                        ),
                                    },
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onCancel,
                                    type: "text",
                                    track: {
                                        name: getCreateJobTaskAlertTrackName(
                                            title,
                                            "Cancel",
                                        ),
                                    },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

CreateJobTaskAlertModal.propTypes = {
    title: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    afterSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    alertRuleId: PropTypes.any,
    projectId: PropTypes.any,
    okText: PropTypes.any,
};
