import { Gap, H4, UnityLink } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    INSTANCE_TABS,
    isProvisioned,
} from "@/components/Integrations/EditInstanceForm";
import { EntityType } from "@/components/Integrations/IntegrationsVersionHistory.overview.page";
import { TransportSelectedToModal } from "@/components/Integrations/components/TransportModal/TransportSelectedTo.modal";
import TransportReadyModal from "@/components/Integrations/components/TransportReady.modal.component";
import { useAccountInstancesQuery } from "@/components/Integrations/hooks/useAccountInstancesQuery.hook";
import { useAllowedEnvsToTransportQuery } from "@/components/Integrations/transport.loadables";
import { useTableCellUpdater } from "@/components/SFTPServers/useTableCellUpdater.hook";
import SSEType from "@/components/ServerSideEvents/types";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useGetDefaultIntegrationLocation } from "@/components/hooks/useGetDefaultIntegrationLocation.hook";
import { useOneVisible } from "@/components/hooks/useOneVisible.hook";
import { provider } from "@/constants/provider.constants";
import { isLoading } from "@/modules/loadable";
import { useConfirmModal } from "@/modules/modal";
import { useRoute } from "@/modules/router";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import { SecurityContext } from "@/security/authorization";
import {
    ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    ADD_INTEGRATION_PERMISSIONS_EXTENDED,
    INTEGRATION_ADMIN_PERMISSIONS,
    INTEGRATION_EDIT_PERMISSIONS,
    INTEGRATION_GIT_MANAGER,
    includesPermission,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { message } from "antd";
import { entries, map, pipe } from "lodash/fp";
import groupBy from "lodash/fp/groupBy";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import uuid from "uuid/v4";
import { accountsService } from "../../apps/accountApp/services/accounts.service";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { getInstancesListTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { useTrackButtonClick } from "../../mixpanel/hooks/useTrackButtonClick.hook";
import { useRefreshAction } from "../PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { InstancePermissionsPanel } from "./InstancePermissionsPanel/InstancePermissionsPanel";
import { createColumns } from "./InstancesList.columns";
import { IntegrationLink } from "./IntegrationLink.component";

const preferencesType = preferencesTypes.INSTANCES_LIST_TABLE;
const MERGE_REQUEST_STATE = {
    OPENED: "OPENED",
    CLOSED: "CLOSED",
};
const MERGE_STATUS = {
    CANNOT_BE_MERGED: "CANNOT_BE_MERGED",
    CAN_BE_MERGED: "CAN_BE_MERGED",
};

export const mapLoadableItems = (mapper, resource) => {
    if (resource.loadable.valueMaybe() === undefined) {
        return resource;
    } else {
        const contents = mapper(resource.loadable.valueMaybe());
        return {
            ...resource,
            loadable: {
                ...resource.loadable,
                contents,
                valueMaybe: () => contents,
            },
        };
    }
};

const canEditIM = envs => envs?.some(env => env.state !== "PENDING");

// https://gitlab.pricefx.eu/platform/platform-manager/-/blob/develop/domain-model/src/main/java/net/pricefx/pm/model/entity/IntegrationManagerInstance.java?ref_type=heads
export const IM_OPERATIONAL_STATE = {
    STARTED: "STARTED",
    STARTING: "STARTING",
    STOPPING: "STOPPING",
    STOPPED: "STOPPED", //
    INITIATED_UPDATE: "INITIATED_UPDATE",
    REQUESTED_UPDATE: "REQUESTED_UPDATE",
};

const isStopped = record =>
    [IM_OPERATIONAL_STATE.STOPPED, IM_OPERATIONAL_STATE.STOPPING].includes(
        record.operationalState,
    ) || record.operationalState === null;

const InstancesList = ({ accountId }) => {
    const {
        axiosService,
        locationRouterService,
        accountAppLocations,
        instanceService,
    } = useDic();

    const {
        instanceNewLocation,
        instanceGroupEditLocation,
        settingsLocation,
        integratonsVersionHistoryLocation,
    } = accountAppLocations;
    const [integrationPanel, permissionsPanel] = useOneVisible(2);
    const getDefaultLocation = useGetDefaultIntegrationLocation();
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [selectedTransport, setSelectedTransport] = useState(null);
    const securityContext = useContext(SecurityContext);

    const instancesQuery = useAccountInstancesQuery({ accountId });

    useRefreshAction(instancesQuery.reload);

    const [breadcrumbButton, setBreadcrumbButton] = useState(null);
    useBreadcrumbButton(breadcrumbButton, [breadcrumbButton]);

    useEffect(() => {
        accountsService
            .getExtendedAddIntegrationPermissions(accountId)
            .then(({ data: isExtendedPermission }) => {
                setBreadcrumbButton({
                    label: "instance-list.button.new",
                    onClick: () =>
                        locationRouterService.navigate(instanceNewLocation, {
                            accountId: accountId,
                        }),
                    permissions: isExtendedPermission
                        ? ADD_INTEGRATION_PERMISSIONS_EXTENDED
                        : ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
                });
            });
    }, [accountId, instanceNewLocation, locationRouterService]);

    const onEdit = useCallback(
        record => {
            locationRouterService.navigate(settingsLocation, {
                accountId: accountId,
                instanceId: record.id,
            });
        },
        [accountId, locationRouterService, settingsLocation],
    );

    const onVersionHistory = useCallback(
        record => {
            locationRouterService.navigate(integratonsVersionHistoryLocation, {
                instanceId: record.id,
                entityId: record.id,
                entityType: EntityType.IM,
            });
        },
        [locationRouterService, integratonsVersionHistoryLocation],
    );

    const onUpdate = useCallback(
        record => {
            locationRouterService.navigate(settingsLocation, {
                accountId: accountId,
                instanceId: record.id,
                tab: INSTANCE_TABS.UPDATE,
            });
        },
        [accountId, locationRouterService, settingsLocation],
    );

    const onDelete = useCallback(
        (record, queryParams) => {
            instanceService
                .deleteInstance(record.id, queryParams)
                .then(() => {
                    message.info(t("instance-list.message.deleted"));
                })
                .catch(() => {
                    message.error(t("instance-list.message.not-deleted"));
                })
                .finally(() => {
                    instancesQuery.reload();
                });
        },
        [instancesQuery, instanceService],
    );
    const columns = createColumns({
        accountId,
        getDefaultLocation,
    });

    const { route } = useRoute();

    const [
        [checkTransportFor, mergeRequestId, forceReloadParam],
        setResolvedMergeRequestParams,
    ] = useState([undefined, undefined, undefined]);

    useEffect(() => {
        setResolvedMergeRequestParams([
            parseInt(route.params?.checkTransportFor, 10),
            parseInt(route.params?.mergeRequestId, 10),
            route.params?.forceReloadParam,
        ]);
    }, [
        route.params?.checkTransportFor,
        route.params?.forceReloadParam,
        route.params?.mergeRequestId,
    ]);

    const confirmModal = useConfirmModal();

    const showTransportNotFoundModal = useCallback(
        () =>
            confirmModal.confirm({
                title: t("instances.transport.not-found.modal.title"),
                message: t("instances.transport.not-found.modal.content"),
                okText: null,
                cancelText: t("general.close"),
            }),
        [confirmModal],
    );

    const showTransportFailedModal = useCallback(
        ({ url }) =>
            confirmModal.confirm({
                title: t("instances.transport.failed.modal.title"),
                message: t("instances.transport.failed.modal.content", {
                    url: (
                        <UnityLink
                            target="_blank"
                            rel="noopener noreferer"
                            href={url}
                            label={url}
                        />
                    ),
                }),
                okText: null,
                cancelText: t("general.close"),
                // onConfirm: () => {}
            }),
        [confirmModal],
    );
    const { trackButtonClick } = useTrackButtonClick();
    useEffect(() => {
        if (
            !isNaN(checkTransportFor) &&
            !isNaN(mergeRequestId) &&
            forceReloadParam
        ) {
            // console.log("%c[IL.eff]", "color:coral;", {
            //     checkTransportFor,
            //     mergeRequestId,
            //     forceReloadParam,
            // });
            trackButtonClick({ name: getTrackName("MergeStart") });
            instanceService
                .getListOfMergeRequests(checkTransportFor, mergeRequestId)
                .then(getData)
                .then(mergeRequest => {
                    if (mergeRequest.state === MERGE_REQUEST_STATE.OPENED) {
                        if (
                            mergeRequest.mergeStatus ===
                            MERGE_STATUS.CANNOT_BE_MERGED
                        ) {
                            showTransportFailedModal({
                                url: mergeRequest.webUrl,
                            });
                        } else if (
                            mergeRequest.mergeStatus ===
                            MERGE_STATUS.CAN_BE_MERGED
                        )
                            setSelectedTransport(mergeRequest);
                    } else {
                        showTransportNotFoundModal();
                    }
                });
        }
    }, [
        axiosService,
        instanceService,
        showTransportFailedModal,
        showTransportNotFoundModal,
        mergeRequestId,
        checkTransportFor,
        forceReloadParam,
        trackButtonClick,
    ]);

    const onTransport = useCallback(
        record => {
            setSelectedInstance(record);
        },
        [setSelectedInstance],
    );

    const [recordToCheck, setRecordToCheck] = useState(null);
    const allowedEnvironmentsResource = useAllowedEnvsToTransportQuery(
        recordToCheck?.id,
        !isNaN(recordToCheck?.id),
    );

    const needToCheckAsync = useCallback(
        record =>
            record.inCloud &&
            includesPermission(securityContext, INTEGRATION_ADMIN_PERMISSIONS),
        [securityContext],
    );

    const startIm = useCallback(
        record =>
            instanceService
                .startInstance(record)
                .then(instancesQuery.reload)
                .catch(() => message.error(t("im-instance.stop.error"))),
        [instanceService, instancesQuery.reload],
    );
    const stopIm = useCallback(
        record =>
            instanceService
                .stopInstance(record)
                .then(instancesQuery.reload)
                .catch(() => message.error(t("im-instance.start.error"))),
        [instanceService, instancesQuery.reload],
    );
    const restartIm = useCallback(
        record =>
            instanceService
                .restartInstance(record)
                .then(instancesQuery.reload)
                .catch(() => message.error(t("im-instance.restart.error"))),
        [instanceService, instancesQuery.reload],
    );

    const canDeleteInstance = record =>
        record.state === "CREATED" ||
        record.state === "REQUEST_FAILED" ||
        record.state === "DELETE_FAILED" ||
        record.state === "PENDING";

    const mapMenuItems = useCallback(
        record => maybeTransportList =>
            [
                {
                    title: t("instance-list.button.start"),
                    onClick: startIm,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible:
                        record.state === "CREATED" &&
                        record.inCloud &&
                        isStopped(record),
                },
                {
                    title: t("instance-list.button.stop"),
                    onClick: stopIm,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible:
                        record.state === "CREATED" &&
                        record.inCloud &&
                        !isStopped(record),
                },
                {
                    title: t("instance-list.button.restart"),
                    onClick: restartIm,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible:
                        record.state === "CREATED" &&
                        record.inCloud &&
                        record.inCloud,
                },
                {
                    title: t("general.show-detail"),
                    onClick: integrationPanel.show,
                    visible: record.state === "CREATED",
                },
                {
                    title: t("instance-list.button.settings"),
                    onClick: onEdit,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible: record.state === "CREATED",
                },
                {
                    title: t("instance-list.button.version-history"),
                    onClick: onVersionHistory,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible: record.state === "CREATED",
                },
                {
                    title: t("instance-form.label.update"),
                    onClick: onUpdate,
                    permission: INTEGRATION_EDIT_PERMISSIONS,
                    visible: record.state === "CREATED" && record.inCloud,
                },
                {
                    title: t("instance-list.button.transport"),
                    onClick: onTransport,
                    permission: INTEGRATION_ADMIN_PERMISSIONS,
                    visible: (() => {
                        return (
                            record.inCloud &&
                            record.state === "CREATED" &&
                            !record.transportToMergeRequestId &&
                            maybeTransportList?.length > 0
                        );
                    })(),
                    track: { name: getTrackName("Transport") },
                },
                {
                    title: t("instance-list.button.resolve-merge-request"),
                    onClick: () =>
                        setResolvedMergeRequestParams([
                            record.id,
                            record.transportToMergeRequestId,
                            uuid(),
                        ]),
                    permission: INTEGRATION_ADMIN_PERMISSIONS,
                    visible:
                        record.state === "CREATED" &&
                        record?.transportToMergeRequestId,
                    track: { name: getTrackName("MergeRequest") },
                },
                {
                    title: t("instance-list.menu.delete"),
                    confirm: {
                        message: t("instance-list.modal.message", {
                            instanceName: record.instanceName,
                        }),
                        onConfirm: onDelete,
                    },
                    visible:
                        !(
                            isProvisioned(record) &&
                            record.cloudProvider === provider.HETZNER
                        ) && canDeleteInstance(record),
                    permission: ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
                    color: "red",
                    track: { name: getTrackName("Delete") },
                },
                {
                    title: t("instance-list.menu.force-delete"),
                    confirm: {
                        message: t("instance-list.modal.message", {
                            instanceName: record.instanceName,
                        }),
                        onConfirm: record =>
                            onDelete(record, { forceDelete: true }),
                    },
                    visible:
                        !(
                            isProvisioned(record) &&
                            record.cloudProvider === provider.HETZNER
                        ) && record.forceDeletable,
                    color: "red",
                    track: { name: getTrackName("Force Delete") },
                },
            ],
        [
            startIm,
            stopIm,
            restartIm,
            integrationPanel.show,
            onEdit,
            onVersionHistory,
            onUpdate,
            onTransport,
            onDelete,
        ],
    );

    const actionMenu = useCallback(
        record => {
            const props = record._isExpandable
                ? {
                      items: [
                          {
                              title: t("general.permissions"),
                              onClick: permissionsPanel.show,
                              visible:
                                  record.inCloud && canEditIM(record.children),
                              permission: INTEGRATION_GIT_MANAGER,
                          },
                          {
                              title: t("general.edit"),
                              onClick: record =>
                                  locationRouterService.navigate(
                                      instanceGroupEditLocation,
                                      {
                                          instanceGroup: record.groupName,
                                      },
                                  ),
                              visible:
                                  record.inCloud &&
                                  record.groupName &&
                                  canEditIM(record.children),
                              permission:
                                  ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
                          },
                      ],
                  }
                : {
                      items: needToCheckAsync(record)
                          ? mapLoadableItems(
                                mapMenuItems(record),
                                allowedEnvironmentsResource,
                            )
                          : mapMenuItems(record)(),
                      wrapperProps: {
                          onClick: () => {
                              if (needToCheckAsync(record)) {
                                  setRecordToCheck(record);
                              }
                          },
                      },
                  };

            return (
                <UserSecurityContentContext
                    projectId={accountId}
                    instanceId={
                        typeof record.id === "number" ? record.id : null
                    }
                >
                    <ActionButton record={record} {...props} />
                </UserSecurityContentContext>
            );
        },
        [
            permissionsPanel.show,
            needToCheckAsync,
            mapMenuItems,
            allowedEnvironmentsResource,
            accountId,
            locationRouterService,
            instanceGroupEditLocation,
        ],
    );

    const updatedDataSource = useTableCellUpdater(
        instancesQuery.loadable.valueMaybe(),
        {
            eventName: SSEType.UPDATE_IM_STATE,
            highlightCell: "operationalState",
            highlightFunc: () => false,
            mapFunc: receivedItem => ({
                ...receivedItem,
            }),
            getKey: item => item.id,
            iconTooltip: t("general.update-in-progress"),
        },
    );

    const groupedInstances = useMemo(
        () =>
            pipe(
                groupBy("groupName"),
                entries,
                map(([groupName, children]) => ({
                    id: `group-${children[0]?.id}`,
                    instanceName: groupName,
                    groupName,
                    children,
                    cloudProvider: children[0]?.cloudProvider,
                    inCloud: children[0]?.inCloud,
                })),
            )(updatedDataSource),
        [updatedDataSource],
    );

    return (
        <>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                defaultSort={{
                    fieldName: "instanceName",
                    sortDir: "ascending",
                }}
                columns={columns}
                fixed
                rowKey="id"
                datasetSlicing="local"
                preferencesType={preferencesType}
                exportUrl={`api/projects/${accountId}/instances/export`}
                panelControl={integrationPanel}
                renderPanelHeader={record => (
                    <>
                        <H4>{record.instanceName}</H4>
                        {record.state === "CREATED" && (
                            <IntegrationLink
                                accountId={accountId}
                                instanceId={record.id}
                                getDefaultLocation={getDefaultLocation}
                            />
                        )}
                        <Gap />
                    </>
                )}
                loading={isLoading(instancesQuery.loadable)}
                dataSource={groupedInstances}
                allExpanded={{ isAllExpandedDefault: true }}
                // TODO: this is a workaround for the hotfix #PFIM-4994
                // needs to be fixed properly without disabling the pagination
                disablePagination
            />
            <InstancePermissionsPanel
                panelControl={permissionsPanel}
                projectId={accountId}
            />
            {selectedInstance && (
                <TransportSelectedToModal
                    transportsEnvListResource={allowedEnvironmentsResource}
                    visible
                    instanceId={selectedInstance.id}
                    instanceName={selectedInstance.instanceName}
                    onFinish={({
                        projectId,
                        instanceId,
                        mergeRequestId,
                    } = {}) => {
                        if (projectId && instanceId && mergeRequestId)
                            locationRouterService.navigate(
                                accountAppLocations.instancesLocationWithTransport,
                                {
                                    accountId: projectId,
                                    checkTransportFor: instanceId,
                                    mergeRequestId: mergeRequestId,
                                },
                            );
                        instancesQuery.reload();
                        setSelectedInstance(null);
                    }}
                    onCancel={() => {
                        setSelectedInstance(null);
                    }}
                />
            )}
            {selectedTransport && (
                <TransportReadyModal
                    visible
                    instanceId={checkTransportFor}
                    mergeRequestId={selectedTransport.id}
                    instanceNameFrom={selectedTransport.instanceName}
                    instanceNameTo={selectedTransport.targetInstanceName}
                    onDiscard={() => {
                        setSelectedTransport(null);
                        instancesQuery.reload();
                    }}
                    onFinish={() => {
                        setSelectedTransport(null);
                        instancesQuery.reload();
                    }}
                    onCancel={() => {
                        setSelectedTransport(null);
                        instancesQuery.reload();
                    }}
                />
            )}
        </>
    );
};

InstancesList.propTypes = {
    accountId: PropTypes.number.isRequired,
};

export default InstancesList;
