import { UnityIcon, UnityTooltip } from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as ExternalLink } from "@pricefx/unity-components/src/icons/unicons/external-link-alt.svg";
import React from "react";

export const AccountSalesFxLink = ({ accountUrl }) => {
    function openAccountInSalesFx() {
        const win = window.open(
            accountUrl,
            "_blank",
            "noopener=yes,noreferrer=yes",
        );
        win.focus();
    }

    if (accountUrl) {
        return (
            <UnityTooltip tooltip={t("account-salesfx-link.tooltip")}>
                <span style={{ cursor: "pointer", marginLeft: "4px" }}>
                    <UnityIcon
                        component={ExternalLink}
                        onClick={openAccountInSalesFx}
                    />
                </span>
            </UnityTooltip>
        );
    } else {
        return (
            <UnityTooltip
                tooltip={t("account-salesfx-link.tooltip-not-defined")}
            >
                <span style={{ cursor: "pointer", marginLeft: "4px" }}>
                    <UnityIcon component={ExternalLink} />
                </span>
            </UnityTooltip>
        );
    }
};
