import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { naturallySortBy } from "@/utils/form.utils";
import { filter, flatMap, get, groupBy, map, pipe } from "lodash/fp";
import { mapperMappingService } from "../../../services/mapperMapping.service";

const getMetadataAttributeLabel = ({ label, name, labelTranslation }) => {
    if (labelTranslation && label && labelTranslation !== label)
        return `${labelTranslation} (${label}, "${name}")`;
    if (label && label !== name) return `${label} ("${name}")`;
    return name;
};

const createOptionFromMetadata = item => ({
    value: item.name,
    label: getMetadataAttributeLabel(item),
    type: item.type,
    mandatory: item?.mandatory ?? false,
});

const CATEGORIES = {
    PREDEFINED: "PREDEFINED",
    RENAMED_ATTRIBUTE: "RENAMED_ATTRIBUTE",
    ATTRIBUTE: "ATTRIBUTE",
};
const categorize = ({ name, label, labelTranslation }) => {
    if (!name.startsWith("attribute")) return CATEGORIES.PREDEFINED;
    if (labelTranslation && label && labelTranslation !== label)
        return CATEGORIES.RENAMED_ATTRIBUTE;
    if (name && label && name !== label) return CATEGORIES.RENAMED_ATTRIBUTE;
    return CATEGORIES.ATTRIBUTE;
};

const orderedValues = keys => obj => map(key => get(key, obj))(keys);

export const naturallySortByCategories = pipe(
    groupBy(categorize),
    orderedValues([
        CATEGORIES.PREDEFINED,
        CATEGORIES.RENAMED_ATTRIBUTE,
        CATEGORIES.ATTRIBUTE,
    ]),
    filter(Boolean),
    flatMap(naturallySortBy(getMetadataAttributeLabel)),
);

export const useOutputOptions = ({
    sourceType,
    sourceId,
    entityType,
    entityName,
    objectTypeErrors,
    canFetch = true,
    includeAll,
}) => {
    const { metadataService } = useDic();
    const outputOptionsResource = useQueryLoadable(async () => {
        if (!sourceId) throw ["Missing source ID"];
        if (!sourceType) throw ["Missing source type"];
        if (!canFetch) throw [];
        if (objectTypeErrors?.length) throw []; // PFIM-4013
        // if (objectTypeErrors?.length) throw objectTypeErrors;

        const attributtesMetadata =
            await mapperMappingService.getAttributesMetadata(
                sourceType,
                sourceId,
                entityType,
                entityName,
                metadataService,
                includeAll ? { includeAll } : undefined,
            );
        const formattedMetadata = pipe(
            naturallySortByCategories,
            map(createOptionFromMetadata),
        )(attributtesMetadata);

        return formattedMetadata;
    }, [
        sourceId,
        sourceType,
        canFetch,
        objectTypeErrors?.length,
        entityType,
        entityName,
        metadataService,
        includeAll,
    ]);

    return outputOptionsResource;
};
