import { Button } from "@/components/DesignSystem/Button";
import { ReactComponent as Copy } from "@pricefx/unity-components/src/icons/unicons/copy.svg";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import "./CellWithCopy.style.less";

export const CellWithCopy = ({ text, children }) => {
    return (
        <div>
            {children}
            <CopyToClipboard text={text}>
                <Button size="small" icon={Copy} className="pmCellWithCopy" />
            </CopyToClipboard>
        </div>
    );
};

CellWithCopy.propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default CellWithCopy;
