import PropTypes from "prop-types";
import React from "react";

export const GapBetween = ({ gap, children }) => {
    return <div style={{ display: "flex", gap: `${gap}px` }}>{children}</div>;
};

GapBetween.propTypes = {
    gap: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};
