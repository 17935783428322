import {
    Alert,
    Button,
    Collapse,
    Forms,
    Gap,
    H3,
    Link,
    Panel,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import DisableSubmitOnEnter from "@/components/FeatureFlags/components/DisableSubmitOnEnter.component";
import {
    createErrorAlert,
    updateStep,
} from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { useIntegrationUpdateChangeLog } from "@/components/Integrations/ImUpdate/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { useFormSubmissionLoadable } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import { ReactComponent as AngleRight } from "@pricefx/unity-components/src/icons/unicons/angle-right-b.svg";
import { size } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const { useForm, useFieldValue, Form, Fields, pmValidators } = Forms;

const isVersion4plus = versionString => {
    if (!versionString) return false;

    const regex = /\d+(\.\d+)+/;
    const extractedVersion = versionString?.match(regex)?.[0];
    if (!extractedVersion) return false;
    return parseInt(extractedVersion.split(".")[0]) >= 4;
};

const ACTION = {
    CODE_ANALYSIS: "CODE_ANALYSIS",
    DIRECT_UPDATE: "DIRECT_UPDATE",
};

export const UpdateCheckStep = ({
    instance,
    isDisabled,
    onNextStep,
    onDirectUpdate,
    versionOptionsQuery,
    setTargetVersion,
}) => {
    const { integrationManagerUpdateService } = useDic();
    const setErrorAlert = useSetErrorAlert();
    const onSubmit = async ({ values: { versionTo }, args: [action] }) => {
        if (action === ACTION.DIRECT_UPDATE) {
            onDirectUpdate({ instanceId: instance.id, versionTo });
        } else {
            const nextProcess = await integrationManagerUpdateService
                .startAnalysis(instance.id, versionTo)
                .then(getData)
                .catch(e => setErrorAlert(createErrorAlert(e)));
            onNextStep(updateStep.CODE_ANALYSIS)(nextProcess);
            setTargetVersion(versionTo);
        }
    };

    const { formId, submit, handleSubmit } = useForm({
        onSubmit,
    });

    const versionTo = useFieldValue({ formId, name: "versionTo" });

    const changeLogResource = useIntegrationUpdateChangeLog({
        instanceId: instance.id,
        versionTo,
    });

    const changeLog = changeLogResource.loadable.valueMaybe();

    return (
        <DisableSubmitOnEnter>
            <Text size="large">
                {t("im-instance.update.current-version", {
                    imVersion: instance.imVersion,
                })}
            </Text>
            <Gap size="medium" />
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Select
                    label={t("im-instance.version.update")}
                    placeholder={t("general.select")}
                    name="versionTo"
                    data-test="integration-version-update-select"
                    disabled={isDisabled}
                    required
                    validator={pmValidators.isRequired}
                    showSearch
                    tooltip={t("im-instance.tooltip.versions-to-update")}
                    {...getLoadableSelectProps(versionOptionsQuery.loadable)}
                />
                <div
                    style={{
                        width: "384px",
                    }}
                >
                    {versionTo === "3.8.3" && (
                        <Alert
                            message={
                                <>
                                    Your current instance uses an old SFTP
                                    solution that will be replaced by a new,
                                    more stable and reliable solution. By doing
                                    this upgrade, you will find both old and new
                                    solutions on your Integration instance.
                                    <Gap size="small" />
                                    We kindly ask you to migrate to the new SFTP
                                    solution:
                                    <ol>
                                        <li>
                                            Change your SFTP connection
                                            (Integration > Connections) to
                                            default-sftp-connection-new
                                            (automatically created).
                                        </li>
                                        <li>
                                            Replace connections in your Routes
                                            (Integration > Routes).
                                        </li>
                                        <li>
                                            Switch your ERP (or other)
                                            communication to the new SFTP server
                                        </li>
                                    </ol>
                                    Read more{" "}
                                    <Link
                                        href="https://pricefx.atlassian.net/wiki/display/IM/22-11+-+SFTP+Migration"
                                        targetBlank
                                        displayIcon="always"
                                    >
                                        here.
                                    </Link>
                                </>
                            }
                            type="warning"
                            showIcon
                            withMargin
                            closable
                        />
                    )}
                    {instance.imVersion === "3.8.3" &&
                        isVersion4plus(versionTo) && (
                            <Alert
                                message={
                                    <>
                                        Your current instance uses an old SFTP
                                        solution. If you completed the migration
                                        steps, the old solution is not needed
                                        anymore. <br />
                                        By upgrading to Integration manager 4.0+
                                        you confirm that your integrations are
                                        migrated and old SFTP solution on:{" "}
                                        <b>{instance.server}</b> can be deleted.
                                    </>
                                }
                                type="warning"
                                showIcon
                                withMargin
                                closable
                            />
                        )}
                </div>

                {changeLog ? (
                    <>
                        {size(changeLog.tickets) === 0 ? (
                            <Text size="large">
                                {t("im-instance.update.no-changes.perex")}
                            </Text>
                        ) : (
                            <>
                                {" "}
                                <Gap size="medium" />
                                <Text size="large">
                                    {t("im-instance.update.changes.perex")}
                                </Text>
                                <Collapse ghost>
                                    {changeLog.tickets.map(ticket => (
                                        <Panel
                                            header={ticket.summary}
                                            key={ticket.key}
                                        >
                                            {ticket.releaseNotes}
                                        </Panel>
                                    ))}
                                </Collapse>
                                <Gap size="medium" />
                                {changeLog.releaseNotesLink && (
                                    <Text size="large">
                                        {t("im-instance.update.release-notes", {
                                            link: (
                                                <Link
                                                    href={
                                                        changeLog.releaseNotesLink
                                                    }
                                                    targetBlank
                                                >
                                                    {t(
                                                        "im-instance.update.release-notes-link",
                                                    )}
                                                </Link>
                                            ),
                                        })}
                                    </Text>
                                )}
                            </>
                        )}

                        <Gap size="medium" />

                        <H3>{t("im-instance.update.continue-to-next-step")}</H3>
                        <Text size="large">
                            {t(
                                "im-instance.update.continue-to-next-step-perex",
                            )}
                        </Text>
                    </>
                ) : null}

                <Gap size="medium" />
                <Button
                    formId={formId}
                    onClick={() => submit(ACTION.CODE_ANALYSIS)}
                    disabled={isDisabled}
                    label={t("im-instance.update.button.code-analysis")}
                    data-test="code-analysis-button"
                    type="primary"
                    rightIcon={AngleRight}
                />
                <Button
                    formId={formId}
                    onClick={() => submit(ACTION.DIRECT_UPDATE)}
                    disabled={isDisabled}
                    label={t("im-instance.update.button.direct-update")}
                    data-test="direct-update"
                />
            </Form>
        </DisableSubmitOnEnter>
    );
};

UpdateCheckStep.propTypes = {
    versionsQuery: PropTypes.object,
    instance: PropTypes.object,
    isDisabled: PropTypes.bool.isRequired,
    onNextStep: PropTypes.func.isRequired,
    onDirectUpdate: PropTypes.func.isRequired,
};
