import { axiosService } from "../axios";
import { getData } from "./utils";

const PROJECTS_ENDPOINT = "/api/partition-assets";

export const partitionAssetsService = {
    getSamlConfiguration: partitionId =>
        axiosService
            .get(`${PROJECTS_ENDPOINT}/${partitionId}/saml-configurations`)
            .then(getData),
};
