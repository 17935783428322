import {
    ButtonMenu,
    Forms,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import {
    useCopyWFMutation,
    useEventWFOriginsQuery,
} from "@/components/EventWorkflows/loadables";
import {
    AssetsMapping,
    PMActionsMapping,
    SingleSourceMapping,
} from "@/components/EventWorkflows/OriginsMapping";
import { useWfNameExistsValidation } from "@/components/EventWorkflows/validators";
import { isLoading, LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import {
    eq,
    filter,
    isEqual,
    map,
    pipe,
    reduce,
    set,
    tap,
    toPairs,
    uniqWith,
} from "lodash/fp";
import React from "react";

const GROUP_KEY = {
    TARGET: "TARGET",
    WORKFLOW: "WORKFLOW",
    DOWNLOAD: "DOWNLOAD",
    OTHER: "OTHER",
};

/* {
    type,
    id,
    configurationId,
    targetType,
    actionId,
    actionLabel,
} */
const getGroupKey = item => {
    if (item.type !== "PFM") return GROUP_KEY.TARGET;
    if (item.targetType === "WORKFLOW") return GROUP_KEY.WORKFLOW;
    if (item.targetType === "DATA_DOWNLOAD") return GROUP_KEY.DOWNLOAD;
    return GROUP_KEY.OTHER;
};

const toNested = map(
    pipe(
        toPairs,
        reduce((acc, [k, v]) => set(k, v, acc), {}),
    ),
);

export const assetToRowValues = asset => ({
    "original.type": asset?.type,
    "original.id": asset?.id,
    "original.label": asset?.configurationId,
    "copy.type": asset?.type,
    "copy.id": asset?.id,
});

export const assetsToSingleSourceRowValues = assets =>
    pipe(
        map(assetToRowValues),
        uniqWith(isEqual),
        tap(shouldBeSingleSource => {
            if (shouldBeSingleSource.length > 1) {
                console.log(
                    "All assets must be the same to be single source!!",
                    { assets, shouldBeSingleSource },
                );
                throw new Error("Assets must be the same to be single source!");
            }
        }),
    )(assets);
export const actionToRowValues = action => ({
    _id: action?.id,
    _type: action?.type,
    "original.targetType": action?.targetType,
    "original.id": action?.actionId,
    "original.label": action?.actionLabel,
    "copy.targetType": action?.targetType,
    "copy.id": action?.actionId,
});

export const EventWorkflowCopyModal = ({
    accountId,
    record,
    visible,
    onCancel,
    reloadAll,
}) => {
    const query = useEventWFOriginsQuery({ accountId, id: record.id });
    const mutation = useCopyWFMutation({
        accountId,
        id: record.id,
        afterSuccess: () => {
            onCancel();
            reloadAll();
        },
    });
    const isSingleSource = !!record.singleSource;
    const { formId, handleSubmit, setValues } = Forms.useForm({
        onSubmit: ({ values }) => {
            const {
                _sources = [],
                _targets = [],
                _singleSource = [],
                _workflows,
                _downloads,
                ...restValues
            } = values;
            const payload = {
                ...restValues,
                sourceAssetMappings: toNested([..._sources, ..._singleSource]),
                targetAssetMappings: toNested([..._targets, ..._singleSource]),
                // assetMappings: toNested([..._sources, ..._targets]),
                platformActionMappings: toNested([
                    ..._workflows,
                    ..._downloads,
                ]),
            };
            mutation.mutate(payload);
        },
    });
    const wfNameExistsValidation = useWfNameExistsValidation({
        accountId,
        isSingleSource: !!record.singleSource,
        sourceType: record.singleSource?.sourceType,
        sourceId: record.singleSource?.sourceId,
    });

    if (!record) return null;

    return (
        <Modal visible={!!record} onClose={onCancel} width={600}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("event-wf.copy.modal.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Forms.Form formId={formId} onSubmit={handleSubmit}>
                        <Forms.FieldGroup width="max" inputWidth="max">
                            <Forms.Fields.Input
                                required
                                name="workflowName"
                                label={t(
                                    "event-wf.copy.form.workflowName.label",
                                )}
                                validator={Forms.validators.failOnFirst([
                                    Forms.pmValidators.isRequired,
                                    Forms.pmValidators.createMinLengthValidation(
                                        1,
                                    ),
                                    Forms.pmValidators.createMaxLengthValidation(
                                        255,
                                    ),
                                    wfNameExistsValidation,
                                ])}
                                initialValue={`${record.name} (copy)`}
                                // validateOnMount={false}
                            />
                            <Forms.Fields.Switch
                                name="enabled"
                                label={t("event-wf.copy.form.enabled.label")}
                                layout="horizontal"
                                noMaxWidth
                                textOn={t("general.enabled")}
                                textOff={t("general.disabled")}
                                initialValue
                            />
                            <Text>{t("event-wf.copy.form.perex")}</Text>
                            <Gap />
                            <LoadableRenderer
                                loadable={query.loadable}
                                hasValue={origins => (
                                    <>
                                        {isSingleSource ? (
                                            <SingleSourceMapping
                                                name="_singleSource"
                                                originalLabel={t(
                                                    "event-wf.copy.form.single-source.original.label",
                                                )}
                                                copyLabel={t(
                                                    "event-wf.copy.form.single-source.copy.label",
                                                )}
                                                assets={[
                                                    ...origins.sources,
                                                    ...origins.targets,
                                                ]}
                                                selectExtraProps={{ accountId }}
                                                setValues={setValues}
                                            />
                                        ) : (
                                            <>
                                                <AssetsMapping
                                                    name="_sources"
                                                    originalLabel={t(
                                                        "event-wf.copy.form.source.original.label",
                                                    )}
                                                    copyLabel={t(
                                                        "event-wf.copy.form.source.copy.label",
                                                    )}
                                                    assets={origins.sources}
                                                    selectExtraProps={{
                                                        accountId,
                                                    }}
                                                    setValues={setValues}
                                                />
                                                <AssetsMapping
                                                    name="_targets"
                                                    originalLabel={t(
                                                        "event-wf.copy.form.target.original.label",
                                                    )}
                                                    copyLabel={t(
                                                        "event-wf.copy.form.target.copy.label",
                                                    )}
                                                    assets={filter(
                                                        pipe(
                                                            getGroupKey,
                                                            eq(
                                                                GROUP_KEY.TARGET,
                                                            ),
                                                        ),
                                                        origins.targets,
                                                    )}
                                                    selectExtraProps={{
                                                        accountId,
                                                    }}
                                                    setValues={setValues}
                                                />
                                            </>
                                        )}
                                        <PMActionsMapping
                                            name="_workflows"
                                            originalLabel={t(
                                                "event-wf.copy.form.workflows.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.workflows.copy.label",
                                            )}
                                            pmActions={filter(
                                                pipe(
                                                    getGroupKey,
                                                    eq(GROUP_KEY.WORKFLOW),
                                                ),
                                                origins.targets,
                                            )}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                        <PMActionsMapping
                                            name="_downloads"
                                            originalLabel={t(
                                                "event-wf.copy.form.pmActions.original.label",
                                            )}
                                            copyLabel={t(
                                                "event-wf.copy.form.pmActions.copy.label",
                                            )}
                                            pmActions={filter(
                                                pipe(
                                                    getGroupKey,
                                                    eq(GROUP_KEY.DOWNLOAD),
                                                ),
                                                origins.targets,
                                            )}
                                            selectExtraProps={{ accountId }}
                                            setValues={setValues}
                                        />
                                    </>
                                )}
                            />
                        </Forms.FieldGroup>
                    </Forms.Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.create"),
                                    type: "primary",
                                    htmlType: "submit",
                                    formId,
                                    disabled: isLoading(query),
                                    loading: isLoading(mutation),
                                },
                                {
                                    label: t("general.cancel"),
                                    type: "text",
                                    onClick: onCancel,
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
