import {
    Button,
    ButtonGroup,
    Forms,
    Label,
    Link,
    P,
    Panel,
} from "@/components/DesignSystem";
import { CollapseContent } from "@/components/DesignSystem/Collapse/CollapseContent";
import {
    PartitionModules,
    modules,
} from "@/components/PartitionRequest/PartitionModules.component";
import {
    usePartitionNameValidator,
    usePartitionsListValidator,
} from "@/components/PartitionRequest/validators";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { t } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import { find, set } from "lodash/fp";
import moment from "moment";
import React, { useMemo } from "react";
import "./PartitionRequestForm.less";

const { useForm, Form, Fields, validators, pmValidators, success, error } =
    Forms;

const MAX_ADMIN_USERS = 999999;

const maxAdminUsersValidator = maxAdminUsers =>
    maxAdminUsers && maxAdminUsers > MAX_ADMIN_USERS
        ? error(
              t("partition-request.validation.max-admin-users", {
                  limit: MAX_ADMIN_USERS,
              }),
          )
        : success();

const isPastDate = dateValue => {
    return dateValue < new Date();
};

const checkDateOneYearFromNow = value => {
    const todayPlusOneYear = new moment().add(1, "year");

    return value?.isAfter(todayPlusOneYear)
        ? error(t("partition-request.validation.maximum-date"))
        : success();
};

const buildDatePickerValidator = isUsageEndDateLimited =>
    isUsageEndDateLimited
        ? validators.failOnFirst([
              pmValidators.isRequired,
              checkDateOneYearFromNow,
          ])
        : () => success();

const isSetAsUnlimited = maxPartitions => {
    return maxPartitions === null || maxPartitions === undefined;
};

const partitionsLimitValidator = clustersList => value => {
    const { existingPartitions, maxPartitions } =
        find({ id: value }, clustersList) ?? {};
    if (existingPartitions === undefined || !maxPartitions === undefined)
        return error(
            t("partition-request.validation.partitions-limit-fetch-error"),
        );

    if (isSetAsUnlimited(maxPartitions)) {
        return success();
    }

    return maxPartitions - existingPartitions > 0
        ? success()
        : error(
              t("partition-request.validation.partitions-limit", {
                  limit: maxPartitions,
              }),
          );
};

const mapValuesIntoNestedObject = values => {
    let nestedValues = {};
    Object.entries(values).map(([key, value]) => {
        const path = key.split("_");
        nestedValues = set(path, value, nestedValues);
    });
    return nestedValues;
};

const mapCheckedItemsToArray = items =>
    Object.entries(items)
        .filter(([, value]) => value === true)
        .map(([key]) => key);

const createPurposeOptions = (purposeList = []) =>
    purposeList?.map(purpose => ({
        label: t(`partition-request.radio.purpose.${purpose.toLowerCase()}`),
        value: purpose,
    }));

const filterNotUsedModules = values => ({
    ...values,
    maxModuleUsers: modules.reduce(
        (result, module) =>
            values.modules[module.value] && module.limitUsers
                ? {
                      ...result,
                      [module.limitUsers]:
                          values.maxModuleUsers[module.limitUsers],
                  }
                : result,
        {},
    ),
});

const DEFAULT_GET_VALUE = ({ value, defaultValue }) => value ?? defaultValue;

const ReadOnlyValueFieldInner = ({
    render = DEFAULT_GET_VALUE,
    Component = "div",
    componentProps = {},
    ...props
}) => (
    <Component style={{ marginTop: "10px" }} {...componentProps}>
        {render(props)}
    </Component>
);

export const ReadOnlyValue = props => (
    <Forms.Field as={ReadOnlyValueFieldInner} {...props} />
);

export const PartitionRequestForm = ({
    projectId,
    currencies,
    clustersList,
    contactsList,
    isUsageEndDateLimited,
    partitionNamesList,
    purposeList,
    onCancel,
    onPurposeChange,
    onSubmit,
    initialValues,
}) => {
    const isEdit = !!initialValues;
    // map items to depth
    const submit = ({ values }) => {
        const mappedValues = filterNotUsedModules(
            mapValuesIntoNestedObject(values),
        );
        const transformedValues = {
            ...mappedValues,
            usageEndDate: mappedValues?.usageEndDate
                ? new moment(mappedValues.usageEndDate).utc().format()
                : undefined,
            accountId: projectId,
            modules: mapCheckedItemsToArray(mappedValues.modules),
            createViaSupport:
                values.remoteDbSchema || !!values.otherRequirements,
            ...(isEdit
                ? {
                      partitionLabel: mappedValues.label,
                      maxModuleUsersDto: mappedValues.maxModuleUsers,
                  }
                : {}),
        };

        return onSubmit(transformedValues);
    };

    const { formId, handleSubmit, setValues, setTouched } = useForm({
        onSubmit: submit,
    });
    const mappedInitialValues = useMemo(() => {
        if (!initialValues) return undefined;

        return {
            // read only / init from child component:
            // baseUrl
            // maxModuleUsersDto
            serialNumber: initialValues.serialNumber,
            label: initialValues.name,
            description: initialValues.sfxSyncData?.description ?? "",
            majorVersion: initialValues.majorVersion,
            clusterName: initialValues.clusterName,
            currency: initialValues.currency,
            unitsOfMeasure: initialValues.unitsOfMeasure,
            maxAdminUsers: initialValues.maxAdminUsers,
            contact: initialValues.sfxSyncData?.contact,
            usageEndDate: initialValues.sfxSyncData?.usageEndDate
                ? new moment(initialValues.sfxSyncData?.usageEndDate)
                : undefined,
        };
    }, [initialValues]);
    useSetValidatedInitialValues(
        { initialValues: mappedInitialValues, setValues, setTouched },
        [mappedInitialValues],
    );

    const handlePurposeChange = event => {
        setValues({ clusterId: undefined });
        onPurposeChange(event);
    };

    const partitionsListValidator = usePartitionsListValidator({
        partitionsList: partitionNamesList,
    });

    const partitionNameValidator = usePartitionNameValidator({
        nextValidator: partitionsListValidator,
    });

    return (
        <>
            <P>
                {isEdit
                    ? t("partition-request.edit.info")
                    : t("partition-request.info")}
            </P>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Collapse
                    bordered={false}
                    defaultActiveKey={["settings", "modules-and-users"]}
                >
                    <Panel
                        header={t("partition-request.collapse.settings")}
                        key="settings"
                    >
                        <CollapseContent>
                            {isEdit ? (
                                <ReadOnlyValue
                                    name="serialNumber"
                                    label={t("partition-request.label.name")}
                                />
                            ) : (
                                <Fields.Input
                                    required
                                    name="name"
                                    label={t("partition-request.label.name")}
                                    placeholder={t(
                                        "partition-request.placeholder.name",
                                    )}
                                    tooltip={t(
                                        "partition-request.tooltip.name",
                                    )}
                                    validator={partitionNameValidator}
                                />
                            )}

                            <Fields.Input
                                required
                                name={"label"}
                                label={t("partition-request.label.label")}
                                placeholder={t(
                                    "partition-request.placeholder.label",
                                )}
                                tooltip={t("partition-request.tooltip.label")}
                                validator={pmValidators.isRequired}
                            />

                            {isEdit ? (
                                <ReadOnlyValue
                                    name="description"
                                    label={t(
                                        "partition-request.label.description",
                                    )}
                                />
                            ) : (
                                <Fields.TextArea
                                    name="description"
                                    label={t(
                                        "partition-request.label.description",
                                    )}
                                    placeholder={t(
                                        "partition-request.placeholder.description",
                                    )}
                                    rows="3"
                                />
                            )}

                            {isEdit ? (
                                <>
                                    <ReadOnlyValue
                                        name="majorVersion"
                                        label={t(
                                            "partition-request.label.version",
                                        )}
                                    />
                                    <ReadOnlyValue
                                        render={props => (
                                            <Link
                                                href={initialValues?.baseUrl}
                                                targetBlank
                                            >
                                                {DEFAULT_GET_VALUE(props)}
                                            </Link>
                                        )}
                                        name="clusterName"
                                        label={t(
                                            "partition-request.label.cluster",
                                        )}
                                    />
                                    <ReadOnlyValue
                                        name="currency"
                                        label={t(
                                            "partition-request.label.currency",
                                        )}
                                    />
                                    <ReadOnlyValue
                                        name="unitsOfMeasure"
                                        label={t("partition-request.label.uom")}
                                    />
                                </>
                            ) : (
                                <>
                                    <Fields.Select
                                        required
                                        name="purpose"
                                        allowClear={false}
                                        label={t(
                                            "partition-request.label.purpose",
                                        )}
                                        onChange={handlePurposeChange}
                                        options={createPurposeOptions(
                                            purposeList,
                                        )}
                                        placeholder={t(
                                            "partition-request.placeholder.purpose",
                                        )}
                                        showSearch
                                        validator={pmValidators.isRequired}
                                    />

                                    <Fields.Select
                                        required
                                        disabled={!clustersList.length}
                                        name="clusterId"
                                        label={t(
                                            "partition-request.label.cluster",
                                        )}
                                        options={clustersList?.map(cluster => ({
                                            label: `${cluster.optionLabel} [${cluster.serialNumber}]`,
                                            value: cluster.id,
                                        }))}
                                        placeholder={t(
                                            "partition-request.placeholder.cluster",
                                        )}
                                        validator={validators.failOnFirst([
                                            pmValidators.isRequired,
                                            partitionsLimitValidator(
                                                clustersList,
                                            ),
                                        ])}
                                    />

                                    <Fields.Select
                                        required
                                        name="defaultCurrency"
                                        label={t(
                                            "partition-request.label.currency",
                                        )}
                                        tooltip={t(
                                            "partition-request.tooltip.currency",
                                        )}
                                        options={currencies?.map(currency => ({
                                            label: currency.value,
                                            value: currency.value,
                                        }))}
                                        showSearch
                                        placeholder={t(
                                            "partition-request.placeholder.currency",
                                        )}
                                        validator={pmValidators.isRequired}
                                        width="default"
                                    />

                                    <Fields.Input
                                        required
                                        name="defaultUOM"
                                        label={t("partition-request.label.uom")}
                                        tooltip={t(
                                            "partition-request.tooltip.uom",
                                        )}
                                        placeholder={t(
                                            "partition-request.placeholder.uom",
                                        )}
                                        validator={pmValidators.isRequired}
                                        width="default"
                                    />
                                </>
                            )}
                        </CollapseContent>
                    </Panel>
                    <Panel
                        header={t(
                            "partition-request.collapse.modules-and-users",
                        )}
                        key="modules-and-users"
                    >
                        <CollapseContent>
                            <Label
                                label={t("partition-request.modules.label")}
                            />
                            <PartitionModules
                                maxModuleUsersDto={
                                    initialValues?.maxModuleUsersDto
                                }
                                setValues={setValues}
                            />

                            <Fields.InputNumber
                                name={
                                    isEdit
                                        ? "maxAdminUsers"
                                        : "numberOfAdminUsers"
                                }
                                label={t(
                                    "partition-request.label.number-of-admin-users",
                                )}
                                placeholder={t(
                                    "partition-request.modules.number-of-users.placeholder",
                                )}
                                tooltip={t(
                                    "partition-request.tooltip.number-of-admin-users",
                                )}
                                min={1}
                                max={MAX_ADMIN_USERS}
                                initialValue={null}
                                validator={maxAdminUsersValidator}
                            />

                            <Fields.Select
                                name="contact"
                                disabled={isEdit}
                                label={t("partition-request.label.contact")}
                                placeholder={t(
                                    "partition-request.placeholder.contact",
                                )}
                                options={contactsList?.map(contact => ({
                                    label: contact.name,
                                    value: contact.contactId,
                                }))}
                            />

                            {!isEdit && (
                                <Fields.Input
                                    name="credentialsEmail"
                                    label={t(
                                        "partition-request.label.credentials-email",
                                    )}
                                    placeholder={t(
                                        "partition-request.placeholder.credentials-email",
                                    )}
                                    validator={Forms.pmValidators.noValueOr(
                                        Forms.pmValidators.emailValid,
                                    )}
                                />
                            )}

                            <Fields.DatePicker
                                required={isUsageEndDateLimited}
                                name="usageEndDate"
                                label={t(
                                    "partition-request.label.usage-end-date",
                                )}
                                tooltip={t(
                                    "partition-request.tooltip.usage-end-date",
                                )}
                                disabled={isEdit}
                                disabledDate={isPastDate}
                                validator={buildDatePickerValidator(
                                    isUsageEndDateLimited,
                                )}
                            />
                        </CollapseContent>
                    </Panel>
                    {isEdit ? null : (
                        <Collapse.Panel
                            header={t(
                                "partition-request.label.non-standard-requirements",
                            )}
                            key="others"
                        >
                            <CollapseContent>
                                <Fields.Checkbox
                                    name="remoteDbSchema"
                                    label={t(
                                        "partition-request.label.remote-db-schema",
                                    )}
                                />

                                <Fields.TextArea
                                    name="otherRequirements"
                                    label={t(
                                        "partition-request.label.other-requirements",
                                    )}
                                    placeholder={t(
                                        "partition-request.label.other-requirements",
                                    )}
                                    rows="3"
                                />
                            </CollapseContent>
                        </Collapse.Panel>
                    )}
                </Collapse>

                <ButtonGroup>
                    <Button
                        label={
                            isEdit
                                ? t("general.save")
                                : t("partition-request.button.submit")
                        }
                        type="primary"
                        htmlType="submit"
                        formId={formId}
                    />
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};
