import { Link, UnityIcon } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { t } from "@/translations";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle-solid.svg";
import { entries, map, pipe } from "lodash/fp";
import React from "react";

const MERGE_STATUSES = {
    CAN_BE_MERGED: "Can be merged",
    CANNOT_BE_MERGED: "Can not be merged",
    UNCHECKED: "Unchecked",
};

export const createColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("merge-request-list.column.title"),
        name: "title",
        render: text => decodeURIComponent(text),
    },
    {
        type: fieldTypes.OPTION,
        label: t("merge-request-list.column.merge-status"),
        name: "mergeStatus",
        options: pipe(
            entries,
            map(([value, label]) => ({ value, label })),
        )(MERGE_STATUSES),
        render: value => (
            <>
                {value === "CANNOT_BE_MERGED" && (
                    <>
                        <UnityIcon
                            component={ExclamationTriangleSolid}
                            style={{ color: "#FAAD14" }}
                        />
                        &nbsp;
                    </>
                )}
                {MERGE_STATUSES[value]}
            </>
        ),
    },
    {
        type: fieldTypes.OPTION,
        label: t("merge-request-list.column.state"),
        name: "state",
        options: [
            { value: "OPENED", label: "Opened" },
            { value: "CLOSED", label: "Closed" },
            { value: "LOCKED", label: "Locked" },
            { value: "MERGED", label: "Merged" },
        ],
    },
    {
        type: fieldTypes.TEXT,
        label: t("merge-request-list.column.git-url"),
        name: "webUrl",
        render: text => (
            <Link href={text} targetBlank>
                {text}
            </Link>
        ),
    },
    {
        type: fieldTypes.NUMBER,
        label: t("merge-request-list.column.id"),
        name: "id",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("merge-request-list.column.author"),
        name: "author",
        visible: false,
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("merge-request-list.column.has-conflicts"),
        name: "hasConflicts",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("merge-request-list.column.source-branch"),
        name: "sourceBranch",
        visible: false,
    },
    {
        type: fieldTypes.TEXT,
        label: t("merge-request-list.column.description"),
        name: "description",
        render: text => decodeURIComponent(text),
        visible: false,
    },
];
