import {
    useClassQuery,
    useCreateClassMutation,
    useSaveClassMutation,
} from "@/components/Classes/loadables";
import { useClassNameValidator } from "@/components/Classes/validations";
import { TABS } from "@/components/Connections/Connections.page";
import { Button, Forms, Gap, Text } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { getClassFormTrackName as getTrackName } from "../../mixpanel/buttonNames";
import { useTrackButtonClick } from "../../mixpanel/hooks/useTrackButtonClick.hook";

const ACTION = {
    DEPLOY: "DEPLOY",
    SAVE: "SAVE",
    TEST: "TEST",
};

export const OPERATION = {
    NEW: "NEW",
    EDIT: "EDIT",
};

export const ClassForm = ({ instanceId, classId, onCancel }) => {
    const {
        locationRouterService,
        accountAppLocations: { classesLocation, classEditLocation },
    } = useDic();
    const operation = classId === "new" ? OPERATION.NEW : OPERATION.EDIT;
    const { formId, submit, handleSubmit, setValues, setTouched } =
        Forms.useForm({
            onSubmit: ({ values, args: [action] }) => {
                const operations = {
                    [OPERATION.NEW]: createClassMutation.mutate,
                    [OPERATION.EDIT]: saveClassMutation.mutate,
                };
                if (action === ACTION.SAVE || action === ACTION.DEPLOY)
                    return operations[operation]({
                        values,
                        withDeploy: action === ACTION.DEPLOY,
                    });
            },
        });
    const { trackHandler } = useTrackButtonClick();
    const afterSuccess = trackHandler(
        ({ classId, withDeploy }) => {
            if (withDeploy) {
                locationRouterService.navigate(classesLocation, {
                    tab: TABS.INSTANCE_REPOSITORY,
                });
            } else {
                if (classId) {
                    locationRouterService.navigate(classEditLocation, {
                        classId,
                    });
                } else {
                    locationRouterService.navigate(classesLocation);
                }
            }
        },
        { name: getTrackName("SubmitSuccess") },
    );

    const createClassMutation = useCreateClassMutation({
        instanceId,
        afterSuccess,
    });
    const saveClassMutation = useSaveClassMutation({
        instanceId,
        classId,
        afterSuccess,
    });
    const classQuery = useClassQuery({
        classId,
        canFetch: operation === OPERATION.EDIT,
    });
    const maybeInitialValues = classQuery.loadable.valueMaybe();
    useSetValidatedInitialValues(
        { setValues, setTouched, initialValues: maybeInitialValues },
        [maybeInitialValues],
    );

    const classNameValidator = useClassNameValidator({
        instanceId,
        currentName: maybeInitialValues?.filename,
    });

    const disabled =
        isLoading(classQuery) ||
        isLoading(createClassMutation) ||
        isLoading(saveClassMutation);

    return (
        <>
            <Text>{t("class-form.title.new-perex")}</Text>
            <Gap />
            <Text>{t("class-form.perex.save-and-deploy")}</Text>
            <Text>{t("class-form.perex.save")}</Text>
            <Gap />
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.FieldGroup>
                    <Forms.Fields.Input
                        name="filename"
                        required
                        label={t("class-form.name.label")}
                        placeholder={t("class-form.name.placeholder")}
                        validator={classNameValidator}
                        allowClear={false}
                    />
                    <Forms.Fields.CodeMirror
                        mode="xml"
                        name="content"
                        required
                        label={t("class-form.code.label")}
                        inputWidth="max"
                    />
                </Forms.FieldGroup>
                <Button
                    formId={formId}
                    type="primary"
                    htmlType="button"
                    onClick={() => submit(ACTION.DEPLOY)}
                    label={t("general.deploy")}
                    disabled={disabled}
                    track={{ name: getTrackName("Deploy") }}
                />
                <Button
                    formId={formId}
                    htmlType="submit"
                    onClick={() => submit(ACTION.SAVE)}
                    label={t("general.save")}
                    disabled={disabled}
                    track={{ name: getTrackName("Save") }}
                />
                <Button
                    htmlType="button"
                    type="text"
                    onClick={onCancel}
                    label={t("general.cancel")}
                    disabled={disabled}
                    track={{ name: getTrackName("Cancel") }}
                />
            </Forms.Form>
        </>
    );
};

ClassForm.propTypes = {
    instanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    onCancel: PropTypes.func.isRequired,
};
