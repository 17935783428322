import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";
import { PropertyTable as DesignSystemPropertyTable } from "@/components/DesignSystem/PropertyTable/PropertyTable";
import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";

const { Field } = Forms;

export const PropertyTable = ({
    label = null,
    disabled: disabledProp,
    ...props
}) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <Field
            as={DesignSystemPropertyTable}
            label={label}
            from={identity}
            disabled={disabled}
            {...props}
        />
    );
};
