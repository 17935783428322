import { useDic } from "@/components/Dic/useDic.hook";
import ElasticSearchPanel from "@/components/Integrations/Dashboard/Tabs/ElasticSearchPanel";
import { EntityType } from "@/constants/CommonConstats";
import { EVENT_WF_RUN_HISTORY_LOG_LIST } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import { keys, path, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

/*
"@timestamp": "2024-10-23T09:06:28.861Z",
"message": "Workflow CalculationFlowWF step Step2 triggered successfully",

"entityType": "WORKFLOW",
"entityName": "CalculationFlowWF",
"stepName": "Step2",

"level": "INFO",

"destinationType": "PFM",
"destinationName": "Test_QA_2",
"destinationId": 44,
"targetType": "DATA_DOWNLOAD",
"targetId": "31",
"entityId": 624,
"logger_name": "net.pricefx.integration.pmeventorchestration.core.service.event_log.EventLogService",
"port": 21356,
"index": "eo-event-log",
"@version": "1",
"msg_size": 60,
"projectId": "103",
"host": "ec2-54-77-254-147.eu-west-1.compute.amazonaws.com",
"thread_name": "task-45",
"processName": "workflow-CalculationFlowWF",
"level_value": 20000,
*/

const DEFAULT_COLUMNS = {
    "@timestamp": true,
    message: true,

    // type: true, // [workflow/scheduler],
    entityType: true,
    // name: true,
    entityName: true,
    // listenerName: true,
    stepName: true,

    // status: true,
    level: true,

    // action: true,
    // triggered: true, // (logic <logic_name> called, calculation flow <CF name> called, etc.)
    destinationType: true,
    destinationName: true,
    destinationId: true,
    targetType: true,
    targetId: true,
};
export const RunHistoryLogs = ({ accountId, initialQuery }) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(`/api/accounts/${accountId}/eo-event-logs`, params)
            .then(getData);

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={EVENT_WF_RUN_HISTORY_LOG_LIST}
            initialQuery={initialQuery}
        />
    );
};

RunHistoryLogs.propTypes = {
    accountId: PropTypes.number.isRequired,
    initialQuery: PropTypes.string,
};
