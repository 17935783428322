import { Link, Popover } from "@/components/DesignSystem";
import { HelpIcon } from "@/components/Help/HelpIcon";
import { t } from "@/translations";
import React from "react";

export const CustomImageState = {
    READY: "READY",
    BEING_CONVERTED: "BEING_CONVERTED",
    BEING_REVERTED_TO_DEFAULT: "BEING_REVERTED_TO_DEFAULT",
    BEING_REBUILT: "BEING_REBUILT",
    ERROR: "ERROR",
};

export const ImImageType = {
    STANDARD: "STANDARD",
    CUSTOM: "CUSTOM",
};

const CustomImage = ({ instance, onConvert }) => {
    return (
        <>
            {instance.customImage === false ? <>Standard</> : <>Custom</>}
            <Popover
                title={t(
                    instance.customImage
                        ? "image-info.conversion.title-to-standard"
                        : "image-info.conversion.title-convert",
                )}
                content={
                    <>
                        {t(
                            instance.customImage
                                ? "image-info.conversion.content-to-standard"
                                : "image-info.conversion.content-convert",
                        )}
                        <br />
                        {![
                            CustomImageState.BEING_CONVERTED,
                            CustomImageState.BEING_REBUILT,
                        ].includes(instance.customImageState) && (
                            <Link
                                onClick={() =>
                                    onConvert(
                                        instance.customImage
                                            ? ImImageType.STANDARD
                                            : ImImageType.CUSTOM,
                                    )
                                }
                            >
                                {t(
                                    instance.customImage
                                        ? "image-info.conversion.action-convert-to-standard"
                                        : "image-info.conversion.action-convert",
                                )}
                            </Link>
                        )}
                    </>
                }
                overlayStyle={{ width: "230px" }}
            >
                {" "}
                <HelpIcon />
            </Popover>
        </>
    );
};

export default CustomImage;
