import { Gap, Link, UnitySteps } from "@/components/DesignSystem";
import { VisibleWhenHasPermission } from "@/security/authorization";
import {
    INTEGRATION_EDIT_PERMISSIONS,
    includesPermissionForIntegration,
} from "@/security/permission.utils";
import { t } from "@/translations";
// import { Collapse } from "antd";

// import { Collapse } from "@pricefx/unity-components";
import { useDic } from "@/components/Dic/useDic.hook";
import { ContinueUpdateModal } from "@/components/Integrations/ImUpdate/ContinueUpdate.modal";
import DirectUpdateModal from "@/components/Integrations/ImUpdate/DirectUpdate.modal";
import InConflictModal from "@/components/Integrations/ImUpdate/InConflict.modal";
import { LeaveUpdateModal } from "@/components/Integrations/ImUpdate/LeaveUpdate.modal";
import {
    useIntegrationUpdateAvailableVersions,
    useIntegrationUpdateState,
} from "@/components/Integrations/ImUpdate/loadables";
import { CodeAnalysisStep } from "@/components/Integrations/ImUpdate/steps/CodeAnalysis.step.component";
import { CodeModificationStep } from "@/components/Integrations/ImUpdate/steps/CodeModification.step.component";
import { TestUpdateStep } from "@/components/Integrations/ImUpdate/steps/TestUpdate.step.component";
import { UpdateStep } from "@/components/Integrations/ImUpdate/steps/Update.step.component";
import { UpdateCheckStep } from "@/components/Integrations/ImUpdate/steps/UpdateCheck.step.component";
import { useImUpdateProcess } from "@/components/Integrations/ImUpdate/useCurrentProcess.hook";
import { useDeleteUpdate } from "@/components/Integrations/ImUpdate/useDeleteUpdate.hook";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { isSameOrNewer } from "@/utils/versionUtils";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

const IN_CONFLICT_STATE = "IN_CONFLICT";
export const updateStep = {
    NOT_STARTED: "NOT_STARTED",
    CODE_ANALYSIS: "CODE_ANALYSIS",
    CODE_MODIFICATION: "CODE_MODIFICATION",
    TEST_UPDATE: "TEST_UPDATE",
    FINISH_UPDATE: "FINISH_UPDATE",
};

const steps = Object.freeze([
    {
        index: 0,
        id: updateStep.NOT_STARTED,
        title: t("im-instance.update.step.update-check"),
        "data-test": "update-check-step",
    },
    {
        index: 1,
        id: updateStep.CODE_ANALYSIS,
        title: t("im-instance.update.step.code-analysis"),
        endpoint: "analyze",
        "data-test": "code-analysis-step",
    },
    {
        index: 2,
        id: updateStep.CODE_MODIFICATION,
        title: t("im-instance.update.step.code-modification"),
        endpoint: "modification",
        "data-test": "modification-step",
    },
    {
        index: 3,
        id: updateStep.TEST_UPDATE,
        title: t("im-instance.update.step.test-update"),
        endpoint: "test",
        "data-test": "test-update-step",
    },
    {
        index: 4,
        id: updateStep.FINISH_UPDATE,
        title: t("im-instance.update.step.update"),
        endpoint: "finish-update",
        "data-test": "update-step",
    },
]);

export const createErrorAlert = ({
    error,
    onReload,
    // onCreateSupportTicket,
}) => (
    <>
        {getErrorMessageFromError(error)}
        {/* Temporary hidden by PFIM-7818 */}
        {/* <Link onClick={onCreateSupportTicket}>
            {t("im-instance.create-support-ticket")}
        </Link> */}
        {onReload && (
            <>
                {" "}
                {" | "}
                <Link onClick={onReload}>Refetch</Link>
            </>
        )}
    </>
);

export const ImUpdate = ({ instanceQuery }) => {
    const {
        messageService,
        locationRouterService,
        locations: { defaultRouteLocation },
        accountAppLocations,
        integrationManagerUpdateService,
    } = useDic();
    const leaveUpdateModal = useVisibility();
    const continueUpdateModal = useVisibility();
    const directUpdateModal = useValueVisibility();

    const maybeInstance = instanceQuery.loadable.valueMaybe();
    const latestStepIndexRef = useRef(0);
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const [targetVersion, setTargetVersion] = useState("");
    const setErrorAlert = useSetErrorAlert();

    const versionOptionsQuery = useIntegrationUpdateAvailableVersions({
        instanceId: maybeInstance?.id,
    });
    const maybeVersionOptions = versionOptionsQuery.loadable.valueMaybe();
    const newerVersionOptionsMaybe = maybeVersionOptions?.filter(
        ({ value }) =>
            targetVersion !== value && isSameOrNewer(value, targetVersion),
    );

    const isLatestVersionMaybe =
        !targetVersion || !newerVersionOptionsMaybe
            ? undefined
            : newerVersionOptionsMaybe.length === 0;

    const stateResource = useIntegrationUpdateState({
        instanceId: maybeInstance?.id,
        onError: error => {
            console.log("error", error);
            return setErrorAlert(
                createErrorAlert({
                    error,
                    onReload: () => {
                        setErrorAlert("");
                        stateResource.reload();
                    },
                    onCreateSupportTicket: () => {
                        stateResource.reload();
                        createSupportTicket();
                    },
                }),
            );
        },
    });
    const state = stateResource.loadable.valueMaybe();
    const imUpdateProcess = useImUpdateProcess(state?.lastJobId);

    const inConflictModal = useVisibility(false);

    useEffect(() => {
        if (
            !inConflictModal.visible &&
            imUpdateProcess.loadable.valueMaybe()?.type === IN_CONFLICT_STATE
        ) {
            inConflictModal.show();
        }
    }, [imUpdateProcess, inConflictModal]);

    const createSupportTicket = useCallback(() => {
        integrationManagerUpdateService
            .createSupportTicket(maybeInstance?.id)
            .then(response =>
                messageService.success({
                    content: t("im-instance.support-ticket-created", {
                        id: response.id,
                    }),
                }),
            )
            .catch(error =>
                messageService.error({
                    content: getErrorMessageFromError(error),
                }),
            );
    }, [integrationManagerUpdateService, maybeInstance?.id, messageService]);

    const goToStep = useCallback(
        nextStepId => {
            const nextStepIndex = steps.findIndex(
                step => step.id === nextStepId,
            );
            latestStepIndexRef.current = currentStep.index;
            setCurrentStep(steps[nextStepIndex]);
        },
        [currentStep.index],
    );

    const onNextStep = useCallback(
        nextStepId => nextProcess => {
            if (nextProcess) {
                imUpdateProcess.setNextJobId(nextProcess.id);
            }
            goToStep(nextStepId);
        },
        [goToStep, imUpdateProcess],
    );

    useEffect(() => {
        if (
            ![steps[0].id, steps[4].id].includes(state?.processStep) &&
            state?.lastJobId &&
            state?.processStep !== IN_CONFLICT_STATE
        ) {
            continueUpdateModal.show();
        }

        if (state?.processStep === IN_CONFLICT_STATE) {
            inConflictModal.show();
        }

        const currentStepIndex = steps.findIndex(
            step => step.id === state?.processStep,
        );

        if (currentStepIndex !== -1) {
            latestStepIndexRef.current = currentStepIndex;
            setCurrentStep(steps[currentStepIndex]);
        }
    }, [stateResource.loadable]);

    const isDisabledForm = isEmpty(state?.processStep);

    const loadables = useComposeLoadablesMemoized([
        instanceQuery.loadable,
        imUpdateProcess.loadable,
    ]);

    const onCancel = useCallback(() => {
        leaveUpdateModal.show();
    }, [leaveUpdateModal]);

    const onReStart = useCallback(
        nextProcess => imUpdateProcess.setNextJobId(nextProcess.id),
        [imUpdateProcess],
    );

    const wasAlreadyCompleted = currentStep.index < latestStepIndexRef.current;

    const { deleteUpdate, task: deleteTask } = useDeleteUpdate({
        endpoint: currentStep?.endpoint ?? steps[1].endpoint,
        instanceId: maybeInstance?.id,
    });

    const returnToInitState = useCallback(async () => {
        await stateResource.reload();
        await instanceQuery.reload();
        locationRouterService.navigate(accountAppLocations.settingsLocation, {
            tab: "update",
        });
    }, [
        accountAppLocations.settingsLocation,
        instanceQuery,
        locationRouterService,
        stateResource,
    ]);

    const cleanUpdateProcess = useCallback(() => {
        return integrationManagerUpdateService
            .cleanupUpdate(maybeInstance?.id)
            .then(async () => {
                await stateResource.reload();
                await instanceQuery.reload();
            });
    }, [
        instanceQuery,
        integrationManagerUpdateService,
        maybeInstance?.id,
        stateResource,
    ]);

    const onStartNewUpdate = useCallback(() => {
        integrationManagerUpdateService
            .cleanupUpdate(maybeInstance?.id)
            .then(returnToInitState)
            .catch(error =>
                setErrorAlert(
                    createErrorAlert({
                        error,
                        onCreateSupportTicket: createSupportTicket,
                    }),
                ),
            );
    }, [
        createSupportTicket,
        integrationManagerUpdateService,
        maybeInstance?.id,
        returnToInitState,
        setErrorAlert,
    ]);

    return (
        <VisibleWhenHasPermission
            permissionCheckFunc={c =>
                includesPermissionForIntegration(
                    c,
                    maybeInstance?.id,
                    maybeInstance?.accountId,
                    INTEGRATION_EDIT_PERMISSIONS,
                )
            }
        >
            <InConflictModal
                onStartNewUpdate={() => {
                    onStartNewUpdate();
                    inConflictModal.hide();
                }}
                visible={inConflictModal.visible}
            />
            <DirectUpdateModal
                instanceId={directUpdateModal.value?.instanceId}
                versionTo={directUpdateModal.value?.versionTo}
                visible={directUpdateModal.visible}
                onCancel={directUpdateModal.hide}
                onCreateSupportTicket={createSupportTicket}
                onStartNewUpdate={onStartNewUpdate}
                onNextStep={nextStepId => nextProcess => {
                    onNextStep(nextStepId)(nextProcess);
                    // stateResource.reload();
                    directUpdateModal.hide();
                }}
            />
            <ContinueUpdateModal
                visible={continueUpdateModal.visible}
                onCancel={continueUpdateModal.hide}
                onStartNew={() =>
                    deleteUpdate({
                        onSuccess: async () => {
                            continueUpdateModal.hide();
                            await returnToInitState();
                        },
                    })
                }
                onContinue={continueUpdateModal.hide}
            />
            <LeaveUpdateModal
                deleteTask={deleteTask}
                visible={leaveUpdateModal.visible}
                onCancel={leaveUpdateModal.hide}
                onDiscard={() =>
                    deleteUpdate({
                        onSuccess: async () => {
                            leaveUpdateModal.hide();
                            await returnToInitState();
                        },
                    })
                }
                onSaveAndLeave={() => {
                    locationRouterService.navigate(defaultRouteLocation);
                }}
            />
            <UnitySteps current={currentStep.index} steps={steps} />
            <Gap />
            <LoadableRenderer
                loadable={loadables}
                hasValue={([instance, process]) => (
                    <>
                        {currentStep.index === 0 && (
                            <UpdateCheckStep
                                isDisabled={isDisabledForm}
                                instance={instance}
                                onNextStep={onNextStep}
                                onDirectUpdate={directUpdateModal.show}
                                versionOptionsQuery={versionOptionsQuery}
                                setTargetVersion={setTargetVersion}
                            />
                        )}
                        {currentStep.index === 1 && (
                            <CodeAnalysisStep
                                instanceId={instance.id}
                                process={process}
                                onNextStep={onNextStep}
                                onReAnalyze={onReStart}
                                onCancel={onCancel}
                                wasAlreadyCompleted={wasAlreadyCompleted}
                            />
                        )}
                        {currentStep.index === 2 && (
                            <CodeModificationStep
                                instanceId={instance.id}
                                process={process}
                                onBack={goToStep}
                                onNextStep={onNextStep}
                                onCancel={onCancel}
                                wasAlreadyCompleted={wasAlreadyCompleted}
                            />
                        )}
                        {currentStep.index === 3 && (
                            <TestUpdateStep
                                instanceId={instance.id}
                                process={process}
                                onBack={goToStep}
                                onNextStep={onNextStep}
                                onReTest={onReStart}
                                onCancel={onCancel}
                                wasAlreadyCompleted={wasAlreadyCompleted}
                            />
                        )}
                        {currentStep.index === 4 && (
                            <UpdateStep
                                instance={instance}
                                process={process}
                                onStartNewUpdate={onStartNewUpdate}
                                cleanUpdateProcess={cleanUpdateProcess}
                                onCancel={onCancel}
                                isLatestVersionMaybe={isLatestVersionMaybe}
                            />
                        )}
                    </>
                )}
            />
        </VisibleWhenHasPermission>
    );
};

ImUpdate.propTypes = {
    instanceQuery: PropTypes.object,
};
