import { useDic } from "@/components/Dic/useDic.hook";
import {
    EO_LOGS_ENTITY_TYPE,
    EO_LOGS_TABS,
} from "@/components/EventLogs/EventLogs.page";
import { EventWorkflows } from "@/components/EventWorkflows/EventWorkflows";
import { RunningWorkflows } from "@/components/EventWorkflows/RunningWorkflows";
import { useHasEditPermission } from "@/components/EventWorkflows/useHasEditPermission.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { getEventWFTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { EO_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import React, { useCallback, useReducer } from "react";

export const TABS = {
    WORKFLOWS: "WORKFLOWS",
    RUNNING_WORKFLOWS: "RUNNING_WORKFLOWS",
};

export const EventWorkflowsPage = () => {
    const { accountId } = useAccountAppParams();
    const { activeKey, setActiveKey } = useHeaderTabs();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);
    useHideBackButton();

    const {
        locationRouterService,
        accountAppLocations: {
            eventWorkflowNewLocation,
            eventWorkflowEditLocation,
            eventWorkflowShowLocation,
            eventLogsLocation,
        },
    } = useDic();
    useBreadcrumbButton(
        {
            permissions: [EO_EDIT_PERMISSION],
            label: "location.accountApp.eventOrchestration.eventWorkflows.create",
            onClick: () =>
                locationRouterService.navigate(eventWorkflowNewLocation),
            track: { name: getTrackName("New") },
        },
        [],
    );
    const canEdit = useHasEditPermission();
    const onEdit = useCallback(
        ({ id }) =>
            locationRouterService.navigate(eventWorkflowEditLocation, {
                wfId: id,
            }),
        [eventWorkflowEditLocation, locationRouterService],
    );
    const onShowDefinition = useCallback(
        ({ id }) =>
            locationRouterService.navigate(eventWorkflowShowLocation, {
                wfId: id,
            }),
        [eventWorkflowShowLocation, locationRouterService],
    );
    const onShowRunHistory = useCallback(
        ({ id }) =>
            locationRouterService.navigate(eventLogsLocation, {
                tab: EO_LOGS_TABS.RUN_HISTORY,
                entityType: EO_LOGS_ENTITY_TYPE.WORKFLOW,
                entityId: id,
            }),
        [eventLogsLocation, locationRouterService],
    );

    return (
        <HeaderTabs defaultActiveKey={TABS.WORKFLOWS}>
            <HeaderTabs.TabPane
                tab={t("event-wf.tab.workflows")}
                key={TABS.WORKFLOWS}
                forceRender
            >
                <EventWorkflows
                    accountId={accountId}
                    visible={activeKey === TABS.WORKFLOWS}
                    onEdit={onEdit}
                    onShowDefinition={onShowDefinition}
                    reloadToken={reloadToken}
                    reloadAll={reloadAll}
                    canEdit={canEdit}
                    onShowRunHistory={onShowRunHistory}
                />
            </HeaderTabs.TabPane>
            <HeaderTabs.TabPane
                tab={t("event-wf.tab.running-workflows")}
                key={TABS.RUNNING_WORKFLOWS}
                forceRender
            >
                <RunningWorkflows
                    accountId={accountId}
                    visible={activeKey === TABS.RUNNING_WORKFLOWS}
                    reloadToken={reloadToken}
                    reloadAll={reloadAll}
                    canEdit={canEdit}
                    onShowRunHistory={onShowRunHistory}
                />
            </HeaderTabs.TabPane>
        </HeaderTabs>
    );
};
