import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    pendingPromise,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";
import get from "lodash/fp/get";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import { TYPE } from "./constants";

export const getNotificationRuleUrl = (projectId, sub) =>
    `/api/projects/${projectId}/notification-rules${sub ? `/${sub}` : ""}`;

export const useGetNotificationRuleQuery = ({ projectId, id, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();
        if (!id) return {};

        return axiosService
            .get(getNotificationRuleUrl(projectId, id))
            .then(getData);
    }, [axiosService, canFetch, id, projectId]);
};

export const usePostNotificationRule = ({ afterSave = noop, projectId }) => {
    const { axiosService } = useDic();
    const currentAfterSave = useCurrentHandler(afterSave);
    return useMutationLoadableWithNotification(
        async alert => {
            const { data } = await axiosService.post(
                getNotificationRuleUrl(projectId, alert.id),
                alert,
            );

            currentAfterSave(data);
            return data;
        },
        [projectId, currentAfterSave, axiosService],
        ({ name }) => t("notification-rule.save.success", { name }),
    );
};

export const useDeleteNotificationRuleMutation = ({
    projectId,
    afterSuccess,
}) => {
    const { axiosService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async record => {
            const { data } = await axiosService.delete(
                getNotificationRuleUrl(projectId, record.id),
            );
            currentAfterSuccess(data);
            return record;
        },
        [axiosService, currentAfterSuccess, projectId],
        ({ name }) => t("notification-rule.delete.success", { name }),
    );
};

const entitiesOptionsFetchers = {
    [TYPE.PARTITION_JOB]: ({ axiosService, projectId }) =>
        axiosService.get(`/api/projects/${projectId}/partition-assets`).then(
            pipe(
                get("data"),
                map(({ id, serialNumber }) => ({
                    value: id,
                    label: serialNumber,
                })),
            ),
        ),
    [TYPE.ROUTE_CHECK]: ({ axiosService, projectId }) =>
        axiosService.get(`/api/projects/${projectId}/instances`).then(
            pipe(
                get("data"),
                map(({ id, instanceName }) => ({
                    value: id,
                    label: instanceName,
                })),
            ),
        ),
    [TYPE.WORKFLOW]: ({ axiosService, projectId }) =>
        axiosService.get(`/api/accounts/${projectId}/event-workflows`).then(
            pipe(
                get("data"),
                map(({ id, name }) => ({
                    value: id,
                    label: name,
                })),
            ),
        ),
};

export const ALL_ENTITY_IDS = "ALL";
const ALL_OPTION = { value: ALL_ENTITY_IDS, label: "All" };

export const useEntitiesOptionsResource = ({ type, projectId }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            (
                entitiesOptionsFetchers[type]?.({ axiosService, projectId }) ??
                pendingPromise()
            ).then(options => [ALL_OPTION, ...options]),
        [projectId, type, axiosService],
    );
};

export const useTargetNameOptionsResource = ({
    projectId,
    partitionId,
    canFetch,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(
                          `/api/projects/${projectId}/partition-assets/${partitionId}/data-load-events`,
                      )
                      .then(
                          pipe(
                              get("data"),
                              map(({ targetName, targetObject }) => ({
                                  value: JSON.stringify([
                                      targetName,
                                      targetObject,
                                  ]),
                                  label: `${targetName} - ${targetObject}`,
                              })),
                              addAllDataloadEventsOption,
                          ),
                      )
                : pendingPromise(),
        [projectId, partitionId, axiosService, canFetch],
    );
};

export const ALL_TARGET_NAMES = JSON.stringify(["ALL", "ALL"]);
const ALL_TARGET_NAMES_OPTION = {
    value: ALL_TARGET_NAMES,
    label: "Target all",
};
const addAllDataloadEventsOption = dataloadEvents => {
    dataloadEvents.unshift(ALL_TARGET_NAMES_OPTION);
    return dataloadEvents;
};

export const getLoadableTargetNameSelectProps = ({
    loadable,
    partitionIds,
    isMultipleEntityIds,
}) => {
    if (!partitionIds?.length)
        return {
            options: [],
            disabled: true,
            placeholder: "",
            tooltip: "Please, select partition first",
        };
    if (isMultipleEntityIds)
        return {
            options: [ALL_TARGET_NAMES_OPTION],
            disabled: true,
            placeholder: "",
        };

    switch (loadable.state) {
        case "loading":
            return {
                options: [],
                loading: true,
                disabled: true,
                // TODO: investigate why unity select doesn't display loading indicator and remove:
                placeholder: "Loading...",
                // tooltip: ""
            };
        case "hasValue":
            return {
                options: loadable.contents ?? [],
                tooltip: `Identify running job on partition, or select "Target all"
                 to create notification to any job get a target state.`,
            };
        case "hasError":
            return {
                options: [],
                disabled: true,
                placeholder: "",
                tooltip: "Partition unavailable",
            };
    }
};
