import { useSetDebugConnectionMutation } from "@/components/Connections/loadables";
import Changed from "@/components/DateTime/Changed";
import { SwitchAsync } from "@/components/DesignSystem";
import { colors } from "@/components/DesignSystem/colors";
import { GapBetween } from "@/components/DesignSystem/Gap/GapBetween";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { Tooltip } from "@/components/DesignSystem/Tooltip";
import { DEPLOYMENT_STATUS } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import { t } from "@/translations";
import { ReactComponent as IconInfoCircle } from "@pricefx/unity-components/dist/es/icons/unicons/info-circle.svg";
import React from "react";

const isPricefx = record => record.type?.startsWith("pricefx");

export const instanceRepoConnectionsColumns = ({
    maybeDebugEnabled,
    instanceId,
}) =>
    [
        {
            type: fieldTypes.TEXT,
            label: t("mapper-list.header.name"),
            name: "name",
        },
        {
            type: fieldTypes.TEXT,
            label: t("mapper-list.header.type"),
            name: "type",
        },
        {
            type: fieldTypes.TEXT,
            label: t("customer-integration-list.header.deployment-status"),
            name: "deploymentStatus",
            render: (text, record) => (
                <GapBetween gap={3}>
                    <IconWithTooltip
                        visible={
                            record.deploymentStatus === DEPLOYMENT_STATUS.FAILED
                        }
                        tooltip={record.message}
                        icon={IconInfoCircle}
                        color={colors.danger.primary}
                    />
                    {text}
                </GapBetween>
            ),
        },
        {
            type: fieldTypes.TEXT,
            canFilter: false,
            label: t("mapper-list.header.changed"),
            name: "updatedAt",
            render: (text, record) => <Changed key={record.id} data={record} />,
        },
        maybeDebugEnabled && {
            label: t("connection-list.header.debug"),
            type: fieldTypes.TEXT,
            name: "debug",
            canFilter: false,
            render: (debug, record) => (
                <Tooltip trigger="click" key={record.name} placement="left">
                    <SwitchAsync
                        initialValue={debug}
                        useMutation={useSetDebugConnectionMutation}
                        from={({ checked }) => ({
                            checked,
                            instanceId,
                            name: record.name,
                        })}
                        disabled={!isPricefx(record)}
                        textOn={t("general.true")}
                        textOff={t("general.false")}
                    />
                </Tooltip>
            ),
        },
        {
            type: fieldTypes.TEXT,
            label: t("mapper-list.header.revision-number"),
            name: "revisionNumber",
        },
        {
            type: fieldTypes.TEXT,
            label: t("connection-list.header.path"),
            name: "path",
            visible: false,
        },
    ].filter(Boolean);
