import { Spinner, UnityLayout } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { Documentation } from "@/components/Integrations/Dashboard/Tabs/Documentation/Documentation";
import { FailedEventsList } from "@/components/Integrations/Dashboard/Tabs/FailedEventsList/FailedEventsList";
import { InstanceMessages } from "@/components/Integrations/Dashboard/Tabs/InstanceMessages/InstanceMessages";
import {
    HeaderTabs,
    useHeaderTabs,
} from "@/components/PageLayout/components/HeaderTabs.component";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { EntityType } from "@/constants/CommonConstats";
import { provider } from "@/constants/provider.constants";
import { INSTANCE_DASHBOARD } from "@/constants/sessionState.constants";
import {
    SecurityContext,
    isAuthorizedByPermissionOr,
} from "@/security/authorization";
import { useHasPermission } from "@/security/hooks/useHasPermission.hook";
import {
    ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS,
    INTEGRATION_OVERVIEW_PERMISSIONS,
    INTEGRATION_READ_EDIT_PERMISSIONS,
} from "@/security/permission.utils";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { isSameOrNewer } from "@/utils/versionUtils";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import ElasticSearchPanel from "./Tabs/ElasticSearchPanel";
import { InstanceDeploymentLog } from "./Tabs/InstanaceDeploymentLog.component";
import LogFilePanel from "./Tabs/LogFileTab/LogFilePanel";
import LoggersTable from "./Tabs/Loggers/LoggersTable.component";
import { MergeRequestsList } from "./Tabs/MergeRequests/MergeRequestsList";
import RecordsPanel from "./Tabs/Records/RecordsPanel";
import SystemHealth from "./Tabs/SystemHealth";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";

const TabPane = HeaderTabs.TabPane;

const TABS = {
    OVERVIEW: "OVERVIEW",
    MESSAGES: "MESSAGES",
    RECORDS: "RECORDS",
    LOGFILE: "LOGFILE",
    LOG_HISTORY: "LOG_HISTORY",
    LOGGERS: "LOGGERS",
    TEMPLATES: "TEMPLATES",
    MERGE_REQUESTS: "MERGE_REQUESTS",
    FAILED_EVENTS: "FAILED_EVENTS",
    DOCUMENTATION: "DOCUMENTATION",
};

const DEFAULT_COLUMNS = {
    "camel.routeId": true,
    message: true,
    "@timestamp": true,
};

const DOCUMENTATION_MINIMUM_VERSION = "5.0.0";

const InstanceDashboard = ({ instanceLoadable, projectId, instanceId }) => {
    const { instanceService } = useDic();
    const { activeKey } = useHeaderTabs();
    const instance = instanceLoadable.valueMaybe();
    const isRequested = instance?.state === "REQUESTED";

    useFullWidthTableLayout();

    const securityContext = useContext(SecurityContext);

    const canRead = useMemo(
        () =>
            isAuthorizedByPermissionOr(
                INTEGRATION_OVERVIEW_PERMISSIONS,
                securityContext.permissions,
            ),
        [securityContext.permissions],
    );

    const canReadDeploymentLog = useMemo(
        () =>
            isAuthorizedByPermissionOr(
                ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS,
                securityContext.permissions,
            ),
        [securityContext.permissions],
    );
    const canReadEditIntegration = useHasPermission(
        INTEGRATION_READ_EDIT_PERMISSIONS,
    );

    const fetchData = params =>
        instanceService.fetchDataSample(instanceId, params).then(getData);

    useLoadableAlert({
        loadable: instanceLoadable,
        hasValue: useCallback(
            ({ state }) => ({
                visible: state == "STOPPED_BY_WORKFLOW",
                type: "warning",
                message: t("partition-provisioning.warning"),
                _persistOnLocationChange: true,
            }),
            [],
        ),
        hasError: undefined,
    });

    if (!instance) return <Spinner text={t("general.loading")} />;

    return (
        <HeaderTabs>
            <TabPane tab="Overview" key={TABS.OVERVIEW} visible={!isRequested}>
                <UnityLayout.Content padding>
                    {activeKey === TABS.OVERVIEW && (
                        <SystemHealth
                            instanceId={instanceId}
                            gitRepositoryUrl={instance.gitRepositoryUrl}
                            imGitLog={instance.imGitLog}
                            instanceLog={instance.instanceLog}
                        />
                    )}
                </UnityLayout.Content>
            </TabPane>
            <TabPane
                tab={"Messages"}
                key={TABS.MESSAGES}
                visible={canRead && !isRequested}
            >
                <UnityLayout.Content padding>
                    {activeKey === TABS.MESSAGES && (
                        <InstanceMessages instanceId={instanceId} />
                    )}
                </UnityLayout.Content>
            </TabPane>
            <TabPane
                tab={"Records"}
                key={TABS.RECORDS}
                visible={canRead && !isRequested}
            >
                <UnityLayout.Content padding>
                    {activeKey === TABS.RECORDS && (
                        <RecordsPanel instanceId={instanceId} />
                    )}
                </UnityLayout.Content>
            </TabPane>
            <TabPane
                tab={"Logfile"}
                key={TABS.LOGFILE}
                visible={canRead && provider.HETZNER === instance.provider}
            >
                <UnityLayout.Content padding>
                    {activeKey === TABS.LOGFILE && (
                        <LogFilePanel
                            instanceId={instanceId}
                            instanceVersion={instance.imVersion}
                            isRequested={isRequested}
                        />
                    )}
                </UnityLayout.Content>
            </TabPane>
            <TabPane
                tab={"Log History"}
                key={TABS.LOG_HISTORY}
                visible={canRead}
            >
                {activeKey === TABS.LOG_HISTORY && (
                    <ElasticSearchPanel
                        accountId={projectId}
                        entityId={instanceId}
                        entityType={EntityType.INTEGRATION}
                        defaultColumns={DEFAULT_COLUMNS}
                        fetchData={fetchData}
                        exportUrl={`/api/instances/${instanceId}/elastic-search/export`}
                        sessionStateName={INSTANCE_DASHBOARD}
                    />
                )}
            </TabPane>
            <TabPane
                tab="Loggers"
                key={TABS.LOGGERS}
                visible={canRead && !isRequested}
            >
                {activeKey === TABS.LOGGERS && (
                    <LoggersTable instanceId={instanceId} />
                )}
            </TabPane>
            <TabPane
                tab={t("instance.templates.tab")}
                key={TABS.TEMPLATES}
                visible={canRead && !isRequested && canReadDeploymentLog}
            >
                <InstanceDeploymentLog
                    accountId={projectId}
                    instanceId={instanceId}
                />
            </TabPane>
            <TabPane
                tab={t("instance.mergeRequests.tab")}
                key={TABS.MERGE_REQUESTS}
                visible={canRead && !isRequested && instance.inCloud}
            >
                <MergeRequestsList instanceId={instanceId} />
            </TabPane>
            <TabPane
                tab={t("instance.failed-events.tab")}
                key={TABS.FAILED_EVENTS}
                visible={canRead && !isRequested && canReadEditIntegration}
            >
                <FailedEventsList
                    instanceId={instanceId}
                    visible={activeKey === TABS.FAILED_EVENTS}
                />
            </TabPane>
            {isSameOrNewer(
                instance.imVersion,
                DOCUMENTATION_MINIMUM_VERSION,
            ) && (
                <TabPane
                    tab={t("instance.documentation.tab")}
                    key={TABS.DOCUMENTATION}
                    visible={canRead && !isRequested}
                >
                    {activeKey === TABS.DOCUMENTATION && (
                        <Documentation
                            accountId={projectId}
                            instanceId={instanceId}
                            imVersion={instance.imVersion}
                            instanceName={instance.instanceName}
                            visible={activeKey === TABS.DOCUMENTATION}
                        />
                    )}
                </TabPane>
            )}
        </HeaderTabs>
    );
};

InstanceDashboard.propTypes = {
    instanceLoadable: PropTypes.object,
    projectId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
};

export default InstanceDashboard;
