import { useDic } from "@/components/Dic/useDic.hook";
import { TrackingContext } from "@/mixpanel/TrackingContextProvider";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { useCustomModal } from "@/modules/modal";
import { useContext } from "react";

export const useSubmitPartitionMutation = ({
    isEdit,
    accountId,
    partitionId,
}) => {
    const {
        locationRouterService,
        partitionRequestService,
        accountAppLocations: { partitionsLocation },
    } = useDic();
    const { trackWorkflow } = useContext(TrackingContext);
    const customModal = useCustomModal();

    return useMutationLoadableWithNotification(
        async payload => {
            if (isEdit)
                return partitionRequestService
                    .modifyPartition({
                        accountId,
                        partitionId,
                        payload,
                    })
                    .then(() =>
                        locationRouterService.navigate(partitionsLocation),
                    );
            else
                return partitionRequestService
                    .submitRequest(payload, customModal, trackWorkflow)
                    .then(() =>
                        locationRouterService.navigate(partitionsLocation),
                    );
        },
        [
            accountId,
            customModal,
            isEdit,
            locationRouterService,
            partitionId,
            partitionRequestService,
            partitionsLocation,
            trackWorkflow,
        ],
        isEdit ? "partition-edit.completed" : "partition-request.completed",
    );
};
