import {
    STEP_SOURCE_EVENT_LOGIC,
    listenerStepFormSteps,
} from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { StepForm } from "@/components/StepForm";
import { t } from "@/translations";
import { identity, isEmpty } from "lodash/fp";
import React, { useCallback, useMemo } from "react";

const eventFrom = ({ filters, ...item }) => ({
    ...item,
    ...(filters
        ? {
              eventFilter: filters?.length ? { filters } : null,
          }
        : {}),
});
const eventTo = ({ eventFilter, ...item }) => ({
    ...item,
    ...{ filters: eventFilter?.filters || [] },
});

const from = ({
    values: {
        sourceEvents = [],
        skipEvents = [],
        actions = [],
        combinationLogic = STEP_SOURCE_EVENT_LOGIC.ONE_OF,
        timeoutInMinutes,
        _stepTriggerTimeoutInMinutes,
        ...listenerRest
    },
    initialValues,
}) => {
    return {
        ...initialValues,
        ...listenerRest,
        timeoutInMinutes,
        stepTarget: { actions },
        stepTrigger: {
            sourceEvents: sourceEvents.map(eventFrom),
            skipEvents: skipEvents.map(eventFrom),
            stepSourceEventLogic: combinationLogic,
            timeoutInMinutes: _stepTriggerTimeoutInMinutes,
        },
    };
};
const to = initialValues => ({
    ...initialValues,
    actions: initialValues?.stepTarget?.actions?.map(identity),
    sourceEvents: initialValues?.stepTrigger?.sourceEvents?.map(eventTo),
    skipEvents: initialValues?.stepTrigger?.skipEvents?.map(eventTo),
    combinationLogic: initialValues?.stepTrigger?.stepSourceEventLogic,
    _stepTriggerTimeoutInMinutes: initialValues?.stepTrigger?.timeoutInMinutes,
});

const obj = {};

export const ListenerStepForm = ({
    accountId,
    initialValues: _initialValues = obj,
    setInitialValues,
    listeners,
    isFirstListener,
    isSingleSource,
    singleSource,
    eoTypesQuery,
    showOrderWarning,
    defaultActiveTab: defaultActiveTabProp,
    onSubmit,
    onCancel,
    readOnly,
}) => {
    const isNew = isEmpty(_initialValues);
    const isTabVariant = !isNew;
    const defaultActiveTab = isTabVariant ? defaultActiveTabProp : undefined;
    const initialValues = useMemo(() => to(_initialValues), [_initialValues]);

    const handleSubmit = useCallback(
        ({ values }) => {
            onSubmit(from({ values, initialValues }));
            onCancel();
        },
        [initialValues, onCancel, onSubmit],
    );
    const handleTabChangeSave = useCallback(
        ({ values }) => {
            const newValues = from({ values, initialValues });
            onSubmit(newValues);
            setInitialValues(newValues);
        },
        [initialValues, onSubmit, setInitialValues],
    );

    const allStepsProps = useMemoByDeepEquality({
        accountId,
        existingNames: listeners
            .map(({ name }) => name)
            .filter(name => name !== initialValues?.name),
        isFirstListener,
        isSingleSource,
        singleSource,
        eoTypesQuery,
        showOrderWarning,
    });
    const getStepButtons = useCallback(
        ({ stepperProps, formId, isTabVariant }) => [
            {
                visible: isTabVariant && !readOnly,
                label: t("general.save"),
                type: "primary",
                formId,
            },
            {
                visible: !isTabVariant && !readOnly,
                label: !stepperProps.isLastStep
                    ? t("general.continue")
                    : "Finish Listener",
                type: "primary",
                formId,
            },
            {
                visible: !isTabVariant && !stepperProps.isFirstStep,
                label: t("general.back"),
                type: "secondary",
                onClick: stepperProps.prevStep,
            },
            {
                visible: !!onCancel,
                label: readOnly ? t("general.back") : t("general.cancel"),
                type: "text",
                onClick: onCancel,
            },
        ],
        [onCancel, readOnly],
    );

    return (
        <StepForm
            title={
                readOnly
                    ? t("event-wf.form.view-listener")
                    : isNew
                    ? t("event-wf.form.add-listener")
                    : t("event-wf.form.edit-listener")
            }
            reactiveInitialValues={initialValues}
            steps={listenerStepFormSteps}
            onSubmit={handleSubmit}
            onTabChangeSave={handleTabChangeSave}
            onCancel={onCancel}
            allStepsProps={allStepsProps}
            getStepButtons={getStepButtons}
            isTabVariant={isTabVariant}
            defaultActiveTab={defaultActiveTab}
        />
    );
};
