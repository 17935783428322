import { Forms, Gap } from "@/components/DesignSystem";
import {
    loadableFromMaybeValue,
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { T, t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import {
    CONVERT_EMPTY_VALUES,
    CONVERT_EMPTY_VALUES_OPTIONS,
} from "../../../../Mappers/MapperTableWithCustomization/MapperGridForm/MapperGridForm";
import {
    MapperTableWithCustomization,
    useInputOptionsWithTypes,
} from "../../../../Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { useMapperStateWithInitialization } from "../../../../Mappers/MapperTableWithCustomization/hooks/useMapperStateWithInitialization.hook";
import { useOutputOptions } from "../../../../Mappers/MapperTableWithCustomization/loadableResources";
import { ObjectTypeSelector } from "../ObjectTypeSelector/ObjectTypeSelector";
import { useObjectTypeSelect } from "../ObjectTypeSelector/hooks/useObjectTypeSelect.hook";
import PackageDataMappingLayout from "../PackageDataMappingLayout/PackageDataMappingLayout";

const sourceType = "connections";

const { useForm, SubmitButton, Fields } = Forms;

export const IntegrationCsvMapper = ({
    globalState,
    step,
    onNext,
    onBack,
    onCancel,
    stepper,
}) => {
    const {
        fileInfo: tableExampleData = {},
        mapper: initMapper,
        // errors = [],
        // valid = false,
        entityType: initialEntityType,
        entityName: initialEntityName,
        usePricingParameterName = true,
        convertEmptyStringToNull: initialConvertEmptyStringToNull = false,
        parserConfig,
    } = globalState;
    const { connectionId } = step;
    const [fileInfo, setFileInfo] = useState(tableExampleData);

    const objectTypeSelect = useObjectTypeSelect({
        initialSourceType: sourceType,
        initialSourceId: connectionId,
        initialEntityType: initialEntityType,
        initialEntityName: initialEntityName,
    });
    const { inputOptionsWithTypes, tableExampleDataMap } =
        useInputOptionsWithTypes({ tableExampleData: fileInfo });
    const outputOptionsResource = useOutputOptions({
        sourceType,
        sourceId: connectionId,
        entityType: objectTypeSelect.entityType,
        entityName: objectTypeSelect.entityName,
        objectTypeErrors: objectTypeSelect.errors,
    });
    const { mapper, setMapper, errors, loadableMapper } =
        useMapperStateWithInitialization({
            entityType: objectTypeSelect.entityType,
            entityName: objectTypeSelect.entityName,
            inputOptionsLoadable: loadableFromMaybeValue(inputOptionsWithTypes),
            outputOptionsLoadable: outputOptionsResource.loadable,
            initMapper,
            tableData: tableExampleData,
        });
    const valid = errors.valid && objectTypeSelect.valid;

    const handleOnNext = ({ values: { convertEmptyStringToNull } }) => {
        if (!valid) {
            console.error("Disable next button!");
            return;
        }
        onNext({
            mapper,
            errors,
            entityType: objectTypeSelect.entityType,
            entityName: objectTypeSelect.entityName,
            usePricingParameterName,
            convertEmptyStringToNull:
                convertEmptyStringToNull === CONVERT_EMPTY_VALUES.NULL,
            fileInfo,
        });
    };
    const handleOnBack = useMemo(() => {
        if (valid)
            // save only valid state on back?
            return () =>
                onBack?.({
                    mapper,
                    errors,
                    entityType: objectTypeSelect.entityType,
                    entityName: objectTypeSelect.entityName,
                    usePricingParameterName,
                });
    }, [
        onBack,
        mapper,
        errors,
        valid,
        objectTypeSelect,
        usePricingParameterName,
    ]);

    // prevents first empty mapper table rendering lag
    const loadables = useComposeLoadablesMemoized([
        outputOptionsResource.loadable,
        loadableMapper,
    ]);

    const { formId, handleSubmit, setValues, setTouched, getBag } = useForm({
        onSubmit: handleOnNext,
    });

    return (
        <>
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <PackageDataMappingLayout
                    dataExample={fileInfo}
                    nextButton={
                        <SubmitButton
                            disabled={!errors.valid}
                            htmlType="submit"
                            type="primary"
                            label={t("partition-send-data.button.continue")}
                            data-test="submit"
                        />
                    }
                    onBack={handleOnBack}
                    onCancel={onCancel}
                >
                    <T id="package-data-upload.label.pfx-fields" />
                    <Gap size="small" />
                    <ObjectTypeSelector {...objectTypeSelect} />
                    <Gap />
                    <LoadableRenderer
                        loadable={loadables}
                        loading={() => <LoadableRenderer.Spinner />}
                        hasError={() => null}
                        hasValue={([outputOptions, mapper]) => (
                            <MapperTableWithCustomization
                                mapper={mapper}
                                errors={errors}
                                apiErrors={undefined}
                                onChange={setMapper}
                                outputOptions={outputOptions}
                                readOnly={false}
                                tableExampleData={fileInfo}
                                inputOptionsWithTypes={inputOptionsWithTypes}
                                tableExampleDataMap={tableExampleDataMap}
                                parserConfig={parserConfig}
                                setValues={setValues}
                                setTouched={setTouched}
                                getBag={getBag}
                                onColumnTypeChange={(name, type) => {
                                    setFileInfo(fileInfo => ({
                                        ...fileInfo,
                                        columns: fileInfo.columns.map(c =>
                                            c?.name !== name
                                                ? c
                                                : { ...c, type },
                                        ),
                                    }));
                                }}
                            />
                        )}
                    />
                    <Fields.Radio
                        name="convertEmptyStringToNull"
                        label={t("mapper-form.form.send-empty-value-as")}
                        initialValue={
                            initialConvertEmptyStringToNull
                                ? CONVERT_EMPTY_VALUES.NULL
                                : CONVERT_EMPTY_VALUES.EMPTY_STRING
                        }
                        options={CONVERT_EMPTY_VALUES_OPTIONS}
                    />
                    <Gap />
                </PackageDataMappingLayout>
            </Forms.Form>
        </>
    );
};

IntegrationCsvMapper.propTypes = {
    globalState: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    changeState: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    stepper: PropTypes.node,
    onCancel: PropTypes.func.isRequired,
};
