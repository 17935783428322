export const INSTANCE_MESSAGES = "INSTANCE_MESSAGES";
export const RECORDS_PANEL = "RECORDS_PANEL";
export const HEALTH_CHART = "HEALTH_CHART";
export const PROCESSED_FILES_TABLE = "PROCESSED_FILES_TABLE";
export const ELASTIC_SEARCH_PANEL = "ELASTIC_SEARCH_PANEL";

export const DM_LOGS_PAGE = "DM_LOGS_PAGE";
export const ACTIVITY_LOG_LIST = "ACTIVITY_LOG_LIST";
export const ACTIVITY_LOG_ADMINISTRATION_LIST =
    "ACTIVITY_LOG_ADMINISTRATION_LIST";
export const PARTITION_DASHBOARD = "PARTITION_DASHBOARD";
export const INSTANCE_DASHBOARD = "INSTANCE_DASHBOARD";
export const EVENT_WF_DEBUG_LOG_LIST = "EVENT_WF_DEBUG_LOG_LIST";
export const EVENT_WF_RUN_HISTORY_LOG_LIST = "EVENT_WF_RUN_HISTORY_LOG_LIST";
