import { UnityIcon } from "@/components/DesignSystem";
import { ReactComponent as ExclamationTriangleSolid } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-triangle.svg";
import PropTypes from "prop-types";
import React from "react";
// import Icon from "@ant-design/icons";

const yellow = "#faad14";

export const ParentWarning = ({ parent, synced }) => {
    if (!parent)
        return (
            <UnityIcon
                tooltip={"Instance parent is missing"}
                style={{ color: yellow }}
                component={ExclamationTriangleSolid}
            />
        );
    if (!synced)
        return (
            <>
                <UnityIcon
                    tooltip={"Not synced"}
                    style={{ color: yellow }}
                    component={ExclamationTriangleSolid}
                />
                {parent}
            </>
        );
    return parent;
};
ParentWarning.propTypes = {
    parent: PropTypes.string,
    synced: PropTypes.bool,
};
