import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Col, Gap, Link, Row, UnityTooltip } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { InstanceHealth } from "@/components/InstanceHealth/InstanceHealth";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import DiskSpace from "@/components/Integrations/Charts/DiskSpace";
import HealthChart from "@/components/Integrations/Charts/HealthChart";
import HeapMemory from "@/components/Integrations/Charts/HeapMemory";
import JavaMemory from "@/components/Integrations/Charts/JavaMemory";
import { ElkTags } from "@/components/Integrations/Dashboard/Tabs/ElkTags";
import WidgetGrid from "@/components/Integrations/Dashboard/Tabs/WidgetGrid";
import { ConvertImageModal } from "@/components/Integrations/Dashboard/Tabs/components/ConvertImageModal.component";
import CustomImage, {
    ImImageType,
} from "@/components/Integrations/Dashboard/Tabs/components/CustomImage.component";
import CustomImageState from "@/components/Integrations/Dashboard/Tabs/components/CustomImageState.component";
import { IM_VERSION_WITH_SUPPORT_OF_ELK_TAGS } from "@/components/Integrations/EditInstanceForm";
import { useIMVersionComplyLoadable } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import { IM_OPERATIONAL_STATE } from "@/components/Integrations/InstancesList";
import { useSetErrorAlert } from "@/components/PageLayout/useSetAlert.hook";
import SSEType from "@/components/ServerSideEvents/types";
import useLoadableWithSSE from "@/components/ServerSideEvents/useLoadableWithSSE.hook";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { provider } from "@/constants/provider.constants";
import {
    isLoading,
    pendingPromise,
    useQueryLoadable,
} from "@/modules/loadable";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import { instancesServices } from "@/services/instance.service";
import { routesService } from "@/services/routes.service";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { downloadFile } from "@/utils/downloadUtils";
import { filterVisible } from "@/utils/filterVisible";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Widget01 from "../../../../views/Widgets/Widget01";
import { logFileService } from "./LogFileTab/logfile.service";

const EMPTY = "-";

// TODO: test, operationalState should reflect update in progress
const getStatus = ({ status, operationalState }) => {
    if (
        [
            IM_OPERATIONAL_STATE.INITIATED_UPDATE,
            IM_OPERATIONAL_STATE.REQUESTED_UPDATE,
        ].includes(operationalState)
    )
        return "UPDATING";
    else return status;
};

const SystemHealth = ({
    instanceId,
    instanceProvider,
    gitRepositoryUrl,
    imGitLog,
    instanceLog,
}) => {
    const fileDownloadSize = 50 * 30 * 1024;
    const downloadRange = `bytes=-${fileDownloadSize}`;
    const setErrorAlert = useSetErrorAlert();

    const instanceQuery = useInstanceQuery({ instanceId });

    const maybeInstance = useLoadableWithSSE({
        loadable: instanceQuery.loadable,
        type: SSEType.UPDATE_IM_STATE,
        predicate: updatedState => updatedState.id === instanceId,
    });

    const [routeCount, setRouteCount] = useState(0);
    const [logFileDownloadable, setLogFileDownloadable] = useState(false);
    const [warnings, setWarnings] = useState([]);

    const basicInfo = useUserBasicInfo();

    const downloadLogFile = () => {
        logFileService
            .download(instanceId, downloadRange)
            .then(fileResponse => {
                downloadFile(fileResponse.data, "main.log");
            });
    };

    useEffect(() => {
        routesService
            .fetchRoutesDetailsOnline(instanceId)
            .then(response => {
                setRouteCount(response.data.length);
            })
            .catch(e => {
                console.error(
                    "[SystemHealth] fetchRoutesDetailsOnline error",
                    e,
                );
            });

        if (provider.HETZNER === instanceProvider) {
            logFileService.download(instanceId, "bytes=-512").then(response => {
                setLogFileDownloadable(
                    response.status >= 200 && response.status <= 299,
                );
            });
        }

        instancesServices.fetchInstanceWarnings(instanceId).then(response => {
            setWarnings(response?.data);
        });
    }, [instanceId]);

    const { instanceService } = useDic();

    const instanceHealthResource = useQueryLoadable(() => {
        return instanceId
            ? instanceService
                  .fetchInstanceHealth(instanceId)
                  .then(getData)
                  .catch(e => {
                      setErrorAlert(e.response.data.message);
                      throw e;
                  })
            : pendingPromise();
    }, [instanceId, instanceService, setErrorAlert]);

    const healthMaybe = instanceHealthResource.loadable.valueMaybe();

    const lastElkMessageTimestampResource = useQueryLoadable(() => {
        return instanceId
            ? instanceService
                  .fetchDataSample(instanceId, {
                      sort: [{ id: "@timestamp", order: "desc" }],
                      size: 1,
                  })
                  .then(
                      response =>
                          response?.data?.hits?.[0]?.source?.["@timestamp"],
                  )
            : pendingPromise();
    }, [instanceId, instanceService]);

    const maybeLastElkMessageTimestamp =
        lastElkMessageTimestampResource.loadable.valueMaybe();
    const maybeCanSeeElkTags = useIMVersionComplyLoadable({
        instanceQuery,
        minVersion: IM_VERSION_WITH_SUPPORT_OF_ELK_TAGS,
    }).valueMaybe()?.comply;

    const convertToImageModal = useValueVisibility();

    return (
        <>
            <WidgetGrid>
                <Widget01
                    loading={isLoading(instanceHealthResource)}
                    items={[
                        {
                            title: t("general.status"),
                            value: healthMaybe ? (
                                <InstanceHealth
                                    status={getStatus(healthMaybe)}
                                    warnings={warnings}
                                />
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("system-health.widget.os", {
                                os: healthMaybe ? healthMaybe.os : null,
                            }),
                            value: healthMaybe ? healthMaybe.pid : EMPTY,
                        },
                        {
                            title: t("system-health.widget.uptime"),
                            value: healthMaybe ? healthMaybe.uptime : EMPTY,
                        },
                    ]}
                    mainText={t("system-health.widget.system-info")}
                    color="primary"
                />

                <Widget01
                    loading={!maybeInstance}
                    items={[
                        {
                            title: t("system-health.widget.version"),
                            value: maybeInstance?.imVersion,
                        },
                        {
                            title: t("system-health.widget.route-count"),
                            value: routeCount,
                        },
                        {
                            title: t("general.log"),
                            value: (
                                <UnityTooltip tooltip={t("log.current-log")}>
                                    <Link onClick={() => downloadLogFile()}>
                                        {"main.log"}
                                    </Link>
                                </UnityTooltip>
                            ),
                            hidden: !logFileDownloadable,
                        },
                    ]}
                    mainText={t("system-health.widget.im")}
                    color="primary"
                />

                <Widget01
                    loading={!maybeInstance}
                    firstColumnWidth={12}
                    secondColumnWidth={12}
                    items={filterVisible([
                        {
                            title: t("system-health.widget.build-id"),
                            value: maybeInstance?.gitCommit ? (
                                basicInfo.imGitAccess ? (
                                    <Link
                                        href={maybeInstance?.buildUrl}
                                        targetBlank={true}
                                    >
                                        {maybeInstance?.buildId}
                                    </Link>
                                ) : (
                                    <>{maybeInstance?.buildId}</>
                                )
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("system-health.widget.git-commit"),
                            value: maybeInstance?.gitCommit ? (
                                basicInfo.imGitAccess ? (
                                    <Link
                                        href={maybeInstance?.gitCommitUrl}
                                        targetBlank={true}
                                    >
                                        {maybeInstance?.gitCommit}
                                    </Link>
                                ) : (
                                    <>{maybeInstance?.gitCommit}</>
                                )
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("system-health.widget.image"),
                            value: maybeInstance ? (
                                <CustomImage
                                    onConvert={convertToImageModal.show}
                                    instance={maybeInstance}
                                />
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("system-health.widget.status"),
                            value: maybeInstance ? (
                                <CustomImageState
                                    instanceId={maybeInstance?.id}
                                    onRebuild={() =>
                                        convertToImageModal.show(
                                            ImImageType.CUSTOM,
                                        )
                                    }
                                    customImageState={
                                        maybeInstance?.customImageState
                                    }
                                />
                            ) : (
                                EMPTY
                            ),
                            visible: maybeInstance?.customImageState !== null,
                        },
                    ])}
                    mainText={t("system-health.widget.build-info")}
                    color="primary"
                />

                <Widget01
                    firstColumnWidth={4}
                    secondColumnWidth={20}
                    items={[
                        {
                            title: t("system-health.widget.git-repo"),
                            value: gitRepositoryUrl ? (
                                <Link
                                    href={gitRepositoryUrl}
                                    targetBlank={true}
                                >
                                    {gitRepositoryUrl}
                                </Link>
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("general.server-log"),
                            value: instanceLog ? (
                                <Link href={instanceLog} targetBlank={true}>
                                    {instanceLog}
                                </Link>
                            ) : (
                                EMPTY
                            ),
                        },
                        {
                            title: t("general.git-log"),
                            value: imGitLog ? (
                                <Link href={imGitLog} targetBlank={true}>
                                    {imGitLog}
                                </Link>
                            ) : (
                                EMPTY
                            ),
                        },
                    ]}
                    mainText={t("system-health.widget.instance-info")}
                    color="primary"
                />
                <Widget01
                    loading={!maybeInstance || !maybeLastElkMessageTimestamp}
                    items={[
                        {
                            title: t("system-health.widget.environment-type"),
                            value: maybeInstance?.type,
                        },
                        {
                            title: t("system-health.widget.elk-last-timestamp"),
                            value: maybeLastElkMessageTimestamp ? (
                                <FormattedDateTime>
                                    {maybeLastElkMessageTimestamp}
                                </FormattedDateTime>
                            ) : (
                                EMPTY
                            ),
                        },
                    ]}
                    mainText={t("system-health.widget.environment")}
                    color="primary"
                />
            </WidgetGrid>
            <Gap size="medium" />
            {maybeCanSeeElkTags && (
                <ElkTags instanceLoadable={instanceQuery.loadable} />
            )}
            <Gap size="medium" />
            <HealthChart instanceId={instanceId} />
            <Gap size="medium" />
            {healthMaybe && (
                <Row gutter={16}>
                    <Col span={8}>
                        {healthMaybe.memory && (
                            <JavaMemory {...healthMaybe.memory} />
                        )}
                    </Col>
                    <Col span={8}>
                        {healthMaybe.heap && (
                            <HeapMemory {...healthMaybe.heap} />
                        )}
                    </Col>
                    <Col span={8}>
                        {healthMaybe.diskSpace && (
                            <DiskSpace {...healthMaybe.diskSpace} />
                        )}
                    </Col>
                </Row>
            )}
            <ConvertImageModal
                convertTo={convertToImageModal.value}
                isRebuild={maybeInstance?.customImage}
                instanceId={maybeInstance?.id}
                instanceName={maybeInstance?.instanceName}
                visible={convertToImageModal.visible}
                onCancel={convertToImageModal.hide}
                onConvert={convertToImageModal.hide}
            />
        </>
    );
};

export default SystemHealth;

SystemHealth.propTypes = {
    instanceId: PropTypes.number.isRequired,
    instanceProvider: PropTypes.string.isRequired,
    gitRepositoryUrl: PropTypes.string,
    imGitLog: PropTypes.string,
    instanceLog: PropTypes.string,
};
