import { ButtonMenu, Forms, UnityLayout } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useIsvConnectionNameValidator } from "@/components/ISVConnections/helpers";
import { mapValuesToConnection } from "@/components/ISVConnections/ISVConnection.page";
import { useEditConnectionMutation } from "@/components/ISVConnections/loadables";
import { mapPartitionsToOptions } from "@/components/Marketplace/helpers";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useRouteAccountPartitions } from "@/mixpanel/hooks/useRouteAccountPartitions.hook";
import { useMapLoadableMemoized, useQueryLoadable } from "@/modules/loadable";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React from "react";

const { useForm, Form, Fields, pmValidators, validators } = Forms;

const STATUS = {
    ACTIVE: "ENABLED",
    STOPPED: "DISABLED",
};

const DEFAULT_SAML_CONFIG_NAME = "DEFAULT";

export const useSamlConfigurationQuery = ({ partitionId }) => {
    const { partitionAssetsService } = useDic();

    return useQueryLoadable(
        async () =>
            partitionId
                ? partitionAssetsService.getSamlConfiguration(partitionId)
                : [],
        [partitionAssetsService, partitionId],
    );
};

const GeneralStepFields = ({ form, stepProps, allStepsProps }) => {
    const { accountPartitionsLoadable } = useRouteAccountPartitions();

    const partitionsOptionsLoadable = useMapLoadableMemoized(
        accountPartitionsLoadable,
        mapPartitionsToOptions,
    );

    const formId = Forms.useFormId();

    const partitionId = useFieldValue({ formId, name: "partitionId" });
    const currentSamlConfiguration = useFieldValue({
        formId,
        name: "samlConfiguration",
    });

    const samlConfigurationResource = useSamlConfigurationQuery({
        partitionId,
    });

    const samlConfigurationOptionsLoadable = useMapLoadableMemoized(
        samlConfigurationResource.loadable,
        configs =>
            configs.map(configurationName => ({
                label: configurationName,
                value: configurationName,
            })),
    );

    const isvConnectionNameValdator = useIsvConnectionNameValidator({
        accountId: allStepsProps.accountId,
        initialValues: stepProps?.initialValues,
    });

    useLoadableHasValueChangedEffect(
        samlConfigurationOptionsLoadable,
        samlConfigurations => {
            // preselect default saml configuration if it is not set
            if (!currentSamlConfiguration) {
                form.setValues({
                    samlConfiguration:
                        samlConfigurations.find(
                            config => config.value === DEFAULT_SAML_CONFIG_NAME,
                        )?.value ?? undefined,
                });
            }
        },
        [currentSamlConfiguration, form, samlConfigurationOptionsLoadable],
    );

    const resetSamlField = () => {
        form.setValues({
            samlConfiguration: undefined,
        });
    };

    return (
        <div style={{ maxWidth: "426px" }}>
            <Fields.Input
                required
                name="name"
                label={t("isv-connections.steps.general.name")}
                validator={isvConnectionNameValdator}
                inputWidth="max"
            />

            <Fields.Select
                label={t("isv-connections.steps.general.vendor")}
                name="vendor"
                required
                validator={pmValidators.isRequired}
                inputWidth="max"
                initialValue={"ENABLE"}
                options={[
                    {
                        label: "ENABLE",
                        value: "ENABLE",
                    },
                ]}
                // {...getLoadableSelectProps(transportsEnvListLoadable)}
            />

            <Fields.Select
                label={t("isv-connections.steps.general.partition")}
                name="partitionId"
                required
                validator={pmValidators.isRequired}
                inputWidth="max"
                {...getLoadableSelectProps(partitionsOptionsLoadable)}
                onChange={resetSamlField}
            />

            <Fields.Select
                label={t("isv-connections.steps.general.saml-configuration")}
                name="samlConfiguration"
                required
                validator={pmValidators.isRequired}
                inputWidth="max"
                {...getLoadableSelectProps(samlConfigurationOptionsLoadable)}
            />

            <Fields.Radio
                name="state"
                label={t("isv-connections.steps.general.status")}
                initialValue={STATUS.ACTIVE}
                options={[
                    { label: t("general.active"), value: STATUS.ACTIVE },
                    { label: t("general.stopped"), value: STATUS.STOPPED },
                ]}
            />
        </div>
    );
};

export const GeneralStepEditForm = ({
    accountId,
    initialValues,
    onSuccess,
    onCancel,
}) => {
    const editConnectionMutation = useEditConnectionMutation({
        connectionId: initialValues.id,
        afterSuccess: onSuccess,
    });

    const { formId, handleSubmit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) =>
            editConnectionMutation.mutate(
                mapValuesToConnection({
                    projectId: accountId,
                    ...initialValues,
                    ...values,
                }),
            ),
    });

    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    return (
        <UnityLayout>
            <UnityLayout.Content padding={[true, true, false, true]}>
                <Form formId={formId} onSubmit={handleSubmit}>
                    <GeneralStepFields
                        form={{
                            setValues,
                        }}
                        allStepsProps={{
                            accountId,
                        }}
                        stepProps={{
                            initialValues,
                        }}
                    />
                </Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtonsLeft={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};

export default GeneralStepFields;
