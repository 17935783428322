import Changed from "@/components/DateTime/Changed";
import { colors } from "@/components/DesignSystem/colors";
import { GapBetween } from "@/components/DesignSystem/Gap/GapBetween";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { DEPLOYMENT_STATUS } from "@/components/IntegrationRoutes/InstanceRepoIntegrationsRoutes";
import { IconWithTooltip } from "@/components/Integrations/components/IconWithTooltip";
import { t } from "@/translations";
import { ReactComponent as IconInfoCircle } from "@pricefx/unity-components/dist/es/icons/unicons/info-circle.svg";
import React from "react";

export const createColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("resources-list.header.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("customer-integration-list.header.deployment-status"),
        name: "deploymentStatus",
        render: (text, record) => (
            <GapBetween gap={3}>
                <IconWithTooltip
                    visible={
                        record.deploymentStatus === DEPLOYMENT_STATUS.FAILED
                    }
                    tooltip={record.message}
                    icon={IconInfoCircle}
                    color={colors.danger.primary}
                />
                {text}
            </GapBetween>
        ),
    },
    {
        type: fieldTypes.TEXT,
        canFilter: false,
        label: t("mapper-list.header.changed"),
        name: "updatedAt",
        render: (text, record) => <Changed key={record.id} data={record} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("mapper-list.header.path"),
        name: "path",
        visible: false,
    },
    // TODO: Maybe in future
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("resources-list.header.type"),
    //     name: "type"
    // },
    // {
    //     type: fieldTypes.OPTION,
    //     label: t("resources-list.header.status"),
    //     name: "status",
    //     options: []
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("resources-list.header.created-at"),
    //     name: "created-at"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("resources-list.header.created-by"),
    //     name: "created-by"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("resources-list.header.uploaded-at"),
    //     name: "uploaded-at"
    // },
    // {
    //     type: fieldTypes.TEXT,
    //     label: t("resources-list.header.uploaded-by"),
    //     name: "uploaded-by"
    // }
];
