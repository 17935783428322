import { TABS } from "@/components/Certificates/CertificatesList.page";
import { useCertificateNameValidator } from "@/components/Certificates/validators";
import {
    Alert,
    Button,
    ButtonGroup,
    Forms,
    Gap,
    message,
    Text,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { EncryptedField } from "@/components/EncryptedField/EncryptedField";
import { WITH_DEPLOY } from "@/components/IntegrationRoutes/form/RouteEditForm";
import { useInstanceQuery } from "@/components/IntegrationRoutes/loadables";
import { IM_VERSION_WITH_CERTIFICATE_ENCRYPTION_SUPPORT } from "@/components/Integrations/constants";
import { useIMVersionComplyLoadable } from "@/components/Integrations/InstanceEntityWorkspaceAlert/InstanceEntityWorkspaceAlert";
import {
    UserAbortError,
    useWithConflictModal,
} from "@/components/Mappers/ConflictModal";
import { useRouteInstance } from "@/mixpanel/hooks/useRouteInstance.hook";
import { LoadableRenderer, useQueryLoadable } from "@/modules/loadable";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { isSameOrNewer } from "@/utils/versionUtils";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { certificatesService } from "../../services/certificates.service";

const { SubmitButton, useForm, Form, Fields, pmValidators } = Forms;

export const IM_VERSION_WITH_SUPPORT_OF_CERTIFICATES = "1.5.1";
const certificateTypes = [
    { value: "CERTIFICATE", label: "Certificate" },
    { value: "KEY", label: "Key" },
    { value: "COMBINED", label: "Certificate and Key" },
];

export const OPERATION = {
    NEW: "NEW",
    EDIT: "EDIT",
};

const SUCCESS_MESSAGE_KEYS = {
    [OPERATION.NEW]: {
        [WITH_DEPLOY]: "certificate-form.createdAndDeployed",
        [!WITH_DEPLOY]: "certificate-form.created",
    },
    [OPERATION.EDIT]: {
        [WITH_DEPLOY]: "certificate-form.updatedAndDeployed",
        [!WITH_DEPLOY]: "certificate-form.updated",
    },
};

export const CertificateForm = ({ instanceId, certificateId }) => {
    const {
        locationRouterService,
        accountAppLocations: { certificatesLocation, certificateEditLocation },
    } = useDic();
    const withConflictModal = useWithConflictModal();

    const operation = certificateId === "new" ? OPERATION.NEW : OPERATION.EDIT;

    const instanceQuery = useInstanceQuery({ instanceId });
    const certificateDeployVersionComplyLoadable = useIMVersionComplyLoadable({
        instanceQuery,
        minVersion: IM_VERSION_WITH_SUPPORT_OF_CERTIFICATES,
    });
    useLoadableAlert({
        loadable: certificateDeployVersionComplyLoadable,
        hasValue: useCallback(
            ({ imVersion, minVersion, comply }) => ({
                visible: !comply,
                type: "info",
                message: t("certificate-form.deployment.not-supported", {
                    currentVersion: imVersion,
                    minVersion,
                }),
            }),
            [],
        ),
        hasError: undefined,
    });
    const certificateQuery = useQueryLoadable(
        async () =>
            operation === OPERATION.NEW
                ? Promise.resolve({})
                : certificatesService
                      .fetchCertificate(instanceId, certificateId)
                      .then(getData),
        [instanceId, certificateId, operation],
    );

    const goToCertificatesList = params => {
        locationRouterService.navigate(certificatesLocation, params);
    };

    const { formId, handleSubmit, submit, setValues } = useForm({
        onSubmit: ({ values, args: [withDeploy] }) => {
            const operations = {
                [OPERATION.NEW]: forceCommit =>
                    certificatesService.createCertificate(instanceId, values, {
                        withDeploy,
                        forceCommit,
                    }),
                [OPERATION.EDIT]: forceCommit =>
                    certificatesService.saveCertificate(
                        instanceId,
                        certificateId,
                        values,
                        { withDeploy, forceCommit },
                    ),
            };

            return withConflictModal(operations[operation])
                .then(res => {
                    message.success({
                        content: t(SUCCESS_MESSAGE_KEYS[operation][withDeploy]),
                    });

                    if (withDeploy) {
                        goToCertificatesList({ tab: TABS.INSTANCE_REPOSITORY });
                    } else {
                        locationRouterService.navigate(
                            certificateEditLocation,
                            { certificateId: res.data.id },
                        );
                    }
                })
                .catch(e => {
                    if (e instanceof UserAbortError) return;
                    message.error({
                        content: getErrorMessage(e.response.data),
                    });
                    throw e;
                });
        },
    });
    const nameValidator = useCertificateNameValidator({
        instanceId,
        currentName: certificateQuery.loadable.valueMaybe()?.name,
    });

    const { instanceLoadable } = useRouteInstance();
    const isEncryptionSupported = isSameOrNewer(
        instanceLoadable.valueMaybe()?.imVersion,
        IM_VERSION_WITH_CERTIFICATE_ENCRYPTION_SUPPORT,
    );

    const encryptedFieldOrTextArea = isEncryptionSupported
        ? {
              Component: EncryptedField,
              otherProps: {
                  type: Fields.TextArea,
                  formId,
                  setValues,
              },
          }
        : {
              Component: Fields.TextArea,
              otherProps: {},
          };

    return (
        <LoadableRenderer
            loadable={certificateQuery.loadable}
            hasValue={certificate => (
                <>
                    {operation === OPERATION.EDIT && (
                        <>
                            <Text size="large">
                                {t(
                                    "certificate-form.header-description.new-certificate",
                                )}
                            </Text>
                            <Gap size="large" />
                        </>
                    )}
                    <Form formId={formId} onSubmit={handleSubmit}>
                        {operation === OPERATION.EDIT && (
                            <Alert
                                message={t(
                                    "instance-entity.alerts.name-change",
                                    { entity: "certificate" },
                                )}
                                type="warning"
                                showIcon
                            />
                        )}
                        <Gap size="small" />
                        <Fields.Input
                            name="name"
                            label={t("certificate-form.label.name")}
                            placeholder={t("certificate-form.placeholder.name")}
                            required
                            validator={nameValidator}
                            initialValue={certificate?.name}
                        />
                        <Fields.Select
                            name="type"
                            label={t("certificate-form.label.certificate-type")}
                            placeholder={t("placeholder.please-select")}
                            required
                            validator={pmValidators.isRequired}
                            initialValue={certificate?.type}
                            options={certificateTypes}
                        />
                        <encryptedFieldOrTextArea.Component
                            {...encryptedFieldOrTextArea.otherProps}
                            name="body"
                            rows="25"
                            label={t("certificate-form.label.certificate-body")}
                            required
                            validator={pmValidators.isRequired}
                            initialValue={certificate?.body}
                            style={{
                                fontSize: "11px",
                                fontFamily: "Courier New",
                            }}
                        />
                        {isEncryptionSupported && <Gap size="small" />}
                        <div style={{ maxWidth: "600px" }}>
                            <Alert
                                type="warning"
                                message={
                                    isEncryptionSupported
                                        ? t(
                                              "certificate-form.encryption.supported",
                                          )
                                        : t(
                                              "certificate-form.encryption.not-supported",
                                              {
                                                  version:
                                                      IM_VERSION_WITH_CERTIFICATE_ENCRYPTION_SUPPORT,
                                              },
                                          )
                                }
                            />
                        </div>

                        <ButtonGroup>
                            <LoadableRenderer
                                loadable={
                                    certificateDeployVersionComplyLoadable
                                }
                                hasValue={({ comply }) =>
                                    comply && (
                                        <Button
                                            formId={formId}
                                            type="primary"
                                            onClick={() => submit(WITH_DEPLOY)}
                                            label={t("general.deploy")}
                                        />
                                    )
                                }
                                hasError={() => null}
                                loading={() => null}
                            />
                            <Button
                                formId={formId}
                                onClick={() => submit(!WITH_DEPLOY)}
                                label={t("general.save")}
                            />
                            <Button
                                type="text"
                                onClick={() => goToCertificatesList()}
                                label={t("general.cancel")}
                            />
                        </ButtonGroup>
                    </Form>
                </>
            )}
        />
    );
};

CertificateForm.propTypes = {
    instanceId: PropTypes.number.isRequired,
    certificateId: PropTypes.number.isRequired,
};
