import {
    Alert,
    ButtonMenu,
    Gap,
    Link,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { ImImageType } from "@/components/Integrations/Dashboard/Tabs/components/CustomImage.component";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { getIMConvertImageModalTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { messageService } from "@/modules/Message/message.service";
import {
    isLoading,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import React from "react";

export const useConvertImageMutation = ({
    convertTo,
    instanceName,
    afterSuccess,
    successMessageKey,
}) => {
    const successMessage = t(successMessageKey, {
        instanceName: instanceName,
    });

    const { instanceService } = useDic();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);
    return useMutationLoadableWithNotification(
        async ({ instanceId }) => {
            const action =
                convertTo === ImImageType.CUSTOM
                    ? instanceService.convertToCustomImage
                    : instanceService.convertToStandardImage;
            const res = await action(instanceId).catch(e =>
                messageService.error({
                    content: getErrorMessage(e.response.data),
                }),
            );

            currentAfterSuccess();
            return res;
        },
        [
            convertTo,
            instanceService.convertToCustomImage,
            instanceService.convertToStandardImage,
            currentAfterSuccess,
        ],
        successMessage,
    );
};
const DOC_LINK =
    "https://pricefx.atlassian.net/wiki/spaces/INTG/pages/4775051311/IM+Custom+Images";

const getTranslations = ({ convertTo, isRebuild }) => {
    if (convertTo === ImImageType.STANDARD) {
        return {
            title: "image-info.modal.conversion.title-to-standard",
            text: "image-info.modal.conversion.message-to-standard",
            alert: "image-info.modal.conversion.warning-to-standard",
            action: "custom-image-state.action.convert",
            success: "image-info.modal.conversion.to-standard.success",
        };
    } else {
        if (isRebuild) {
            return {
                title: "image-info.modal.conversion.title-rebuild",
                text: "image-info.modal.conversion.message-rebuild",
                alert: "image-info.modal.conversion.warning-rebuild",
                action: "custom-image-state.action.rebuild",
                success: "image-info.modal.conversion.success-rebuild",
            };
        } else {
            return {
                title: "image-info.modal.conversion.title",
                text: "image-info.modal.conversion.message",
                alert: "image-info.modal.conversion.warning",
                action: "custom-image-state.action.convert",
                success: "image-info.modal.conversion.success",
            };
        }
    }
};

export const ConvertImageModal = ({
    convertTo,
    isRebuild,
    instanceName,
    instanceId,
    visible,
    onConvert,
    onCancel,
}) => {
    const contentKeys = getTranslations({ convertTo, isRebuild });

    const convertImageMutation = useConvertImageMutation({
        convertTo,
        instanceName,
        afterSuccess: onConvert,
        successMessageKey: contentKeys.success,
    });

    return (
        <Modal visible={visible} onClose={onCancel}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={t(contentKeys.title)} />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>{t(contentKeys.text)}</Text>
                    <Gap />
                    <Alert type="warning" message={t(contentKeys.alert)} />
                    <Gap />
                    <Text>
                        {t("documentation-link.info", {
                            documentationLink: (
                                <Link href={DOC_LINK} targetBlank>
                                    {t("general.documentation")}
                                </Link>
                            ),
                        })}
                    </Text>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    disabled: isLoading(convertImageMutation),
                                    label: t(contentKeys.action),
                                    type: "primary",
                                    onClick: () =>
                                        convertImageMutation.mutate({
                                            instanceId,
                                        }),
                                    track: {
                                        name: getTrackName("Convert"),
                                    },
                                },
                                {
                                    disabled: isLoading(convertImageMutation),
                                    label: t("general.cancel"),
                                    type: "default",
                                    onClick: onCancel,
                                    track: { name: getTrackName("Cancel") },
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};
