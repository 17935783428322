import { ButtonMenu, Forms, Gap, P } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import DocumentationLink from "@/components/DocumentationLink";
import { TABS } from "@/components/IntegrationRoutes/IntegrationRoutes.page";
import { useSaveRouteMutation } from "@/components/IntegrationRoutes/loadables";
import {
    useRouteNameValidator,
    useRouteXmlValidator,
} from "@/components/IntegrationRoutes/validators";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { Component, useCallback } from "react";
import { getRouteEditFormTrackName as getTrackName } from "../../../mixpanel/buttonNames";
import { useTrackButtonClick } from "../../../mixpanel/hooks/useTrackButtonClick.hook";
import { debounceAsync } from "@/utils/promises/promise.utils";

export const WITH_DEPLOY = true;

const RouteEditForm = ({
    readOnly,
    projectId,
    instanceId,
    route,
    isRepositoryView,
}) => {
    const initialValues = isRepositoryView
        ? { name: route.id, xml: route.xmlDefinition }
        : route;

    const {
        locationRouterService,
        accountAppLocations: { integrationEditLocation, integrationsLocation },
    } = useDic();
    const { trackHandler } = useTrackButtonClick();

    const goToRoutesList = useCallback(
        params => locationRouterService.navigate(integrationsLocation, params),
        [integrationsLocation, locationRouterService],
    );
    const saveRouteMutation = useSaveRouteMutation({
        projectId,
        instanceId,
        afterSuccess: trackHandler(
            ({ withDeploy, id }) =>
                withDeploy
                    ? goToRoutesList({ tab: TABS.INSTANCE_REPOSITORY })
                    : locationRouterService.navigate(integrationEditLocation, {
                          integrationId: id,
                      }),
            { name: getTrackName("SubmitSuccess") },
        ),
    });

    const { formId, handleSubmit, submit } = Forms.useForm({
        onSubmit: ({ values, args: [withDeploy] }) =>
            saveRouteMutation.mutate({
                values: { ...values, id: initialValues.id },
                withDeploy,
            }),
        initialValues: initialValues,
    });

    const nameValidator = useRouteNameValidator({
        instanceId,
        currentName: initialValues?.name,
    });

    const routeXmlValidator = useRouteXmlValidator({
        instanceId,
    });

    return (
        <>
            <P>{t("route-edit-form.description")}</P>
            <DocumentationLink
                link={initialValues?.template?.documentationLink}
            />
            <Gap size="large" />

            <Forms.Form
                formId={formId}
                layout="vertical"
                onSubmit={handleSubmit}
            >
                <Forms.Fields.Input
                    required={!isRepositoryView}
                    validator={nameValidator}
                    name="name"
                    label={t("route-edit-form.form.route-name.label")}
                    placeholder={t(
                        "route-edit-form.form.route-name.placeholder",
                    )}
                    disabled={isRepositoryView}
                />
                <Forms.Fields.CodeMirror
                    mode="xml"
                    inputWidth="max"
                    name="xml"
                    readOnly={readOnly || isRepositoryView}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        debounceAsync(500, routeXmlValidator),
                    ])}
                />
                <ButtonMenu
                    buttonSize="middle"
                    align="left"
                    buttons={[
                        {
                            label: t("general.deploy"),
                            type: "primary",
                            formId,
                            onClick: () => submit(WITH_DEPLOY),
                            disabled: isLoading(saveRouteMutation),
                            track: { name: getTrackName("Deploy") },
                            visible: !isRepositoryView,
                        },
                        {
                            label: t("general.save"),
                            formId,
                            onClick: () => submit(!WITH_DEPLOY),
                            disabled: isLoading(saveRouteMutation),
                            track: { name: getTrackName("Save") },
                            visible: !isRepositoryView,
                        },
                        {
                            label: t("general.cancel"),
                            onClick: () => goToRoutesList(),
                            disabled: isLoading(saveRouteMutation),
                            type: "text",
                            track: { name: getTrackName("Cancel") },
                        },
                    ]}
                />
            </Forms.Form>
        </>
    );
};

RouteEditForm.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    instanceId: PropTypes.number.isRequired,
    route: PropTypes.object,
};

export default RouteEditForm;
