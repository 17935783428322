import {
    Button,
    ButtonMenu,
    Gap,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as Copy } from "@pricefx/unity-components/src/icons/unicons/copy.svg";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export const ModalApiTokenCreated = ({ visible, onClose, token }) => {
    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("api-token.modal.create.title")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text dataTest="api-token-name-created">
                        {t("api-token.modal.created.content1")}
                    </Text>
                    <Gap size="medium" />
                    <Text dataTest="api-token-name-created-2">
                        <strong
                            data-test="api-token-name"
                            key="showTokenModal-token"
                        >
                            {token}
                        </strong>
                        <CopyToClipboard text={token}>
                            <Button
                                type="link"
                                size="small"
                                icon={Copy}
                                data-test="api-copy-button"
                                label={t("general.copy")}
                            />
                        </CopyToClipboard>
                    </Text>
                    <Gap size="medium" />
                    <Text dataTest="api-token-name-created-3">
                        {t("api-token.modal.created.content2")}
                    </Text>
                    <Gap size="small" />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ModalApiTokenCreated.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
};
