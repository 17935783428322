import { useDic } from "@/components/Dic/useDic.hook";
import ElasticSearchPanel from "@/components/Integrations/Dashboard/Tabs/ElasticSearchPanel";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { EntityType } from "@/constants/CommonConstats";
import { ACTIVITY_LOG_LIST } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React from "react";

export const DEFAULT_COLUMNS = {
    "@timestamp": true,
    message: true,
    time: true,
    executorUsername: true,
    module: true,
    result: true,
};
const ActivityLogList = ({ accountId }) => {
    useHideBackButton();

    const { axiosService } = useDic();

    const fetchData = params =>
        axiosService
            .post(`/api/audit-logs/projects/${accountId}`, params)
            .then(getData);

    const exportLogs = params =>
        axiosService.post(
            `/api/audit-logs/projects/${accountId}/elastic-search/export`,
            params,
        );

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            exportLogMessages={exportLogs}
            accountId={accountId}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={ACTIVITY_LOG_LIST}
        />
    );
};

ActivityLogList.propTypes = {
    accountId: PropTypes.number.isRequired,
};

export default ActivityLogList;
