import { DebugLogs } from "@/components/EventLogs/DebugLogs";
import { RunHistoryLogs } from "@/components/EventLogs/RunHistoryLogs";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

export const EO_LOGS_TABS = {
    RUN_HISTORY: "RUN_HISTORY",
    DEBUG_LOGS: "DEBUG_LOGS",
};

export const EO_LOGS_ENTITY_TYPE = {
    WORKFLOW: "WORKFLOW",
    SCHEDULER: "SCHEDULER",
};

/*
pro WF treba: entityType.keyword : "WORKFLOW" and entityId : 624
pro scheduler treba: entityType.keyword : "SCHEDULER" and entityId : 455
*/

const buildQueryPart = (name, value) =>
    value && `${name} : ${JSON.stringify(value)}`;

export const EventLogsPage = () => {
    const { accountId, entityType, entityId } = useAccountAppParams();
    const initialQuery = [
        buildQueryPart("entityType.keyword", entityType),
        buildQueryPart("entityId", parseInt(entityId, 10)),
    ]
        .filter(Boolean)
        .join(" AND ");

    useFullWidthTableLayout();
    useHideBackButton();

    return (
        <HeaderTabs defaultActiveKey={EO_LOGS_TABS.RUN_HISTORY}>
            <HeaderTabs.TabPane
                tab={t("event-wf.logs.tab.run-history")}
                key={EO_LOGS_TABS.RUN_HISTORY}
                forceRender
            >
                <RunHistoryLogs
                    accountId={accountId}
                    initialQuery={initialQuery}
                />
            </HeaderTabs.TabPane>
            <HeaderTabs.TabPane
                tab={t("event-wf.logs.tab.debug-logs")}
                key={EO_LOGS_TABS.DEBUG_LOGS}
                forceRender
            >
                <DebugLogs accountId={accountId} initialQuery={initialQuery} />
            </HeaderTabs.TabPane>
        </HeaderTabs>
    );
};

EventLogsPage.propTypes = {
    accountId: PropTypes.number.isRequired,
};
