import { CREATE_TABLE_SUPPORTED_TYPES } from "@/components/DataUploads/Wizard/NewTable/NewTableModal";
import { Button, Forms, H4, P } from "@/components/DesignSystem";
import { LoadablePfxEntityAdvancedFilter } from "@/components/DesignSystem/PfxEntityAdvancedFilter/PfxEntityAdvancedFilter";
import { useDic } from "@/components/Dic/useDic.hook";
import { ErrorBoundary } from "@/components/Error";
import { useFieldKeysQuery } from "@/components/Filters/form/loadables";
import { useDefinedFieldKeysValidator } from "@/components/Filters/form/validators";
import {
    EntitySelectFields,
    SOURCE_TYPE,
} from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { NewPartitionConnectionModal } from "@/components/PartitionDataManagement/Connections/NewPartitionConnectionModal";
import { useDownloadNameExistsValidation } from "@/components/PartitionDataManagement/Downloads/validators";
import { useDownloadTargetsQuery } from "@/components/PartitionDataManagement/loadables";
import { StepForm } from "@/components/StepForm";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { identity, map, noop } from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const DESTINATION = {
    DIRECT_DOWNLOAD: "DIRECT_DOWNLOAD",
    SFTP: "SFTP",
};
const DESTINATION_OPTIONS = [
    {
        label: t("partition.download.destination.option.pm"),
        value: DESTINATION.DIRECT_DOWNLOAD,
    },
    {
        label: t("partition.download.destination.option.external"),
        value: DESTINATION.SFTP,
    },
];

// Similar to SFTPUserForm.component ?
const SFTPFields = ({ partitionId }) => {
    const {
        locationRouterService,
        accountAppLocations: { partitionNewConnectionLocation },
    } = useDic();
    const query = useDownloadTargetsQuery({ partitionId });
    const modal = useVisibility();

    // return ( <SFTPUserForm sftpServerQuery={sftpServerQuery} sftpServerUserQuery={sftpServerUserQuery} sftpServerUserMutation={sftpServerUserMutation} sftpServerUsernamesLoadable={sftpServerUsernamesLoadable} isNew={isNew} maybePrefix={maybePrefix} isLDAP={isLDAP} initialValues={initialValues} goBack={goBack} /> );
    return (
        <>
            <Forms.Fields.Select
                required
                name="targetId"
                label={"Existing Connection"}
                placeholder={t("general.select")}
                validator={Forms.pmValidators.isRequired}
                {...getLoadableSelectProps(
                    query.loadable,
                    map(({ id, name }) => ({ value: id, label: name })),
                )}
            />
            <Button label={"New Connection"} onClick={modal.show} />
            <NewPartitionConnectionModal
                modal={modal}
                afterSuccess={() => {
                    query.reload();
                    modal.hide();
                }}
            />
        </>
    );
};

const GeneralStep = ({
    form: { formId, setTouched, setValues },
    allStepsProps: { partitionId, accountId, isNew },
    stepFormProps: { initialValues, setAllValues },
}) => {
    const nameExistsValidation = useDownloadNameExistsValidation({
        partitionId,
        skipValidation: ({ name }) => !isNew && name === initialValues?.name,
    });
    const onEntityChange = useCallback(
        values => {
            setAllValues(allValues => ({ ...allValues, ...values }));
        },
        [setAllValues],
    );
    console.log("[GeneralStep]", { partitionId });

    return (
        <>
            <H4>{t("general.general")}</H4>
            <P>{t("partition.download.form.steps.entity-type.perex")}</P>
            <Forms.Fields.Input
                name="name"
                label={t("partition.download.form.name.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1),
                    nameExistsValidation,
                ])}
            />
            <H4>{t("general.entities")}</H4>
            <EntitySelectFields
                formId={formId}
                setTouched={setTouched}
                setValues={setValues}
                sourceType={SOURCE_TYPE.PARTITIONS}
                sourceId={partitionId}
                projectId={accountId}
                allowCreatePartitionTableFor={CREATE_TABLE_SUPPORTED_TYPES}
                onChange={onEntityChange}
            />
        </>
    );
};

const FieldsStep = ({ allStepsProps: { partitionId }, ...props }) => {
    return <></>;
};

const FilterStep = ({
    allStepsProps: { partitionId },
    stepFormProps: { allValues },
    onValidationChange = noop, // only when used as standalone "validator"
}) => {
    const fieldKeysQuery = useFieldKeysQuery({
        objectType: mapperMappingSourceType.partitions,
        objectId: partitionId,
        entityType: allValues.entityType,
        entityName: allValues.entityName,
        canFetch: !!partitionId && !!allValues.entityType,
    });
    const fieldKeysMaybe = fieldKeysQuery.loadable.valueMaybe();
    const definedFieldKeysValidator = useDefinedFieldKeysValidator({
        enabled: true, // needs to be enabled without fieldKeys to trigger onValidationChange
        maybeFieldKeys: fieldKeysMaybe,
    });
    const onValidationChangeCurrent = useCurrentHandler(onValidationChange);
    useEffect(() => {
        onValidationChangeCurrent?.();
    }, [fieldKeysMaybe, onValidationChangeCurrent]);

    console.log("[FilterStep.rndr]", {
        partitionId,
        entityType: allValues.entityType,
        fieldKeysMaybe,
    });

    return (
        <>
            <H4>{t("general.filter")}</H4>
            <P>{t("partition.download.form.steps.filter.perex")}</P>
            <Forms.Field
                width="max"
                inputWidth="max"
                from={identity}
                name="filter"
                {...{
                    as: LoadablePfxEntityAdvancedFilter,
                    fieldKeysLoadable: fieldKeysQuery.loadable,
                    validator: definedFieldKeysValidator,
                    label: "Filter",
                    embedded: true,
                }}
            />
        </>
    );
};

const DestinationStep = ({ allStepsProps: { partitionId } }) => {
    const formId = Forms.useFormId();

    const destinationValue = Forms.useFieldValue({
        formId,
        name: "destination",
    });

    return (
        <>
            <H4>{t("general.destination")}</H4>
            <Forms.Fields.Radio
                name="destination"
                label={t("partition.download.form.destination.label")}
                layout="vertical"
                required
                validator={Forms.pmValidators.isRequired}
                options={DESTINATION_OPTIONS}
                tooltip={t("partition.download.form.destination.tooltip")}
            />

            {destinationValue === DESTINATION.SFTP && (
                <SFTPFields partitionId={partitionId} />
            )}
        </>
    );
};

const DOWNLOAD_STEP_KEY = {
    ENTITY: "ENTITY",
    // FIELDS: "FIELDS",
    FILTER: "FILTER",
    DESTINATION: "DESTINATION",
};

const downloadStepFormSteps = [
    {
        key: DOWNLOAD_STEP_KEY.ENTITY,
        title: t("partition.download.form.steps.entity-type.title"),
        Component: GeneralStep,
    },
    // {
    //     key: DOWNLOAD_STEP_KEY.FIELDS,
    //     title: t("partition.download.form.steps.fields.title"),
    //     // HeadingComponent: H4,
    //     Component: FieldsStep,
    // },
    {
        key: DOWNLOAD_STEP_KEY.FILTER,
        title: t("partition.download.form.steps.filter.title"),
        Component: FilterStep,
    },
    {
        key: DOWNLOAD_STEP_KEY.DESTINATION,
        title: t("partition.download.form.steps.destination.title"),
        Component: DestinationStep,
    },
];

const to = ({ target, ...initialValues } = {}) => ({
    ...initialValues,
    destination: target?.id ? DESTINATION.SFTP : DESTINATION.DIRECT_DOWNLOAD,
    targetId: target?.id,
});
const from = ({ destination, targetId, ...values }) => ({
    ...values,
    targetId: destination === DESTINATION.SFTP ? targetId : undefined,
});

const HiddenContainer = ({ children }) => {
    return <div style={{ display: "none" }}>{children}</div>;
};

const HiddenFormValidator = ({
    reactiveValues,
    onValidityChange,
    children,
}) => {
    const form = Forms.useForm({
        onSubmit: noop,
    });
    const { Form, getBag, setTouched, setValues } = form;
    useSetValidatedInitialValues(
        { initialValues: reactiveValues, setTouched, setValues },
        [reactiveValues],
    );
    const [bagMaybe, refreshBag] = Forms.pmHooks.useBagMaybe({ getBag });

    useEffect(() => {
        refreshBag();
    }, [reactiveValues, refreshBag]);

    const onValidityChangeCurrent = useCurrentHandler(onValidityChange);
    const isValidMaybe = bagMaybe?.validation?.isValid;
    useEffect(() => {
        onValidityChangeCurrent({ isValidMaybe });
    }, [isValidMaybe, onValidityChangeCurrent]);

    return (
        <ErrorBoundary
            onError={(...args) =>
                console.error("[HiddenFormValidator.onError]", ...args)
            }
        >
            <HiddenContainer>
                <Form>{children}</Form>
            </HiddenContainer>
        </ErrorBoundary>
    );
};

export const DownloadForm = ({
    initialValues: _initialValues,
    accountId,
    partitionId,
    onSave,
    onExport,
    onCancel,
}) => {
    const isNew = !_initialValues;
    const initialValues = useMemo(() => to(_initialValues), [_initialValues]);
    const [allValues, setAllValues] = useState(initialValues);
    const isTabVariant = false;
    // const isTabVariant = !isNew; // Next sprints
    const handleExport = useCallback(
        ({ values }) => onExport(from({ partitionId, ...values })),
        [onExport, partitionId],
    );
    const handleSave = useCallback(
        ({ values }) => onSave(from({ partitionId, ...values })),
        [onSave, partitionId],
    );
    const allStepsProps = useMemoByDeepEquality({
        accountId,
        partitionId,
        isNew,
    });
    const [isValidMaybe, setIsValidMaybe] = useState(undefined);
    const handleFilterValidityChange = useCallback(({ isValidMaybe }) => {
        setIsValidMaybe(isValidMaybe);
    }, []);
    const skipToDestinationDisabled = !isValidMaybe;

    const getStepButtons = useCallback(
        ({ stepperProps, formId, submit }) =>
            isTabVariant
                ? [
                      {
                          label: t("general.save"),
                          type: "primary",
                          formId,
                          onClick: () => submit(handleSave),
                      },
                      {
                          label: t("partition.download.form.action.export-csv"),
                          type: "secondary",
                          formId,
                          onClick: () => submit(handleExport),
                      },
                      {
                          visible: !!onCancel,
                          label: t("general.cancel"),
                          type: "text",
                          onClick: onCancel,
                          "data-test": "cancel-button",
                      },
                  ]
                : [
                      {
                          visible: !stepperProps.isLastStep,
                          label: t("general.continue"),
                          type: "primary",
                          formId,
                      },
                      {
                          visible: stepperProps.isLastStep && isNew,
                          label: t("partition.download.form.action.export-csv"),
                          type: "primary",
                          formId,
                          onClick: () => submit(handleExport),
                      },
                      {
                          visible: stepperProps.isLastStep && !isNew,
                          label: t("general.save"),
                          type: "primary",
                          formId,
                          onClick: () => submit(handleSave),
                      },
                      {
                          visible: stepperProps.isLastStep && !isNew,
                          label: t("partition.download.form.action.export-csv"),
                          type: "secondary",
                          formId,
                          onClick: () => submit(handleExport),
                      },
                      {
                          visible: stepperProps.isFirstStep,
                          label: "Skip to Destination",
                          type: "secondary",
                          disabled: skipToDestinationDisabled,
                          formId,
                          onClick: () =>
                              submit(() =>
                                  stepperProps.goToStep({
                                      key: DOWNLOAD_STEP_KEY.DESTINATION,
                                  }),
                              ),
                      },
                      {
                          visible: !stepperProps.isFirstStep,
                          label: t("general.back"),
                          type: "secondary",
                          onClick: stepperProps.prevStep,
                      },
                      {
                          visible: !!onCancel,
                          label: t("general.cancel"),
                          type: "text",
                          onClick: onCancel,
                          "data-test": "cancel-button",
                      },
                  ],
        [
            isTabVariant,
            onCancel,
            isNew,
            skipToDestinationDisabled,
            handleSave,
            handleExport,
        ],
    );

    return (
        <>
            <StepForm
                title={
                    isNew
                        ? t("partition.download.new")
                        : t("partition.download.edit")
                }
                initialValues={initialValues}
                steps={downloadStepFormSteps}
                onCancel={onCancel}
                allStepsProps={allStepsProps}
                getStepButtons={getStepButtons}
                allValues={allValues}
                setAllValues={setAllValues}
                isTabVariant={isTabVariant}
                onSubmit={handleSave} // Tab change -> save
            />
            <HiddenFormValidator
                reactiveValues={allValues}
                onValidityChange={handleFilterValidityChange}
            >
                <FilterStep
                    allStepsProps={allStepsProps}
                    stepFormProps={{ allValues }}
                    onValidationChange={() => setAllValues(v => ({ ...v }))}
                />
            </HiddenFormValidator>
        </>
    );
};
