import { Alert, Gap } from "@/components/DesignSystem";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { createSessionState } from "@/utils/sessionState/sessionState";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRoute } from "react-router5";
import { axiosService } from "../../../../../axios";
import { RECORDS_PANEL } from "../../../../../constants/sessionState.constants";
import PageableTable, {
    PAGE_SIZE,
} from "../../../../PagableTable/PageableTable";
import { useFetchPage } from "../../../../PagableTable/useFetchPage.hook";
import FailedRecordsTable, { formatSort } from "./FailedRecordsTable";
import RecordsHeader from "./RecordsHeader";
import { recordsColumns } from "./RecordsTable.columns";

const DEFAULT_SORT_BY = [{ id: "timestamp", order: "desc" }];
export const parseSort = ({
    str,
    pair: [id, order] = str?.split(",") ?? [],
}) => [{ id, order }];

const { useSessionState } = createSessionState(RECORDS_PANEL);

const addAlert = ({ row }) => {
    if (row.failed)
        return {
            alerts: {
                failed: t("failed-record-column-indicator.tooltip", {
                    count: row.failed,
                }),
                failed_ALERT_TYPE: "YELLOW",
            },
        };
    else return {};
};

const RecordsPanel = ({ instanceId }) => {
    const [from, setFrom] = useSessionState("from", moment().startOf("day"));
    const [to, setTo] = useSessionState(
        "to",
        moment().add(1, "days").startOf("day"),
    );
    const dateInitializedRef = useRef(false);
    const dateRangeChange = (from, to) => {
        setFrom(from);
        setTo(to);
    };

    const { router } = useRoute();
    const params = useAccountAppParams();
    useEffect(() => {
        if (params.dateFrom && params.dateTo && !dateInitializedRef.current) {
            dateInitializedRef.current = true;
            const dateFrom = moment(decodeURIComponent(params.dateFrom));
            const dateTo = moment(decodeURIComponent(params.dateTo));

            if (dateFrom.isValid() && dateTo.isValid())
                dateRangeChange(dateFrom, dateTo);

            // cleanup init params as there is more date range selectors
            // with sessionstate on the same route in different tabs
            const state = router.getState();
            router.navigate(state.name, {
                ...state.params,
                dateFrom: "",
                dateTo: "",
            });
        }
    }, []);

    const [sort, setSort] = useState(DEFAULT_SORT_BY);
    const [tableProps] = useFetchPage(
        (page, size, sort) => {
            const parsedSort = sort
                ? parseSort({ str: sort })
                : DEFAULT_SORT_BY;
            setSort(parsedSort);

            return axiosService.post(
                `/api/instances/${instanceId}/elastic-search/api-statistics`,
                {
                    dateFrom: from.format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                    dateTo: to.format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                    mustQuery: "",
                    size,
                    from: (page - 1) * size,
                    sort: sort ? formatSort(sort) : DEFAULT_SORT_BY,
                },
            );
        },
        [instanceId, from, to],
    );
    const mappedTableProps = useMemo(
        () => ({
            ...tableProps,
            page: {
                ...tableProps.page,
                page: {
                    ...tableProps.page?.page,
                    content: tableProps.page?.page?.content?.map(row => ({
                        ...row,
                        ...addAlert({ row }),
                    })),
                },
            },
        }),
        [tableProps],
    );

    return (
        <>
            <RecordsHeader
                successful={mappedTableProps.page?.successful ?? 0}
                failed={mappedTableProps.page?.failed ?? 0}
                from={from}
                to={to}
                onDateRangeChange={dateRangeChange}
            />
            <Gap />
            <PageableTable
                fixed
                columns={recordsColumns}
                fetchPage={mappedTableProps.fetchPage}
                loading={mappedTableProps.page?.loading}
                page={mappedTableProps.page?.page}
                rowKey="unique"
                hasQuickFilters={false}
                defaultSort={{
                    fieldName: "timestamp",
                    sortDir: "descending",
                }}
                exportUrl={`/api/instances/${instanceId}/elastic-search/api-statistics/export`}
                elkQuery={{
                    dateFrom: from.format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                    dateTo: to.format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                    mustQuery: "",
                    size: PAGE_SIZE,
                    from: 0,
                    sort,
                }}
                pmExpandable={{
                    expandedRowHeight: 400,
                    rowExpandable: record => !!record.failed,
                    expandedRowRender: record => (
                        <FailedRecordsTable
                            instanceId={instanceId}
                            routeId={record.routeId}
                            breadcrumbId={record.breadcrumbId}
                            failed={record.failed}
                            objectType={record.objectType}
                        />
                    ),
                }}
                restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT + 167}
            />
        </>
    );
};

export default RecordsPanel;
