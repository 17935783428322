import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { Tag } from "@/components/DesignSystem";
import { GapBetween } from "@/components/DesignSystem/Gap/GapBetween";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { NonStandardEntity } from "@/components/Integrations/components/NonStandardEntity";
import { AUTOMATED_UPGRADE_MIN_DELAY } from "@/components/UpgradeScheduling/UpgradeScheduling";
import { t } from "@/translations";
import moment from "moment";
import React from "react";
import { clusterUpgradeTypes } from "./constants";

const options = {
    APPROVED: t(`cluster.upgrade.overview.column.status.approved`),
    CANCELLED: t(`cluster.upgrade.overview.column.status.cancelled`),
    REQUESTED: t(`cluster.upgrade.overview.column.status.requested`),
    COMPLETED: t(`cluster.upgrade.overview.column.status.completed`),
};

const AUTOMATED_OPTIONS = [
    {
        label: t("cluster.upgrade.automatic-upgrade.option.manual"),
        value: clusterUpgradeTypes.MANUAL,
    },
    {
        label: t("cluster.upgrade.automatic-upgrade.option.automated"),
        value: clusterUpgradeTypes.AUTOMATED,
    },
];

const mapStatusToColor = status => {
    switch (status) {
        case "APPROVED":
        case "COMPLETED":
            return "green";
        case "CANCELLED":
            return "red";
        case "REQUESTED":
            return "blue";
        default:
            return "grey";
    }
};

const canExecuteActions = record => {
    const editEndDateTime =
        record.type === clusterUpgradeTypes.AUTOMATED
            ? moment(record.upgradeDate).subtract(
                  AUTOMATED_UPGRADE_MIN_DELAY,
                  "minutes",
              )
            : moment(record.upgradeDate).subtract(1, "days");
    return record.status !== "CANCELLED" && moment().isBefore(editEndDateTime);
};

export const clusterUpgradeOverviewColumns = (
    overviewMode,
    onEdit,
    onDelete,
) => [
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.overview.column.cluster"),
        name: "cluster",
        render: (text, record) => (
            <GapBetween gap={3}>
                <NonStandardEntity
                    display={!canExecuteActions(record)}
                    tooltip={t("cluster.upgrade.overview.no-edit-available")}
                />
                {text}
                {!overviewMode && canExecuteActions(record) && (
                    <ActionButton
                        record={record}
                        items={[
                            {
                                title: t("general.tooltip.edit"),
                                onClick: () => onEdit(record),
                            },
                            {
                                title: t("general.delete"),
                                confirmMessage: t("cluster.upgrade.delete", {
                                    cluster: record.cluster,
                                }),
                                onConfirm: () => onDelete(record.eventId),
                                color: "red",
                            },
                        ]}
                    />
                )}
            </GapBetween>
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.overview.column.currentVersion"),
        name: "currentVersion",
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.overview.column.targetVersion"),
        name: "targetVersion",
    },
    {
        type: fieldTypes.DATETIME,
        label: t("cluster.upgrade.overview.column.upgradeDate"),
        name: "upgradeDate",
        canFilter: false,
        render: date => <FormattedDateTime>{date}</FormattedDateTime>,
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.overview.column.responsiblePerson"),
        name: "responsiblePerson.username",
        exportName: "responsiblePersonFullName",
        render: (_, record) => record.responsiblePersonFullName,
    },
    {
        type: fieldTypes.TEXT,
        label: t("cluster.upgrade.overview.column.requestedBy"),
        name: "requestedBy.username",
        render: (_, record) => record.requestedBy,
    },
    {
        type: fieldTypes.OPTION,
        label: t("cluster.upgrade.automatic-upgrade.label"),
        name: "type",
        options: AUTOMATED_OPTIONS,
    },
    {
        type: fieldTypes.OPTION,
        label: t("cluster.upgrade.overview.column.status"),
        name: "status",
        options,
        render: status => {
            const color = mapStatusToColor(status);
            return (
                <Tag color={color} size="small">
                    {t(
                        `cluster.upgrade.overview.column.status.${status.toLowerCase()}`,
                    )}
                </Tag>
            );
        },
    },
];
