import { Tooltip, UnityIcon } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const IconWithTooltip = ({ tooltip, visible, color, icon }) => {
    if (!visible) return null;

    return (
        <Tooltip tooltip={tooltip}>
            <UnityIcon component={icon} style={{ color }} />
        </Tooltip>
    );
};

IconWithTooltip.propTypes = {
    tooltip: PropTypes.string,
    visible: PropTypes.any,
    color: PropTypes.any,
    icon: PropTypes.node,
};
