import {
    Button,
    ButtonMenu,
    Modal,
    Text,
    UnityLayout,
} from "@/components/DesignSystem";
import { t } from "@/translations";
import { ReactComponent as Copy } from "@pricefx/unity-components/src/icons/unicons/copy.svg";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const ShowTokenModal = ({ visible, onClose, token }) => {
    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout data-test="api-token-header-title">
                <UnityLayout.Header
                    size={3}
                    title={t("create-token.title.modal-created-token")}
                />
                <UnityLayout.Content padding={[false, true]}>
                    <Text>
                        {t("create-token.message.modal-created-token", {
                            token: (
                                <strong key="showTokenModal-token">
                                    {token}
                                </strong>
                            ),
                            br: <br key="showTokenModal-br" />,
                        })}
                        <CopyToClipboard text={token}>
                            <Button
                                type="link"
                                size="small"
                                icon={Copy}
                                data-test="api-copy-button"
                                label={t("general.copy")}
                            />
                        </CopyToClipboard>
                        <br />
                        <br />
                        {t("create-token.message.store-in-safe-place")}
                    </Text>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.close"),
                                    onClick: onClose,
                                    type: "primary",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

ShowTokenModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    token: PropTypes.string,
};

export default ShowTokenModal;
