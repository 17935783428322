import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import {
    fieldTypes,
    HEADER_HEIGHT_WITH_BREADCRUMB,
} from "@/components/DesignSystem/Table/constants";
import { Tooltip } from "@/components/DesignSystem/Tooltip";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import PageableTable, {
    PAGE_SIZE,
    PAGE_SIZE_OPTIONS,
} from "@/components/PagableTable/PageableTable";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import SearchPanel from "@/components/SearchPanel/SearchPanel.component";
import { DislocatedExportButton } from "@/components/TableWithPreferences/DislocatedExportButton";
import { ELASTIC_SEARCH_PANEL } from "@/constants/sessionState.constants";
import {
    isLoading,
    useMutationLoadable,
    useQueryLoadable,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { getData } from "@/services/utils";
import { downloadFile } from "@/utils/downloadUtils";
import { syntaxHighlight } from "@/utils/formats/json";
import { createSessionState } from "@/utils/sessionState/sessionState";
import entries from "lodash/entries";
import _filter from "lodash/filter";
import { path, pick, pipe, reduce, keys, map, uniq, filter } from "lodash/fp";
import _map from "lodash/map";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

const MAX_EXPORTED_MESSAGES = 10000;

const limitNumberTo = (num, max) => Math.min(num, max);

const createDefaultTableState = ({ initialQuery = "" } = {}) => {
    const date = moment();
    const [id, order] = DEFAULT_SORT_BY.split(",");
    return {
        page: 0,
        pageSize: PAGE_SIZE_OPTIONS[0],
        query: initialQuery,
        isDefaultDateRange: true,
        dateFrom: moment(date).startOf("day"),
        dateTo: moment(date).add(1, "d").startOf("day"),
        sortBy: [{ id, order }],
        elkQueries: [],
        availableUsers: [],
    };
};

const formatDateTime = date => {
    if (date) {
        return date.format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    }
    return null;
};

const convertSelectedColumn = column => {
    const getValue = path(["source", column]);

    if (column === "@timestamp") {
        return {
            type: fieldTypes.TEXT,
            label: column,
            name: column,
            render: (_, record) => (
                <FormattedDateTime>
                    {getValue(record)}
                    {/* {record.source?.["@timestamp"]} */}
                </FormattedDateTime>
            ),
            width: 180,
            canFilter: false,
        };
    } else if (column === "message") {
        return {
            label: column,
            name: column,
            render: (_, record) => (
                <>
                    <Tooltip
                        tooltip={JSON.stringify(getValue(record), null, 2)}
                    >
                        {JSON.stringify(getValue(record), null, 2)}
                    </Tooltip>
                    {/* <Tooltip
                        tooltip={JSON.stringify(record.source[column], null, 2)}
                    >
                        {JSON.stringify(record.source[column], null, 2)}
                    </Tooltip> */}
                </>
            ),
            canSort: false,
            canFilter: false,
        };
    }

    return {
        label: column,
        name: column,
        skipParseDotNotation: true,
        render: (_, record) => (
            <>
                <pre
                    style={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {JSON.stringify(getValue(record), null, 2)}
                    {/* {JSON.stringify(record.source[column], null, 2)} */}
                </pre>
            </>
        ),
        canSort: false,
        canFilter: false,
    };
};

const getSelectedColumns = selectedColumns => {
    return _filter(entries(selectedColumns), e => e[1]).map(
        column => column[0],
    );
};

const buildFetchParams = (state, selectedColumns) => {
    return {
        ...state,
        dateFrom: formatDateTime(state.dateFrom),
        dateTo: formatDateTime(state.dateTo),
        selectedColumns: getSelectedColumns(selectedColumns),
    };
};

const filterRequestParams = params => {
    const {
        query,
        page,
        pageSize = PAGE_SIZE,
        dateFrom,
        dateTo,
        sortBy = INIT_SORT_BY,
        selectedColumns,
        totalMessages,
    } = params;

    const exportedMessages =
        totalMessages &&
        (totalMessages < MAX_EXPORTED_MESSAGES
            ? totalMessages
            : MAX_EXPORTED_MESSAGES);
    return {
        mustQuery: query,
        size: exportedMessages || pageSize,
        from: pageSize * page,
        dateFrom: dateFrom,
        dateTo: dateTo,
        sort: sortBy.map(({ id, order }) => ({
            id,
            order,
        })),
        selectedColumns: selectedColumns,
    };
};

const BODY_HEADER_HEIGHT = 95;
const REST_HEIGHT = HEADER_HEIGHT_WITH_BREADCRUMB + BODY_HEADER_HEIGHT;

const DEFAULT_SORT_BY = "@timestamp,desc";
const INIT_SORT_BY = [{ id: "@timestamp", desc: true }];

const DEFAULT_COLUMNS = {
    "@timestamp": true,
    message: true,
};

const emptyArray = [];

const ElasticSearchPanel = ({
    fetchData,
    exportLogMessages,

    defaultColumns = DEFAULT_COLUMNS,
    exportUrl,
    accountId,

    entityId,
    entityType,
    sessionStateName,
    restHeight = REST_HEIGHT,

    initialQuery,
    columnNamesFromDataSource = pipe(path([0, "source"]), keys),
    reloadToken,
    reloadAll,
}) => {
    const [columnsVisibility, setColumnsVisibility] = useState(defaultColumns);

    const { useSessionState } = useMemo(
        () =>
            createSessionState(
                `${ELASTIC_SEARCH_PANEL}-${sessionStateName ?? entityType}`,
            ),
        [entityType, sessionStateName],
    );
    const [state, setState] = useSessionState(
        "state",
        createDefaultTableState({ initialQuery }),
        {
            reviveState: state => ({
                ...state,
                ...(initialQuery ? { query: initialQuery } : {}),
                ...(state.isDefaultDateRange
                    ? pick(["dateFrom", "dateTo"], createDefaultTableState())
                    : {}),
            }),
        },
    );

    const fetchParams = useMemoByDeepEquality(
        filterRequestParams(buildFetchParams(state, columnsVisibility)),
    );
    const currentFetchData = useCurrentHandler(fetchData);
    const messagesResource = useQueryLoadable(
        async () => currentFetchData(fetchParams),
        [currentFetchData, fetchParams, entityId, reloadToken],
    );

    const dataMaybe = messagesResource.loadable.valueMaybe();
    const totalMessages = dataMaybe?.total;
    const dataSource = dataMaybe?.hits; // || dataMaybe?.logs; // TODO: BE - RunHistoryLogs are using "logs"
    const dynamicColumnNames = dataSource?.length
        ? columnNamesFromDataSource(dataSource)
        : emptyArray;

    const messageResourceReload = useCurrentHandler(messagesResource.reload);
    useRefreshAction(reloadAll || messageResourceReload);

    const availableColumnsArr = useMemo(
        () => uniq([...keys(defaultColumns), ...dynamicColumnNames]),
        [defaultColumns, dynamicColumnNames],
    );
    const selectedColumns = useMemo(
        () => ({
            ...defaultColumns,
            ...columnsVisibility,
        }),
        [columnsVisibility, defaultColumns],
    );
    const tableColumns = useMemo(
        () =>
            pipe(
                entries,
                filter(([columnName, visible]) => visible),
                map(([columnName]) => convertSelectedColumn(columnName)),
            )(selectedColumns),
        [selectedColumns],
    );

    // console.log("[ESP.rndr]", { defaultColumns, columnsVisibility, selectedColumns, dynamicColumnNames, availableColumnsArr, });

    const onFetchPage = useCallback(
        (current, pageSize, sortBy = DEFAULT_SORT_BY) => {
            const [id, order] = sortBy.split(",");
            setState(state => ({
                ...state,
                page: current - 1,
                pageSize,
                sortBy: [{ id, order }],
            }));
        },
        [setState],
    );

    const sendQuery = query => {
        setState({ ...state, query });
    };

    const onExport = useCallback(() => {
        return exportLogMessages(
            filterRequestParams({
                ...buildFetchParams(state, selectedColumns),
                totalMessages: totalMessages,
            }),
        )
            .then(getData)
            .then(fileContent =>
                downloadFile(fileContent, "log-messages-export.csv"),
            );
    }, [exportLogMessages, selectedColumns, state, totalMessages]);
    const exportMutation = useMutationLoadable(
        async (...args) => onExport(...args),
        [onExport],
    );

    logger.debug({
        logGroupKey: ["ElasticSearchPanel", "rndr"],
        color: "aqua",
        data: { exportLogMessages },
    });

    return (
        <>
            {exportLogMessages && (
                <DislocatedExportButton
                    exportDisabled={totalMessages >= MAX_EXPORTED_MESSAGES}
                    exportVisible
                    onExport={exportMutation.mutate}
                    exporting={isLoading(exportMutation)}
                />
            )}
            <SearchPanel
                accountId={accountId}
                entityId={entityId}
                entityType={entityType}
                availableColumns={availableColumnsArr}
                onSearch={sendQuery}
                onDateRangeChange={value => {
                    setState(state => ({
                        ...state,
                        isDefaultDateRange: false,
                        page: 0,
                        dateFrom: value[0],
                        dateTo: value[1],
                    }));
                }}
                selectedColumns={selectedColumns}
                onColumnsChange={columnsVisibility => {
                    setColumnsVisibility(columnsVisibility);
                }}
                query={state.query}
                dateFrom={state.dateFrom}
                dateTo={state.dateTo}
            />

            <PageableTable
                fixed
                paddingTop={0}
                exportable={!!exportUrl}
                exportUrl={exportUrl}
                restHeight={restHeight}
                loading={isLoading(messagesResource.loadable)}
                page={{
                    content: dataSource,
                    numberOfElements: state.pageSize,
                    totalElements: limitNumberTo(
                        totalMessages,
                        MAX_EXPORTED_MESSAGES,
                    ),
                    size: state.pageSize,
                    number: state.page,
                    totalPages: state.pages,
                    pageable: {
                        offset: state.pageSize * state.page,
                        pageSize: state.pageSize,
                        pageNumber: state.page,
                        paged: true,
                        sort: { empty: false, sorted: true, unsorted: false },
                    },
                }}
                rowKey="id"
                hasQuickFilters={false}
                fetchPage={onFetchPage}
                pmExpandable={{
                    expandedRowHeight: 500,
                    expandedRowRender: record => (
                        <pre
                            className="jsonParent"
                            dangerouslySetInnerHTML={{
                                __html: syntaxHighlight(
                                    JSON.stringify(record.source, null, 4),
                                    record.highlight &&
                                        Object.keys(record.highlight),
                                ),
                            }}
                        />
                    ),
                }}
                columns={tableColumns}
                elkQuery={{
                    dateFrom: state.dateFrom.format(
                        "YYYY-MM-DDTHH:mm:ss.SSSZZ",
                    ),
                    dateTo: state.dateTo.format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
                    mustQuery: state.query,
                    size: PAGE_SIZE,
                    from: 0,
                    sort: [{ id: "@timestamp", order: "desc" }],
                }}
            />
        </>
    );
};

ElasticSearchPanel.propTypes = {
    fetchData: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    defaultColumns: PropTypes.object,
    exportLogMessages: PropTypes.func,
    sessionStateName: PropTypes.string.isRequired,
    restHeight: PropTypes.number,
    exportUrl: PropTypes.string,
};

export default ElasticSearchPanel;
