import { Alert, Button, Gap, H3, Tooltip } from "@/components/DesignSystem";
import { parseSort } from "@/components/Integrations/Dashboard/Tabs/Records/RecordsPanel";
import { t } from "@/translations";
import React, { useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { messagesApiService } from "../../../../../apps/accountApp/services/messagesApi.service";
import PageableTable, {
    MAX_EXPORT_SIZE,
} from "../../../../PagableTable/PageableTable";
import { useFetchPage } from "../../../../PagableTable/useFetchPage.hook";
import { failedRecordsColumns } from "./FailedRecordsTable.columns";

const DEFAULT_SORT_BY = [{ id: "timestamp", order: "desc" }];
export const formatSort = sort => {
    const [id, order] = sort.split(",");
    return [{ id, order }];
};

const getExportButton = ({ buttonContainerRef }) => {
    const ExportButton = ({
        exportDisabled,
        exportVisible,
        onExport,
        exporting,
        text = t("button.export-errors"),
    }) => {
        if (!exportVisible || !buttonContainerRef.current) return null;

        const btn = (
            <Tooltip
                tooltip={
                    exportDisabled &&
                    t("export.tooltip.max-record", {
                        maxRows: MAX_EXPORT_SIZE,
                    })
                }
            >
                <div />
                <Button
                    disabled={exportDisabled}
                    loading={exporting}
                    style={{ marginRight: 8, marginLeft: 8 }}
                    label={text}
                    onClick={onExport}
                />
            </Tooltip>
        );

        return createPortal(btn, buttonContainerRef.current);
    };
    return ExportButton;
};

const shouldDisplayBatchFailed = ({ hasFailed, objectType, hasNoRecords }) => {
    return ["DM", "DMDS"].includes(objectType) && hasFailed && hasNoRecords;
};

const FailedRecordsTable = ({
    routeId,
    instanceId,
    breadcrumbId,
    failed,
    objectType,
}) => {
    const buttonContainerRef = useRef();
    const [sort, setSort] = useState(DEFAULT_SORT_BY);
    const [tableProps, { response }] = useFetchPage(
        (page, size, sort) => {
            const parsedSort = sort
                ? parseSort({ str: sort })
                : DEFAULT_SORT_BY;
            setSort(parsedSort);
            return messagesApiService.fetchFailedRecordsMultiple(instanceId, {
                size,
                from: (page - 1) * size,
                sort: sort ? formatSort(sort) : DEFAULT_SORT_BY,
                routeId,
                breadcrumbId,
            });
        },
        [breadcrumbId, routeId, instanceId],
    );
    const ExportButton = useMemo(
        () => getExportButton({ buttonContainerRef }),
        [],
    );

    const hasFailed = failed > 0;
    const hasNoRecords = !!response && tableProps.page?.content?.length === 0;
    const isBatchFailed = shouldDisplayBatchFailed({
        hasFailed,
        objectType,
        hasNoRecords,
    });

    return (
        <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
            <Gap />
            <div
                style={{
                    width: "100%",
                    display: "inline-flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: "39px",
                }}
            >
                <H3>
                    {failed} {t("failed-records.title")}
                </H3>
                <div ref={buttonContainerRef} />
            </div>
            {isBatchFailed ? (
                <Alert
                    type="error"
                    message={t("failed-records.batch-failed")}
                />
            ) : (
                <div>
                    <PageableTable
                        fixed
                        ExportButton={ExportButton}
                        exportUrl={`/api/instances/${instanceId}/elastic-search/failed-records/export`}
                        defaultSort={{
                            fieldName: "timestamp",
                            sortDir: "descending",
                        }}
                        elkQuery={{
                            from: 0,
                            routeId,
                            breadcrumbId,
                            sort,
                        }}
                        fixedHeight={300}
                        rowKey="id"
                        hasQuickFilters={false}
                        columns={failedRecordsColumns}
                        // emptyDataPlaceholder={ isBatchFailed ? ( <Alert type="error" message={t("failed-records.batch-failed")} /> ) : undefined }
                        {...tableProps}
                    />
                </div>
            )}
        </div>
    );
};

export default FailedRecordsTable;
