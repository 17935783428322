import { tap } from "@/components/Connections/loadables";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { map, noop } from "lodash/fp";
import qs from "qs";

export const useEOTypesOptionsQuery = ({ accountId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-origin-types`)
                .then(getData)
                .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService],
    );
};

export const useEOTEventOptionsQuery = ({ accountId, eoType, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-origin-types/${eoType}/events`,
                      )
                      .then(getData)
                      .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService, canFetch, eoType],
    );
};

export const useEOTEntitiesOptionsQuery = ({
    accountId,
    eoType,
    canFetch = accountId && eoType,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-origin-types/${eoType}/entities`,
                      )
                      .then(getData)
                      .then(
                          map(({ id, name }) => ({ value: id, label: name })),
                      ),
        [accountId, axiosService, canFetch, eoType],
    );
};

export const useEOTActionTypesOptionsQuery = ({
    accountId,
    eoType,
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-origin-types/${eoType}/action-types`,
                      )
                      .then(getData)
                      .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService, canFetch, eoType],
    );
};

export const useEOTActionEntitiesOptionsQuery = ({
    accountId,
    eoType, // PARTITION
    actionType, // LOGIC
    entityId, // {partitionId}
    wfId, // optional
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-origin-types/${eoType}/action-types/${actionType}/entities?${qs.stringify(
                              {
                                  targetId: entityId,
                                  workflowId: wfId,
                              },
                          )}`,
                      )
                      .then(getData)
                      .then(
                          map(({ id, name }) => ({
                              value: id,
                              label: name || id,
                          })),
                      ),
        [accountId, actionType, axiosService, canFetch, entityId, eoType, wfId],
    );
};

export const useEventWorkflowsQuery = ({ accountId, reloadToken }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-workflows`)
                .then(getData)
                .then(wfs =>
                    wfs.map(wf => ({
                        ...wf,
                        target: wf.singleSource?.label,
                    })),
                ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken, accountId, axiosService],
    );
};

export const useCreateEventWFMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(`/api/accounts/${accountId}/event-workflows`, payload)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
    );
};

export const useEventWFQuery = ({ accountId, wfId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-workflows/${wfId}`)
                .then(getData),
        [accountId, axiosService, wfId],
    );
};

export const useUpdateEventWFMutation = ({
    accountId,
    wfId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-workflows/${wfId}`,
                    payload,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService, wfId],
    );
};

export const useDeleteEventWFMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ wfId }) =>
            axiosService
                .delete(`/api/accounts/${accountId}/event-workflows/${wfId}`)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
        "event-wf.workflows.message.delete-request.success",
    );
};

export const useChangeEventWFStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ wfId, enabled, accountId }) =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-workflows/${wfId}/${
                        enabled ? "activate" : "deactivate"
                    }`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [afterSuccessCurrent, axiosService],
    );
};

export const useTerminateWFMutation = ({ accountId, afterSuccess = noop }) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(`/api/accounts/${accountId}/event-workflow-runs/${id}`)
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
    );
};

export const useRestartLastWFStepMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async ({ workflowId }) =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-workflows/${workflowId}/restart-last-step`,
                )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [accountId, afterSuccessCurrent, axiosService],
        t("event-wf.running.action.restart-last-step.success-message"),
    );
};

export const WFL_STATUS = {
    ACTIVE: "active",
    EXECUTED: "executed",
    QUEUED: "queued",
};

export const useRunningEventWorkflowsQuery = ({ accountId, reloadToken }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-workflow-runs`)
                .then(getData)
                .then(wfs =>
                    wfs.map(({ steps, ...wf }) => {
                        const activeIndex = steps.findIndex(
                            ({ active }) => active,
                        );
                        return {
                            ...wf,
                            name: wf.singleOriginLabel
                                ? wf.name + ` (${wf.singleOriginLabel})`
                                : wf.name,
                            children: steps.map((s, i) => ({
                                id: JSON.stringify(s),
                                _wfl_status:
                                    i === activeIndex
                                        ? WFL_STATUS.ACTIVE
                                        : i < activeIndex
                                        ? WFL_STATUS.EXECUTED
                                        : WFL_STATUS.QUEUED,
                                ...s,
                            })),
                        };
                    }),
                ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken, accountId, axiosService],
    );
};

export const useEventWFOriginsQuery = ({ accountId, id }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-workflows/${id}/origins`)
                .then(getData),
        [accountId, axiosService, id],
    );
};

export const useCopyWFMutation = ({ accountId, id, afterSuccess = noop }) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async payload =>
            axiosService
                .post(
                    `/api/accounts/${accountId}/event-workflows/${id}/copy`,
                    payload,
                )
                .then(getData)
                .then(afterSuccessCurrent),
        [accountId, afterSuccessCurrent, axiosService, id],
        "event-wf.copy.success",
    );
};
